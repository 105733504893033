import React, { useEffect, useRef, useState } from 'react'
import DirectionsCarIcon from '@mui/icons-material/Person';
import { Box, TapArea, Button, Text, Image, Layer, Modal, Spinner } from 'gestalt'

// import DriverSelector from '../operator/component/DriverSelector';
// import { setOrderDriver } from '../dashboard/components/OrderDashboardApi'
import { _getProcessColor } from '../../utils/helpers'

const LocationPin = (props) => {
    const anchorRef = useRef();
    const { selected, order, driver, current } = props
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false)

    const renderPickup = () => {
        return (
            <TapArea 
                tapStyle="compress"
                accessibilityControls="main-example"
                fullWidth={false}
                onTap={() => props.onSelectDriver(current)}
            >
                <Box position='relative'>
                    {current.cab && current.cab.drivers.length > 0 && (
                        <Box width={80}>
                            <span style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                textAlign: 'center',
                                textShadow: "2px 2px #fff",
                            }}>
                                [{
                                    current.cab && current.cab.drivers.map((driver, index) => (
                                        <span key={index} style={{
                                            fontWeight: "bold",
                                            fontSize: 14,
                                            textAlign: 'center',
                                            textShadow: "2px 2px #fff",
                                        }}>
                                            {driver.sap},
                                        </span>
                                    ))
                                }]
                            </span>
                        </Box>
                    )}
                    

                    <Box height={40} width={Math.ceil(40 * 1.375586854460094)} position='relative'>
                        <Image 
                            src="/images/bus.png"
                            naturalHeight={1}
                            naturalWidth={1}
                        />

                        <div style={{
                            position: 'absolute',
                            left: 10,
                            bottom: 10,
                        }}>
                            <span style={{
                                color: "#fff",
                                fontWeight: 'bold',
                                fontSize: 14,
                            }}>#{driver.sap}</span>
                        </div>
                    </Box>
                </Box>
            </TapArea>
        )
    }

    const renderDriver = () => {
        if(current.cab) return null
        if(current.order && current.order.current_process.name != 'assigned') return null

        return (
            <TapArea 
                tapStyle="compress"
                accessibilityControls="main-example"
                fullWidth={false}
                onTap={() => props.onSelectDriver(current)}
            >
                <Box position='relative'>
                    <div style={{
                        position: 'absolute',
                        top: -20,
                        left: 0,
                        right: 0
                    }}>
                        <Box width={50} display='flex' justifyContent='center'>
                            <span style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                textAlign: 'center',
                                textShadow: "2px 2px #fff",
                            }}>
                                {driver.sap}
                            </span>
                        </Box>
                    </div>
                    
                    <Box height={50} width={50}>
                        <Image 
                            src={current.order ? "/images/on_going.png" : (current.pickup ? "/images/pickup.png" : "/images/waiting.png")}
                            naturalHeight={1}
                            naturalWidth={1}
                        />
                    </Box>
                </Box>
            </TapArea>
        )
    }

    return (
        <Box>
            {
                (driver.roles[0].title == 'Түгээлт' || driver.roles[0].title == 'Түгээгч') ? renderPickup() : renderDriver()
            }
            
            {open && (
                <Layer>
                    <Modal 
                        onDismiss={() => setOpen(false)}
                        heading={
                            <Box>
                                <Text size={400} align='center' weight='bold'>Хуваарилах уу?</Text>
                            </Box>
                        }
                        footer={
                            <Box display='flex' marginTop={4}>
                                <Button 
                                    text='Хаах'
                                    fullWidth
                                    onClick={() => setOpen(false)}
                                />

                                <Box width={12} />

                                {
                                    fetching ? <Spinner show={true} /> : (
                                        <Button
                                            text='Хадгалах'
                                            fullWidth
                                            color='red'
                                            // onClick={onConfirm}
                                        />
                                    )
                                }
                                
                            </Box>
                        }
                    >
                        <Box>
                            {/* <Box flex="grow" padding={2}>
                                <Box marginBottom={2}>
                                    <Text>
                                        Жолооч хувиарлах
                                    </Text>
                                </Box>
                                <DriverSelector
                                    order={order}
                                    driver={driver}
                                    onDriverChange={(driver) => setDriver(driver)}
                                />
                            </Box> */}
                        </Box>
                    </Modal>
                </Layer>
            )}
        </Box>
    )
}

export default LocationPin