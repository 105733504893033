import React, { useState } from 'react'
import { Layer, Modal, Button, Box, Text, Spinner } from 'gestalt'
import { Grid, TextField } from "@material-ui/core";
import _isEmpty from 'lodash/isEmpty'

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOn from '@mui/icons-material/LocationOn'
import AsyncAutoComplete from '../../components/AsyncAutoComplete/AsyncAutoComplete'
import { priceList } from '../operator/component/OperatorDashboard'

import { currencyFormat } from '../../utils/helpers';
import Autocomplete from '@mui/material/Autocomplete';
import { editOrderHistory } from '../dashboard/components/OrderDashboardApi'

const EditOrder = (props) => {
    const { order = {} } = props

    const [note, setNote] = useState(order.note)
    const [from_location, setFromLocation] = useState(order.from_location)
    const [to_location, setToLocation] = useState(order.to_location)
    const [price, setPrice] = useState({
        price: order.price,
        label: currencyFormat(order.price)
    })

    const [fetching, setFetching] = useState(false)

    // if(_isEmpty(order)) return null
    const priceProps = {
        options: priceList.map((value) => { return { label: currencyFormat(value), value }}),
        getOptionLabel: (option) => option.label,
    }

    const onSave = () => {
        setFetching(true)
        editOrderHistory(order._id, {
            from_location,
            to_location,
            price: price.value,
            note,
        })  
        .then(res => {
            if(res.data.code != 0) {
                alert(res.data.errors[0].msg)
                return
            }

            if(res.data.code == 0) {
                props.onRefresh(res.data.order)
            }
        }) 
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Layer>
            <Modal 
                onDismiss={props.onClose}
                heading={
                    <Box>
                        <Text align='center' size={400} weight='bold'>Дуудлага засварлах</Text>
                    </Box>
                }
                footer={
                    <Box display='flex'>
                        <Button fullWidth text="Хаах" onClick={props.onClose} />
                        <Box width={12} />
                        {
                            fetching ? <Spinner show={true} /> : <Button fullWidth text="Хадгалах" color="red" onClick={onSave} />
                        }
                    </Box>
                }
            >
                <Box>
                    <Grid item xs={12}>
                        <Box display='flex' paddingX={4}>
                            <Box display='flex' justifyContent='center' alignItems='center' width={40}>
                                <DirectionsCarIcon 
                                />
                            </Box>
                            <Box flex='grow'>
                                <AsyncAutoComplete 
                                    id="test" 
                                    label='Хаанаас'
                                    value={from_location}
                                    onChange={(event, newValue) => {
                                        setFromLocation(newValue);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Box height={20} />
                    <Grid item xs={12}>
                        <Box display='flex' paddingX={4}>
                            <Box display='flex' justifyContent='center' alignItems='center' width={40}>
                                <LocationOn />
                            </Box>
                            <Box flex='grow'>
                                <AsyncAutoComplete 
                                    id="test" 
                                    label='Хаашаа'
                                    value={to_location}
                                    onChange={(event, newValue) => {
                                        setToLocation(newValue);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Box height={20} />
                    <Grid item xs={12}>
                        <Box paddingX={4}>
                            <Autocomplete
                                {...priceProps}
                                id="controlled-demo"
                                value={price}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setPrice(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}  variant="outlined" label="Үнэ" />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Box height={20} />
                    <Box paddingX={4}>
                        <TextField 
                            value={note}
                            fullWidth
                            onChange={(e) => setNote(e.target.value)}
                            label="Нэмэлт тэмдэглэл"
                            variant="outlined"
                        />
                    </Box>
                    <Box height={20} />

                </Box>
            </Modal>
        </Layer>
    )
}

export default EditOrder