import React, { useState, useEffect } from 'react'
import {
    Paper,
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
} from "@material-ui/core";
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination'
import { getMyFilteredOrder } from './OrderDashboardApi'
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router-dom";
import ReactPlaceholder from 'react-placeholder';

const OrderList = (props) => {
    const { search_value, process_name, filterFetching } = props
    const { t } = useTranslation();
    const locale = localStorage.getItem('locale')
    const [pageFetching, setPageFetching] = useState(false)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [orders, setOrders] = useState([])
    const [filter, setFilter] = useState(process_name)
    useEffect(() => {
        setPageFetching(true)
        fetchMyOrders()
    }, [page])

    useEffect(() => {
        props.setFilterFetching(true)
        if (page == 1) {
            fetchMyOrders()
        }
        else {
            setPage(1)
        }
    }, [search_value, process_name])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const FetchingIndicator = () => (
        <Grid
            container
            justify='center'
            style={{ paddingTop: 30 }}
        >
            <CircularProgress />
        </Grid>
    )

    const fetchMyOrders = () => {
        getMyFilteredOrder({
            search_value,
            process_name,
            page,
        })
            .then(res => {
                if (res.data.code == 0) {
                    setPages(res.data.result.pages)
                    setOrders(res.data.result.docs)
                }
            })
            .catch(err => alert(err))
            .then(() => props.setFilterFetching(false))
            .then(() => setPageFetching(false))
    }
    return (
        <>
            {
                filterFetching ? (
                    <FetchingIndicator />
                ) : (
                    <TableContainer
                        component={Paper}
                        style={{ paddingBottom: 30, backgroundColor: "white" }}
                    >
                        {
                            pageFetching ? (
                                <FetchingIndicator />
                            ) : (
                                <Table>
                                    <TableHead >
                                        <TableRow hover>
                                            <TableCell>{t("dashboard.order.list.headers.order_id")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.location")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.duration")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.due_date")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.leader")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.status")}</TableCell>
                                            <TableCell>{t("dashboard.order.list.headers.note")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            orders.map((order, index) => (
                                                <TableRow key={index} hover onClick={() => props.history.push(`/app/order/${order._id}`)}>
                                                    <TableCell>{order.order_id}</TableCell>
                                                    <TableCell>{order.location[`location_${locale}`]}</TableCell>
                                                    <TableCell>{order.begin_at ? moment(order.begin_at).format('YYYY/MM/DD HH:MM') : '-'}</TableCell>
                                                    <TableCell>{order.end_at ? moment(order.end_at).format('YYYY/MM/DD HH:MM') : '-'}</TableCell>
                                                    <TableCell>{order.duration}</TableCell>
                                                    <TableCell>{t(`order.detail.process.${order.current_process.name}`)}</TableCell>
                                                    <TableCell>{order.note}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            )
                        }
                        <Grid container alignItems='center' justify="center" style={{ paddingTop: 30 }}>
                            <Pagination count={pages} color="primary" onChange={onPagingChange} />
                        </Grid>
                    </TableContainer>
                )
            }
        </>
    )
}

export default withRouter(OrderList);