import React, { useState, useEffect } from 'react'


import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle"
import Widget from "../../../components/Widget/Widget"
import { useTranslation } from "react-i18next"
import { fetchPois, saveLocation } from '../../../utils/RestApi'
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon, MapPin } from 'react-feather'
import { Box, Text, Icon, Layer, Modal, TextField, Button, Spinner, TapArea } from 'gestalt'
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { Map } from 'react-feather'
import supercluster from 'points-cluster';
import { toast } from 'react-toastify'

export const Location = ({ poi, hasIcon = true, color = "dark" }) => {
    return (
        <Box>
            {
                hasIcon && (
                    <Icon 
                        icon="location"
                        color="brandPrimary"
                        size={30}
                    />
                )
            }
            
            <span style={{
                color,
                fontSize: 14,
                fontWeight: 'bold',
                textShadow: "1px 1px #fff",
                whiteSpace: 'nowrap',
            }}> 
                {poi.points[0].id}
            </span>
        </Box>
    )
}

const ClusterMarker = ({ points }) => {
    return (
        <Box width={40} height={40} color="dark" rounding="circle" display='flex' justifyContent='center' alignItems='center'>
            <Text color='light'>{points.length}</Text>
        </Box>
    )
}

const LocationList = (props) => {
    const { t } = useTranslation();
    const locale = localStorage.getItem('locale')
    const [locations, setLocations] = useState([])
    const [cluster_locations, setClusterLocations] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [search_value, setSearchValue] = useState('');
    const history = useHistory();
    const { btnColor = "secondary" } = props
    const [isOpen, setIsOpen] = useState(false)
    const [saving, setSaving] = useState(false)

    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [name, setName] = useState("")
    const [mapTypeId, setMapTypeId] = useState("satellite")
    
    const sukhbaatarSquire = {
        lat: 47.912411,
        lng: 106.9184605
    }

    const [mapOptions, setMapOptions] = useState({
        center: sukhbaatarSquire,
        zoom: 14,
    })

    const [center, setCenter] = useState(sukhbaatarSquire)
    const [zoom, setZoom] = useState(14)

    useEffect(() => {
        getPois()
    }, [])

    useEffect(() => {
        if(!mapOptions.bounds) return setClusterLocations([])

        const clusters = supercluster(locations.map((location) => {
            return {
                id: location.name,
                lat: location.coordinate[1],
                lng: location.coordinate[0]
            }
        }), {
            minZoom: 0,
            maxZoom: 16,
            radius: 60,
        });


        setClusterLocations(clusters(mapOptions))
    }, [mapOptions, locations])

    const onSaveLocation = () => {
        setSaving(true)
        saveLocation({
            x,
            y,
            name
        })
        .then(res => {
            if(res.data.code == 0) {
                setLocations(locations.concat([res.data.poi]))
                setIsOpen(false)
                toast.success("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const getPois = () => {
        fetchPois()
        .then(res => {
            if(res.data.code == 0) {
                setLocations(res.data.pois)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const handleMapChange = ({ center, zoom, bounds }) => {
        setMapOptions({
            center,
            zoom,
            bounds,
        })
            // this.createClusters(this.props);
    }

    const onClick = (params) => {
        const { lat, lng } = params
        setX(lng)
        setY(lat)
        setName("")
        setIsOpen(true)
    }

    return (
        <Box height="100vh" flex='grow' position="relative">
            <div style={{
                position: 'absolute',
                right: 10,
                top: 10,
                zIndex: 99
            }}>
                <TapArea tapStyle="compress" onTap={() => setMapTypeId(mapTypeId == "roadmap" ? "satellite" : "roadmap" )}>
                    <Box width={40} height={40} color="light" borderStyle='shadow' display='flex' justifyContent='center' alignItems='center'>
                        <Map 
                            size={20}
                        />
                    </Box>
                </TapArea>
            </div>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyB65Q6LHuGVifZDf1gNzi1hnqHwfjnnl_s" }}
                zoom={zoom}
                center={center}
                onClick={onClick}
                // onChange={this._onChange}
                options={{
                    mapTypeId,
                }}
                // onZoomChanged={(zoom) => alert(zoom)}
                onChange={handleMapChange}
            >
                {cluster_locations.map((item, index) => {
                    if (item.numPoints === 1) {
                    return (
                        <Location 
                            key={index}
                            lat={item.y}
                            lng={item.x}
                            poi={item}
                        />
                    );
                    }

                    return (
                        <ClusterMarker
                            key={item.id}
                            lat={item.y}
                            lng={item.x}
                            points={item.points}
                        />
                    );
                })}
            </GoogleMapReact>

            {
                isOpen && (
                    <Layer>
                        <Modal 
                            onDismiss={() => setIsOpen(false)}
                            heading={
                                <Box>
                                    <Text weight='bold' size='400'>Шинэ байршил нэмэх</Text>
                                </Box>
                            }
                            footer={
                                <Box display='flex'>
                                    <Button 
                                        text="Хаах"
                                        fullWidth
                                        onClick={() => setIsOpen(false)}
                                    />
                                    <Box width={8} />
                                    {
                                        saving ? (
                                            <Spinner show={true} />
                                        ) : (
                                            <Button 
                                                color="red"
                                                fullWidth
                                                text="Хадгалах"
                                                onClick={onSaveLocation}
                                            />
                                        )
                                    }
                                </Box>
                            }
                        >
                            <Box>
                                <TextField 
                                    value={name}
                                    placeholder='Байршил нэр'
                                    onChange={({ value }) => setName(value)}
                                />
                            </Box>
                        </Modal>
                    </Layer>
                )
            }
        </Box>
    )
}

export default LocationList