import { useState, useEffect } from 'react'
import { Box, Text, TapArea, Spinner } from 'gestalt'

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import ReportIcon from '@mui/icons-material/Report';

import { setOrderMood } from '../../../utils/RestApi'

import { toast } from 'react-toastify'

const CustomerMood = (props) => {
    const { order } = props
    const [mood, setMood] = useState(order.mood || 5)
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setMood(order.mood || 5)
    }, [order])

    const onSetOrderMood = (mood) => {
        setFetching(true)
        setOrderMood(order._id, {
            mood
        })
        .then(res => {
            if(res.data.code == 1) {
                return toast.error(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success("Амжилттай төлөв солилоо")
                setMood(mood)
            }   
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }
    
    return (
        <Box paddingY={2} color='light' paddingX={4}>
            <Text>Харилцагчийн төлөв байдал</Text>

            <Spinner show={fetching} />

            <Box display='flex' paddingY={2}>
                <TapArea tapStyle='compress' fullWidth={false} onTap={() => onSetOrderMood(5)}>
                    <Box paddingY={1} paddingX={2} rounding="pill" color={mood == 5 ? 'dark' : 'secondary'}>
                        <InsertEmoticonIcon 
                            style={{
                                color: mood == 5 ? 'white' : "black"
                            }}
                        />
                    </Box>
                </TapArea>

                <TapArea tapStyle='compress' fullWidth={false} onTap={() => onSetOrderMood(4)}>
                    <Box paddingY={1} paddingX={2} rounding="pill" color={mood == 4 ? 'dark' : 'secondary'} marginStart={1} marginEnd={1}>
                        <SentimentVeryDissatisfiedIcon 
                            style={{
                                color: mood == 4 ? 'white' : "black"
                            }}
                        />
                    </Box>
                </TapArea>
                
                <TapArea tapStyle='compress' fullWidth={false} onTap={() => onSetOrderMood(3)}>
                    <Box paddingY={1} paddingX={2} rounding="pill" color={mood == 3 ? 'dark' : 'secondary'}>
                        <MoodBadIcon 
                            style={{
                                color: mood == 3 ? 'orange' : "black"
                            }}
                        />
                    </Box>
                </TapArea>

                <TapArea tapStyle='compress' fullWidth={false} onTap={() => onSetOrderMood(2)}>
                    <Box paddingY={1} paddingX={2} rounding="pill" color={mood == 2 ? 'dark' : 'secondary'} marginStart={1} marginEnd={1}>
                        <PriorityHighIcon 
                            style={{
                                color: mood == 2 ? 'red' : "black"
                            }}
                        />
                    </Box>
                </TapArea>

                <TapArea tapStyle='compress' fullWidth={false} onTap={() => onSetOrderMood(1)}>
                    <Box paddingY={1} paddingX={2} rounding="pill" color={mood == 1 ? 'dark' : 'secondary'}>
                        <ReportIcon 
                            style={{
                                color: mood == 1 ? 'red' : "black"
                            }}
                        />
                    </Box>
                </TapArea>
            </Box>
        </Box>
    )
}

export default CustomerMood