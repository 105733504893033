import { request, localRequest } from '../../../utils/api'

export function getOrderSummary() {
    return request().get('order/summary')
}

export function getDrivers() {
    return request().get('calendar/available-drivers')
}

export function getOrderLogs(params) {
    let token = localStorage.getItem("token")
    return request(token).get('history/logs', {
        params
    })
}

export function getOrderFilterCount() {
    let token = localStorage.getItem("token")
    return request(token).get('order/filter-count')
}

export function getFilteredOrder(params) {
    let token = localStorage.getItem("token")
    return request(token).get('order', { params })
}

export function getMyOrderDashboard() {
    let token = localStorage.getItem("token")
    return request(token).get('customer/order-filter-count')
}

export function getMyFilteredOrder(params) {
    let token = localStorage.getItem("token")
    return request(token).get('customer/orders', { params })
}

export function getCurrentOrder(params) {
    let token = localStorage.getItem("token")
    return request(token).get('order/current-order', { params })
}

export function deleteOrder(order_id) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/delete`)
}

export function confirmOrder(order_id) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/confirm`)
}

export function getSelectedOrder(order_id) {
    return request().get(`order/${order_id}`)
}

export function setOrderDriver(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/set-driver`, params)
}

export function saveOrder(params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order`, params)
}

export function editOrder(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`order/${order_id}/edit`, params)
}

export function editOrderHistory(order_id, params) {
    let token = localStorage.getItem("token")
    return request(token).post(`history/${order_id}/edit`, params)
}