import React from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"

import { Grid, Paper, Button, TextField, MenuItem, IconButton, FormControl, InputLabel, Select, InputAdornment} from  '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));


const CreateOrder = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const [currency, setCurrency] = React.useState('Choose');

    const onCreateOrder  = () =>  props.history.push('/app/order/create')
    const onCreateOrderArea  = () =>  props.history.push('/app/order/create/area')
    
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });
    
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle
                    title={t("location.action.create")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("location.create.id")}
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={11}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("area.action.choose")}</InputLabel>
                            <Select
                            native
                            value={state.project}
                            onChange={handleChange}
                            label={t("area.action.choose")}
                            inputProps={{
                                name: 'state',
                                id: 'choose_state',
                            }}
                            >
                            <option aria-label="None" value="" />
                            <option value={10}>Төсөл 1</option>
                            <option value={20}>Төсөл 2</option>
                            <option value={30}>Төсөл 3</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={onCreateOrderArea}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("location.create.name")}
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("location.create.eng_name")}
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("location.create.desc")}
                            multiline
                            rows={4}
                            placeholder={t("location.create.desc")}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <div style={{
                            paddingTop: 20
                        }}>
                            <Grid justify='center' container>
                                <Button variant="contained" color="primary" onClick={onCreateOrder}>
                                    {t("location.action.create")}    
                                </Button>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateOrder