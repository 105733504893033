import React, { useState, useEffect, useCallback } from 'react'
import { Box, FixedZIndex, Layer, Spinner, Text } from 'gestalt'
import PageTitle from "../../components/PageTitle/PageTitle"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useHistory } from "react-router-dom";

import { getCalendarResult } from '../employee/EmployeeApi'
import _ from 'lodash'

const localizer = momentLocalizer(moment)

const CalendarView = (props) => {
    const history = useHistory();
    const [events, setEvents] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        fetchEvents()
    }, [])

    const fetchEvents = () => {
        setFetching(true)
        getCalendarResult({
            begin: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
            end: moment().endOf('month').format('YYYY-MM-DD hh:mm')
        })
        .then(res => {
            if(res.data.code == 0) {
                let events = _(res.data.drivers)
                .groupBy((driver) => moment(driver.date).format('YYYY-MM-DD'))
                .map((items, date) => {
                    return {
                        id: items[0]._id,
                        allDay: true,
                        title: `Ээлж [${items.length} хүн]`,
                        start: moment(date).toDate(),
                        end: moment(date).toDate(),
                    }
                })
                .value()

                events = events.concat(_(res.data.drivers)
                .groupBy((driver) => moment(driver.date).format('YYYY-MM-DD'))
                .map((items, date) => {
                    // const driverManagersCount = items.filter((item) => item.driver.roles[0].title == "ээлжийн ахлах").length
                    // const operatorCount = items.filter((item) => item.driver.roles[0].title == "Оператор").length
                    const deliveryCount = items.filter((item) => (item.driver.roles[0].title == 'Түгээлт' || item.driver.roles[0].title == 'Түгээгч')).length

                    const driverCount = items.length - deliveryCount

                    return {
                        id: items[0]._id,
                        allDay: true,
                        title: `Жол: ${driverCount}, Түг: ${deliveryCount}`,
                        start: moment(date).toDate(),
                        end: moment(date).toDate(),
                    }
                })
                .value())

                setEvents(events)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const handleSelectEvent = useCallback(
        (event) => {
            // console.log(event)
            const date = moment(event.start).format('YYYY-MM-DD')
            history.push(`/app/calendar/${date}`)
        },
        []
    )

    const handleSelectSlot = useCallback(
        (event) => {
            const date = moment(event.start).format('YYYY-MM-DD')
            history.push(`/app/calendar/${date}`)
        },
        []
    )

    return (
        <Box paddingX={10}>
            <PageTitle
                title="Ээлж тохируулах"
                // rightView={() => rView()}
                //btnColor="primary"
                // button={t("registration.employee.new_employee")}
                // onButtonClick={onCreateNewEmployee}  
            />

            <Calendar
                localizer={localizer}
                events={events}
                views={["month"]}
                startAccessor="start"
                endAccessor="end"
                selectable
                style={{ height: 500 }}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
            />

            {
                fetching && (
                    <Layer zIndex={new FixedZIndex(5)}>
                        <Box position="fixed" top left right bottom color="transparentDarkGray">
                            <Box height="100%" display='flex' direction='column' justifyContent='center' alignItems='center'>
                                <Spinner show={true} />
                                <Text color="light" size="500">Уншиж байна</Text>
                            </Box>
                        </Box>
                    </Layer>
                )
            }
        </Box>
    )
}

export default CalendarView