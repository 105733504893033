import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    convertToPixelCrop
} from 'react-image-crop'
import { Box, Button, Spinner } from 'gestalt'
// import { useDebounceEffect, canvasPreview } from 'app/utils/helpers'
import { request, cloudFront } from './S3'

const TO_RADIANS = Math.PI / 180

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()
}


export function useDebounceEffect(
  fn,
  waitTime,
  deps,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
}

const UploadImage = (props) => {
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [imgSrc, setImgSrc] = useState('')
    const aspect = props.aspect || 1
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [loading, setLoading] = useState(false)

    useDebounceEffect(
        async () => {
          if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
          ) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
              imgRef.current,
              previewCanvasRef.current,
              completedCrop,
              1,
              0,
            )
          }
        },
        100,
        [completedCrop],
    )
    
    useEffect(() => {
      props.onStatusChanged && props.onStatusChanged(imgSrc ? true : false)
    }, [imgSrc])

    // useEffect(() => {
    //   if (imgRef.current) {
    //     const { width, height } = imgRef.current
    //     const newCrop = centerAspectCrop(width, height, aspect)
    //     setCrop(newCrop)
    //     setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    //   }
    // }, [aspect])

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setCrop(undefined) // Makes crop preview update between images.
          const reader = new FileReader()
          reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || ''),
          )
          reader.readAsDataURL(e.target.files[0])
        }
    }

    const onImageLoad = (e) => {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    const onSave = () => {
      setLoading(true)
      onDownloadCropClick()
    }

    const onDownloadCropClick = async () => {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
          throw new Error('Crop canvas does not exist')
        }
    
        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
    
        const offscreen = new OffscreenCanvas(
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
          throw new Error('No 2d context')
        }
    
        ctx.drawImage(
          previewCanvas,
          0,
          0,
          previewCanvas.width,
          previewCanvas.height,
          0,
          0,
          offscreen.width,
          offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const pId = `avatar_${Math.floor(Math.random() * 100)}_${Math.floor(Math.random() * 100000)}`
        const blob = await offscreen.convertToBlob({
          type: 'image/png',
        })

        const file = new File([blob], pId + ".jpeg", { type: "image/jpeg" })

        request({
          dirName: "geru-by-me/avatar"
        }).uploadFile(file, (pId).replace(/\.[^/.]+$/, ""))
        .then(res => {
          const secure_url = cloudFront(res.location)
          setLoading(false)
          props.onDone(secure_url)
          setImgSrc("")
          setCompletedCrop(null)
        })
        .catch(err => alert(err))
    }

    return (
        <Box position='relative'>
            <Box marginTop={4}>
                <input type="file" accept="image/*" onChange={onSelectFile} />
            </Box>
            {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  ruleOfThirds
                  minHeight={200}
                >
                <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                />
                </ReactCrop>
            )}
            {!!completedCrop && (
                <>
                <div style={{ position: 'absolute', top: '-100%' }}>
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            opacity: 0,
                            width: completedCrop.width,
                            height: completedCrop.height,
                        }}
                    />
                </div>
                <div>
                    {
                      loading ? <Spinner show={true} /> : (
                        <Button 
                            color="red"
                            fullWidth
                            text="Хадгалах"
                            onClick={onSave}
                        />
                      )
                    }
                </div>
                </>
            )}
        </Box>
    )
}

export default UploadImage