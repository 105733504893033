import React, { useState, useEffect } from "react"
import { Spinner, Text, Box, Button, Layer, Modal, FixedZIndex } from "gestalt"
import PageTitle from "../../components/PageTitle/PageTitle"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'

import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import moment from 'moment';

import { getCrashLogs, deleteBanned, saveBanned } from '../../utils/RestApi'
import { toast } from "react-toastify";

import OrderCrashEdit from '../history/OrderCrashEdit'

const VipList = (props) => {
    const [search_value, setSearchValue] = useState('');
    const [crash_logs, setCrashLogs] = useState([])
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [selected, setSelected] = useState(null)
    const [focus, setFocus] = useState(false)
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    
    const [start_at, setStartAt] = useState(null)
    const [end_at, setEndAt] = useState(null)

    const [isOpenCrash, setIsOpenCrash] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        fetchData()
    }, [page, start_at, end_at])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEmployee = () => { setIsOpen(true) }

    const fetchData = () => {
        setFetching(true)
        getCrashLogs({
            start_at,
            end_at
        })
        .then(res => {
            if(res.data.code == 0) {
                setCrashLogs(res.data.result.docs)
                setPages(res.data.result.pages)
            }
        })
        .then(() => setFetching(false))
    }

    const onEdit = (crash) => {
        setSelected(crash)
        setIsOpenCrash(true)
    }
    const onDelete = (vip_id) => {
        if (window.confirm("Устгахдаа итгэлтэй байна уу") == true) {
            deleteBanned(vip_id)
            .then(res => {
                if(res.data.code != 0) {
                    return toast("Амжилттай устгалаа")
                }

                if(res.data.code == 0) {
                    fetchData()
                }
            })
        }
    }

    const onSave = () => {
        if(!note) return toast("Тэмдэглэл оруулна уу")
        if(!phone || String(phone).length != 8) return toast("Утас оруулна уу")

        setSaving(true)
        saveBanned({
            note,
            phone,
            end_at: moment(end_at).toDate()
        })
        .then(res => {
            if(res.data.code != 0) return toast(res.data.errors[0].msg)
            if(res.data.code == 0) {
                fetchData()
                setIsOpen(false)
                return toast("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const renderStatus = (crash) => {
        switch(crash.status) {
            case "waiting": return <Button size="sm" fullWidth onClick={() => onEdit(crash)} text="Хүлээлтэнд" color="red" />
            case "deal": return <Button size="sm" fullWidth onClick={() => onEdit(crash)} text="Тохиролцсон" color="blue" /> 
            case "insurance": return <Button size="sm" fullWidth onClick={() => onEdit(crash)} text="Даатгалаар шийдсэн"  color="gray" /> 
            default: return <Button size="sm" fullWidth onClick={() => onEdit(crash)} text="Хүлээлтэнд" color="red" />
        }
    }

    const onCrashDone = (crash) => {
        let newList = [...crash_logs]
        let index = newList.findIndex((cur) => String(cur._id) == String(crash._id))
        
        if(index != -1) {
            newList[index].crash_id = crash.crash_id
            newList[index].note = crash.note
            newList[index].status = crash.status

            setCrashLogs(newList)
        }

        setIsOpenCrash(false)
    }

    const calculateEndDate = (order) => {
        let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

        if(end.status == 'pending') return "-"

        return moment(end.start_at).format("HH:mm")
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                // title={t("registration.employee.title")}
                // rightView={() => rView()}
                //btnColor="primary"
                // button="Хар жагсаалтад нэмэх"
                // onButtonClick={onCreateNewEmployee}  
            />
            <div style={{ marginBottom: 20 }}>
                <Box display="flex">
                    <Box>
                        <Box marginBottom={1}>
                            <Text>Эхлэх огноо</Text>
                        </Box>
                        <TextField
                            id="outlined-basic"
                            type="date"
                            variant="outlined"
                            value={start_at}
                            onChange={(e) => setStartAt(e.target.value)}
                        />
                    </Box>
                    <Box width={12} />
                    <Box>
                        <Box marginBottom={1}>
                            <Text>Дуусах огноо</Text>
                        </Box>
                        <TextField
                            id="outlined-basic"
                            type="date"
                            variant="outlined"
                            value={end_at}
                            onChange={(e) => setEndAt(e.target.value)}
                        />
                    </Box>
                </Box>
            </div>
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Утас</TableCell>
                            <TableCell>Хаанаас</TableCell>
                            <TableCell>Хаашаа</TableCell>
                            <TableCell>Машин</TableCell>
                            <TableCell>Жолооч</TableCell>
                            <TableCell>Тэмдэглэл</TableCell>
                            <TableCell>Эхэлсэн</TableCell>
                            <TableCell>Дууссан</TableCell>
                            <TableCell>Бүртгэсэн огноо</TableCell>
                            <TableCell>Төлөв</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            crash_logs.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.order.phone}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.order.from_location.name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.order.to_location.name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.order.car.mark} {employee.order.car.plate}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.driver.sap} {employee.driver.first_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.note}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{moment(employee.created_at).format("YYYY/MM/DD")}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{moment(employee.order.created_at).format("YYYY/MM/DD")}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{calculateEndDate(employee.order)}</TableCell>
                                        {/* <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onEdit(employee._id)}>
                                                <EditIcon color='gray' />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>
                                            {renderStatus(employee)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            {
                isOpen && (
                    <Layer>
                        <Modal size='lg' footer={
                            <Box display="flex" marginTop={6}>
                                <Button text="Хаах" fullWidth onClick={() => setIsOpen(false)} />
                                <Box width={12} />
                                {
                                    saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={() => onSave()} />
                                }
                            </Box>
                        } 
                            heading={
                                <Text size='400'>Хар жагсаалтанд бүртгэх</Text>
                            }
                            onDismiss={() => setIsOpen(false)}
                        >
                            <Box>
                                <Box paddingY={4}>
                                    <TextField 
                                        fullWidth
                                        label="Утасны дугаар"
                                        type="number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Box>
                                <Box marginBottom={12}>
                                    <TextField
                                        fullWidth
                                        label="Тэмдэглэл оруулах"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </Box>
                                <Box marginTop={12} position="relative">
                                    <Box marginBottom={1}>
                                        <Text color='subtle'>Дуусах огноо оруулах</Text>
                                    </Box>
                                    
                                    <TextField 
                                        type="date"
                                        value={end_at}
                                        defaultValue={end_at}
                                        onChange={(e) => setEndAt(e.target.value)}
                                    />
                                </Box>
                            </Box>

                            
                        </Modal>
                    </Layer>
                )
            }
            {
                isOpenCrash && (
                    <OrderCrashEdit crash={selected} onClose={() => setIsOpenCrash(false)} onSave={onCrashDone} />
                )
            }
        </Box>
    )
};

export default VipList;
