import React, { useRef, useState, useEffect, Component } from 'react'
import { Button, Heading, Box, Text, TapArea, Image, Icon, IconButton, Layer, Modal, Spinner } from 'gestalt'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchDeliveryCab, pickupPositionChange, pickupDone, pickupMoveChange, saveDeliveryCab } from '../../utils/RestApi'
import _isEqual from 'lodash/isEqual'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const InCabDrivers = (props) => {
    const { pickup } = props

    let in_cab_drivers = pickup.in_cab && pickup.in_cab.drivers ? pickup.in_cab.drivers : []

    in_cab_drivers = in_cab_drivers.filter((driver) => {
        let index = pickup.pickups.findIndex((current) => current.driver && String(current.driver._id) == String(driver._id))
        return index == -1
    })


    return (
        <Box display='flex' wrap>
            {
                in_cab_drivers.map((driver) => (
                    <Box paddingX={2} color="light" rounding={4} marginEnd={1} marginBottom={1}>
                        <Text weight='bold'>{driver.sap}</Text>
                    </Box>
                ))
            }
            {
                pickup.pickups.map((pickup) => (
                    <Box paddingX={2} color={pickup.pickup_type == 'pickup' ? "successBase" : "recommendationBase"} rounding={4} marginEnd={2}>
                        <Text weight='bold' color='light'>{pickup.driver && pickup.driver.sap}</Text>
                    </Box>
                ))
            }
        </Box>
    )
}

const PickupItem = (props) => {
    const { item } = props
    const { driver } = item

    const [fetching, setFetching] = useState(false)

    const onPickupDone = (status) => {
        // alert(pickup_id)
        pickupDone(item._id, {
            status
        })
        .then(res => {
            if(res.data.code == 0) {
                toast.success("Амжилттай хадгаллаа")
                props.onRemove(item)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onDone = () => {
        if (window.confirm("Дууссан төлөвт оруулахдаа итгэлтэй байна уу") == true) {
            onPickupDone("done")
        }
    }

    const onRemove = () => {
        if (window.confirm("Устгахдаа итгэлтэй байна уу") == true) {
            onPickupDone("rejected")
        }
    }

    if(driver == null) return null

    return (
        <Box display='flex' height={80}>
            <Box width={6} color={item.pickup_type == "drop" ? "recommendationBase" : "successBase"} />

            <Box flex="grow" color='light' paddingX={4} paddingY={2}>
                <Text size={200}>[{driver.sap}] {driver.first_name}.{driver.last_name.slice(0, 1)} {driver.phones}</Text>
                {
                    (false || item.pickup_type == "drop") && (
                        <Box marginTop={2}>
                            <Text size={200} weight='bold'>{item.location.name}</Text>
                        </Box>
                    )
                }
            </Box>
            {
                fetching ? <Spinner show={true} /> : (
                    <Box display='flex' direction='column' justifyContent="between" color='light'>
                        <IconButton
                            icon="check"
                            size="sm"
                            iconColor="gray"
                            bgColor="transparent"
                            onClick={onDone}
                        />

                        <IconButton
                            icon="remove"
                            size="sm"
                            iconColor="gray"
                            bgColor="transparent"
                            onClick={onRemove}
                        />
                    </Box>
                )
            }
        </Box>
    )
}

// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#323232' : 'grey',
    // borderWidth: 0,
    // padding: 2,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#323232' : 'transparent',
    padding: grid,
    // width: 250
});

const PickupList = (props) => {
    const { drivers } = props
    const [fetching, setFetching] = useState(false)
    const [isMore, setIsMore] = useState(false)
    const [pickups, setPickups] = useState([])
    
    useEffect(() => {
        getDeliveryCab()
    }, [])

    useEffect(() => {
        setInterval(() => {
            getDeliveryCab()
        }, 10000)
    }, [])

    const onRefresh = () => {
        getDeliveryCab()
    }

    const onMore = () => {
        setIsMore(true)
    }

    const getDeliveryCab = () => {
        setFetching(true)
        fetchDeliveryCab()
        .then(res => {
            if(res.data.code == 0) {
                setPickups(res.data.agents)
                // let agents = res.data.agents
                // setPickups(agents.concat(agents))
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onRemovePickup = (pickup, item) => {
        let index = pickups.findIndex((current) => String(current._id) == String(pickup._id))

        if(index != -1) {
            let newPickups = [...pickups]
            newPickups[index].pickups = newPickups[index].pickups.filter((pickup) => String(pickup._id) == String(item._id))
            setPickups(newPickups)
        }
    }

    return (
        <div style={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            right: 0,
            height: isMore ? 700 : 240,
            display: 'flex',
            flexDirection: 'column'
        }}>
            {
                isMore && (
                    <Box display='flex' justifyContent='center' paddingY={4}>
                        <Box column={4} display='flex' justifyContent='center'>
                            <TapArea onTap={() => setIsMore(false)} fullWidth={false}>
                                <Box maxWidth={200} width={200} color="transparentDarkGray" paddingY={2} display='flex' justifyContent='center'>
                                    <Text color='light' weight='bold'>Хаах</Text>
                                </Box>
                            </TapArea>
                            {/* <Button 
                                text="Хаах"
                                color="transparentWhiteText"
                                fullWidth
                                onClick={() => setIsMore(false)}
                            /> */}
                        </Box>
                    </Box>
                )
            }

            <div style={{
                // boxSizing: 'border-box',
                overflowY: 'scroll',
                // height: '100vh',
                position: 'relative',
            }}>
                <QuoteApp 
                    pickups={pickups}
                    drivers={drivers}
                    onToggleMore={() => setIsMore(!isMore)}
                    onRefresh={onRefresh}
                    onRemovePickup={onRemovePickup}
                />
            </div>
        </div>
    )
}

export function usePrevious(value) {
    const ref = useRef();
  
    useEffect(() => {
      ref.current = JSON.stringify(value);
    }, [value]); 
  
    return ref.current;
}

function QuoteApp(props) {
    const { pickups } = props
    const drivers = props.drivers.filter((current) => current.driver.roles[0].title != 'Түгээгч')
    const [state, setState] = useState([]);
    const oldPickups = usePrevious(pickups);

    const [isOpen, setIsOPen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [selected_pickup, setSelectedPickup] = useState({})
    const [selected_drivers, setSelectedDrivers] = useState([])

    useEffect(() => {
        if(!_isEqual(oldPickups, JSON.stringify(pickups))) {
            // alert('rerender')
            setState(pickups)
        }
        
    }, [pickups])

    useEffect(() => {
        // alert('gey')
    }, [state])

    // const onRemovePickup = (pickup, item) => {
    //     setPickups
    // }

    const onSavePickupCab = () => {
        setSaving(true)
        saveDeliveryCab({
            pickup: selected_pickup.driver._id,
            in_drivers: selected_drivers.map((driver) => driver._id)
        })
        .then(res => {
            if(res.data.code == 0) {
                props.onRefresh()
                toast.success("Амжилттай хадгаллаа")
                setIsOPen(false)
            } 
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    // console.log(pickups)
    
    function onDragEnd(result) {
      const { source, destination } = result;
  
      // dropped outside the list
      if (!destination) {
        return;
      }
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;
  
      if (sInd === dInd) {
        const pickup_id = state[sInd].pickups[source.index]._id

        pickupPositionChange(state[sInd].driver._id, {
            pickup_id,
            source_index: source.index,
            destination_index: destination.index,
        })
        .then(res => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }
        })
        .catch(err => alert(err))
        .then(() => {
            props.onRefresh()
        })

        const pickups = reorder(state[sInd].pickups, source.index, destination.index);
        
        const newState = [...state];
        newState[sInd].pickups = pickups;
        setState(newState);
      } else {
        const pickup_id = state[sInd].pickups[source.index]._id
        const moved_pickup_id = state[dInd].driver._id

        pickupMoveChange(state[sInd].driver._id, {
            moved_pickup_id,
            pickup_id,
            source_index: source.index,
            destination_index: destination.index,
        })
        .then(res => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }
        })
        .catch(err => alert(err))
        .then(() => {
            props.onRefresh()
        })

        const result = move(state[sInd].pickups, state[dInd].pickups, source, destination);
        const newState = [...state];
        newState[sInd].pickups = result[sInd];
        newState[dInd].pickups = result[dInd];
        setState(newState);
      }
    }

    const onToggleDriver = (driver) => {
        let exists = selected_drivers.find((current) => String(current._id) == String(driver._id))

        if(exists) {
            setSelectedDrivers(selected_drivers.filter((current) => String(current._id) != String(driver._id)))
        } else {
            setSelectedDrivers([...selected_drivers, driver])
        }
    }
  
    return (
        <Box>
            
            <Box display='flex' justifyContent={state.length <= 3 ? "center" : "start"} >
            <DragDropContext onDragEnd={onDragEnd}>
                {state.map((pickup, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                    >
                        <Box color="dark">
                            <Box width={260} paddingY={2} position='relative'>
                                <div style={{
                                    position: 'absolute',
                                    right: 12,
                                    top: 12,
                                }}>
                                    <Box>
                                        <IconButton
                                            icon="edit" 
                                            bgColor="lightGray"
                                            size="sm"
                                            onClick={() => {
                                                setSelectedPickup(pickup)
                                                setSelectedDrivers(pickup.in_cab ? pickup.in_cab.drivers : [])
                                                setIsOPen(true)
                                            }}
                                        />
                                    </Box>
                                </div>
                                <TapArea onTap={() => props.onToggleMore()} key={ind} fullWidth={false} tapStyle="compress">
                                    <Box color="dark" borderStyle="shadow" marginStart={2} marginEnd={2}>
                                        <Box display='flex' alignItems='center'>
                                            <Box paddingX={5} display='flex' direction="column" alignItems='center'>
                                                <Box height={40} width={Math.ceil(40 * 1.375586854460094)}>
                                                    <img 
                                                        src="/images/bus.png"
                                                        style={{
                                                            width: '100%',
                                                            // filter: 'grayscale(1)'
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box flex='grow' paddingY={4} display='flex' direction='column' alignItems='center'>
                                                <Text color='light'>#{pickup.driver.sap} {pickup.driver.last_name.slice(0, 1)}. {pickup.driver.first_name}</Text>
                                                <Text align='center' weight='bold' color='light'>{pickup.driver.phones}</Text>

                                                <Box height={12} />
                                            </Box>
                                            <Box paddingX={2}>
                                                <Box width={40} />
                                            </Box>
                                        </Box>

                                        <InCabDrivers 
                                            pickup={pickup}
                                        />
                                    </Box>
                                </TapArea>
                                <Box paddingX={2} marginTop={2}>
                                    {pickup.pickups.map((item, index) => (
                                        <Draggable
                                            key={String(item._id)}
                                            draggableId={String(item._id)}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <PickupItem 
                                                        item={item}
                                                        pickup_id={pickup.driver._id}
                                                        onRemove={() => props.onRemovePickup(pickup, item)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    )}
                </Droppable>
                ))}
            </DragDropContext>

            {
                isOpen && (
                    <Layer>
                        <Modal 
                            heading={
                                <Box>
                                    <Text size='400' weight='bold' align='center'>Сууж яваа жолооч тохируулах</Text>
                                </Box>
                            } footer={
                                <Box display='flex'>
                                    <Button text="Хаах" fullWidth onClick={() => setIsOPen(false)} />
                                    <Box width={12} />
                                    {
                                        saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={onSavePickupCab}/>
                                    }
                                </Box>
                            }
                            onDismiss={() => setIsOPen(false)}
                        >
                            <Box>
                            {
                                drivers.map((current, index) => {
                                    const selected = selected_drivers.find((driver) => String(current.driver._id) == String(driver._id))

                                    return <TapArea tapStyle="compress" key={index} fullWidth onTap={() => onToggleDriver(current.driver)}>
                                        <Box display='flex' alignItemds='center' color='light' borderStyle='sm' paddingY={2} paddingX={4} marginBottom={3}>
                                            <Box display='flex' alignItems='center' marginEnd={2}>
                                                <Icon
                                                    icon={selected ? "check-circle" : "circle-outline"}
                                                    size={20}
                                                    color={selected ? "success" : "black"}
                                                />
                                            </Box>
                                            <Box>
                                                <Text inlin><Text inline weight='bold'>[{current.driver.sap}] {current.driver.first_name}</Text>.{current.driver.last_name.slice(0, 1)}</Text>
                                                <Text size='200'>{current.driver.phones} / {current.driver.roles[0].title}</Text>
                                            </Box>
                                        </Box>
                                    </TapArea>
                                })
                            }
                            </Box>
                        </Modal>
                    </Layer>
                )
            }
            </Box>
        </Box>
    );
  }

export default PickupList