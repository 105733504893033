import React from 'react'
import { Box, Text, Image } from 'gestalt'
import { _getProcessColor, _getProcessImg } from '../../utils/helpers'

const ToLocationPin = (props) => {
    const { order } = props
    return (
        <Box color="brand">
            {
                order.driver && (
                    <div style={{
                        position: 'absolute',
                        top: -44,
                        left: -5,
                        right: -5,
                        width: 50,
                        display: "flex"
                    }}>
                        <p style={{
                            fontWeight: 'bold',
                            fontSize: 18,
                            // position: "absolute",
                            textAlign: 'center',
                            textShadow: "2px 2px #fff",
                            color: _getProcessColor(order.current_process.name)
                        }}>
                            {order.driver.sap}
                        </p>
                    </div>
                )
            }
            
            <Box height={30} width={30}>
                <Image 
                    src="/images/to_location.png"
                    naturalHeight={1}
                    naturalWidth={1}
                />
                {/* <img 
                    
                    style={{
                        height: 60,
                        width: '100%'
                    }}
                /> */}
            </Box>
        </Box>
    )
}

export default ToLocationPin