import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Button,
    TextField,
    Paper,
    TableContainer
} from "@material-ui/core";
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import { getWarehouseInventory, saveInventory } from '../../utils/RestApi'
import { 
    Trash as TrashIcon, 
    Edit as EditIcon, 
    List as ListIcon,
    Lock as LockIcon,
    MapPin as MapPinIcon,

} from 'react-feather'
import { toast } from 'react-toastify'

const WarehouseInventory = (props) => {
    const warehouse_id = props.match.params.warehouse_id
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [resources, setResources] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [editable, setEditable] = useState(false)
    const locale = localStorage.getItem('locale')
    const history = useHistory();

    useEffect(() => {
        fetchInventory()
    }, [page])

    const fetchInventory = () => {
        setFetching(true)
        getWarehouseInventory(warehouse_id)
        .then((res) => {
            if (res.data.code == 0) {
                setResources(res.data.resources) //users
                // setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onBack = () => history.push('/app/inventory');

    const onSaveInventory = () => {

        let inventories = resources.map((resource) => {
            return {
                resource_id: resource._id,
                in_stock_quantity: resource.in_stock_quantity
            }
        })

        setFetching(true)
        saveInventory(warehouse_id, {
            inventories
        })
        .then((res) => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.push('/app/inventory');
            }
            //
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onSetInventory = (warehouse_id) => {
        history.push(`/app/warehouse/${warehouse_id}/inventory`)
    }

    const onNewWarehouse  = () =>  {
        history.push('/app/warehouse/create')
    }

    const onChangeQuantity = (e, index) => {
        let newResources = [...resources]
        newResources[index].in_stock_quantity = e.target.value
        setResources(newResources)
    } 

    return (    
        <div>
            <PageTitle
                title={t("registration.warehouse.title")}
                button={t("warehouse.configure_inventory")}
                //onButtonClick={onNewWarehouse}  
            />
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('naming.resource')}</TableCell>
                            <TableCell>{t('naming.quanity')}</TableCell>
                            <TableCell colSpan={2} align='center'>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            resources.map((resource, index) => (
                                <TableRow key={index} hover>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{resource[`name_${locale}`]}</TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            value={resource.in_stock_quantity}
                                            onChange={(e) => onChangeQuantity(e, index)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: 30 }}>
                                        <IconButton onClick={() => onSetInventory(resource._id)}>
                                            <EditIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ height: 30 }}></div>
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <Grid justify='center' container>
                        <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                            {t("action.back")}  
                        </Button>
                        {
                            fetching && (
                                <Button variant="contained" color="primary" disabled>
                                    {t("action.save")}  
                                </Button>
                            ) || (
                                <Button variant="contained" color="primary" onClick={onSaveInventory}>
                                    {t("action.save")}  
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default WarehouseInventory