import React from 'react'
import { Box, Image, Text, Mask, Heading, Link } from 'gestalt'
import logo from '../login/jolooch.png'
import Appstore from '../login/Appstore.png'
import Playstore from '../login/Playstore.png'

const DownloadLink = () => {
    return (
        <Box display='flex' justifyContent='center'>
            <Box maxWidth={600} paddingX={6} paddingY={12}>
                <Box>
                    <img 
                        src={logo}
                        style={{
                            width: '100%'
                        }}
                    />
                </Box>
                
                <Box>
                    <Box paddingY={10} marginTop={4}>
                        <Text align='center'>Жолоочийн түргэн тусламж апп татах</Text>
                    </Box>
                    <Box paddingX={10}>
                        <Link href="https://play.google.com/store/apps/details?id=com.simplood.jolooch" target="blank">
                            <Box display='flex'>
                                <img 
                                    src={Playstore}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </Box>
                        </Link>
                        <Box height={12} />
                        <Link href='https://apps.apple.com/us/app/jolooch103/id6454901906' target="blank">
                            <Box>
                                <img 
                                    src={Appstore}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </Box>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default DownloadLink