import React, { useEffect } from 'react'
import { Box, Text, Divider, Spinner } from 'gestalt'
import { TextField } from "@material-ui/core";
import moment from 'moment'

import { leaveText } from '../../../utils/RestApi'
import { toast } from 'react-toastify'

const CustomerResponse = (props) => {
    const { order, addable = true } = props
    const [text, setText] = React.useState("")
    const [fetching, setFetching] = React.useState(false)
    const [responses, setResponses] = React.useState([])

    useEffect(() => {
        setResponses(order.response_logs || [])
    }, [order])

    const onLeaveText = () => {
        setFetching(true)
        leaveText(order._id, {
            text,
        })
        .then(res => {
            if(res.data.code == 0) {
                setText("")
                toast.success("Амжилттай хадгаллаа")
                setResponses([...responses, res.data.response_log])
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Box padding={4}>
            <Box color='light' borderStyle='raisedBottomShadow'>
                <Box>
                {
                    responses.map((response, index) => (
                        <Box key={index}>
                            <Box paddingX={4} paddingY={2}>
                                <Text>{response.text}</Text>
                                <Box display='flex' justifyContent='between'>
                                    <Text size='200' color='subtle'>{moment(response.text_at).fromNow()}</Text>

                                    <Text size='200' color='subtle'>{response.text_by}</Text>
                                </Box>
                            </Box>

                            <Divider />
                        </Box>
                    ))
                }
                </Box>
                <Spinner show={fetching} />
                {
                    addable && (
                        <TextField 
                            value={text} 
                            onChange={(e) => setText(e.target.value)} 
                            fullWidth 
                            variant="filled" 
                            label="Тэмдэглэл оруулах"  
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                // Do code here
                                ev.preventDefault();
                                onLeaveText()
                                }
                            }}
                        />
                    )
                }
                
            </Box>
        </Box>
    )
}

export default CustomerResponse