import React, { useRef, useState, useEffect } from 'react'
import { Box, Layer, OverlayPanel, Button, FixedZIndex, Text, Spinner, IconButton } from "gestalt"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Paper,
    TableContainer
} from "@material-ui/core";
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

import { getEmployees } from './EmployeeApi'
import { updateInsurances, getInsuranceDate } from '../../utils/RestApi'
import { toast } from 'react-toastify';

import EmployeeSelector from './EmployeeSelector'

const CreateInsuranceList = (props) => {
    const { insurance } = props
    const [end_at, setEndAt] = useState(moment())
    const [focus, setFocus] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [employees, setEmployees] = useState([])
    const [search_value, setSearchValue] = useState("")

    const [saving, setSaving] = useState(false)
    const [isOpenSelector, setIsOpenSelector] = useState(false)

    const isMountingRef = useRef(false);

    // useEffect(() => {
    //     isMountingRef.current = true;
    // }, []);

    useEffect(() => {
        if(isMountingRef.current) {
            fetchAssignedDrivers()
        } else {
            isMountingRef.current = true;
        }
    }, [end_at])

    useEffect(() => {
        if(insurance) {
            setEndAt(moment(insurance.date))
        } else {
            fetchAssignedDrivers()
        }
    }, [insurance])

    const fetchAssignedDrivers = () => {
        setEmployees([])
        setFetching(true)
        getInsuranceDate(end_at.format("YYYY-MM-DD"), {
            page: 1,
            limit: 200,
            search_value,
            selected_filter: "assigned",
        })
        .then((res) => {
            if (res.data.code == 0) {
                setEmployees(res.data.drivers) //users
            }
        })
        .then(() => setFetching(false))

        // getAssignedDrivers(end_at.toDate(), {
        //     date: end_at.toDate()
        // })
        // .then(res => {
        //     if(res.data.code == 0) {
        //         // setCalendar(res.data.calendar)
        //         setEmployees(res.data.drivers)
        //     }
        // })
        // .catch(err => alert(err))
    }

    const onRemoveEmployee = (index) => {
        let newEmployees = [...employees]
        newEmployees.splice(index, 1)
        setEmployees(newEmployees)
    }

    const onSave = () => {
        setSaving(true)
        updateInsurances(end_at.format("YYYY-MM-DD"), {
            drivers: employees.map((employee) => employee._id)
        })
        .then(res => {
            if(res.data.code == 0) {
                toast("Амжилттай хадгаллаа")
                props.onSave()
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }
    
    const onSaveSelector = (list) => {
        // let newEmployees = [...employees]
        // newEmployees = newEmployees.concat(list)
        setEmployees(list)

        setIsOpenSelector(false)
    }

    return (
        <Layer zIndex={new FixedZIndex(9999)}>
            <OverlayPanel
                size='lg'
                onDismiss={props.onDismiss}
                footer={
                    <Box display="flex">
                        <Button text="Хаах" fullWidth onClick={props.onDismiss} />
                        <Box width={12} />
                        {
                            saving ? (
                                <Spinner show={true} />
                            ) : <Button text="Хадгалах" fullWidth color="red" onClick={onSave} />
                        }
                    </Box>
                }
            >
                <Box marginBottom={6}>
                    <Box marginTop={2} position="relative" display="flex" justifyContent="between" alignItems="center">
                        <Box>
                            <Box marginBottom={1}>
                                <Text color='subtle'>Даатгал илгээх өдөр</Text>
                            </Box>
                            <SingleDatePicker 
                                date={end_at} // momentPropTypes.momentObj or null
                                onDateChange={(date) => setEndAt(date)} // PropTypes.func.isRequired
                                focused={focus} // PropTypes.bool
                                onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
                                numberOfMonths={1}
                                displayFormat="YYYY/MM/DD"
                                showClearDate={true}
                                isOutsideRange={() => false}
                            />
                        </Box>
                        <Box>
                            <Button 
                                text="Ажилтан нэмж оруулах" 
                                color="red" 
                                iconEnd="add-circle"
                                onClick={() => setIsOpenSelector(true)}
                            />
                        </Box>
                    </Box>
                </Box>

                <Spinner show={fetching} />

                <TableContainer component={Paper}>
                    <Table className="mb-0">
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Регистр дугаар</TableCell>
                                <TableCell>Овог</TableCell>
                                <TableCell>Нэр</TableCell>
                                <TableCell>Утас</TableCell>
                                <TableCell>Албан тушаал</TableCell>
                                <TableCell>Хасах</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                employees.map((employee, index) => { 
                                    return (
                                        <TableRow key={index} hover style={{
                                            background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                        }}>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.registry_number}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.last_name}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.first_name}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.phones}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.roles.length > 0 && employee.roles[0].title}</TableCell>
                                            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>
                                                <IconButton 
                                                    icon="trash-can"
                                                    iconColor="brandPrimary"
                                                    size="sm"
                                                    onClick={() => onRemoveEmployee(index)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table> 
                </TableContainer>                           
            </OverlayPanel>
            {
                isOpenSelector && (
                    <EmployeeSelector 
                        onDismiss={() => setIsOpenSelector(false)} 
                        selected_users={employees}
                        onSave={onSaveSelector}
                    />
                )
            }
            
        </Layer>
    )
};

export default CreateInsuranceList;
