import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";

import './index.css'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Translation from './utils/Translation'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'gestalt/dist/gestalt.css'
import 'react-image-crop/dist/ReactCrop.css'
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from 'moment'
// import 'moment/locale/mn'  // without this line it didn't work
import { AppProvider } from "./components/RealmApp";
import atlasConfig from "./atlasConfig.json";
const locale = localStorage.getItem("locale")

// moment.locale('mn')

const { appId } = atlasConfig

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: Translation,
  lng: locale ? locale : "mn", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

ReactDOM.render(
  <AppProvider appId={appId}>
    <LayoutProvider>
      <UserProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </UserProvider>
    </LayoutProvider>
  </AppProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
