import React, { useEffect, useRef, useState } from 'react'
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Box, TapArea, Button, Text, Image, Layer, Modal, Spinner, FixedZIndex } from 'gestalt'

import DriverSelector from '../operator/component/DriverSelector';
import { setOrderDriver } from '../dashboard/components/OrderDashboardApi'
import { _getProcessColor, _getProcessImg } from '../../utils/helpers'
import LocationOn from '@mui/icons-material/LocationOn'

const LocationPin = (props) => {
    const anchorRef = useRef();
    const { selected, drivers, order } = props
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false)

    const [driver, setDriver] = useState("")

    useEffect(() => {
        if(open && order.driver) {
            setDriver(order.driver)
        }
    }, [open])

    const onConfirm = () => {
        // setOpen(false)
        setFetching(true)
        setOrderDriver(order._id, { driver: driver._id })
        .then((res) => {
            if(res.data.code != 0) return alert(res.data.errors[0].msg)

            if(res.data.code == 0) {
                props.onRefresh()
                setOpen(false)
            }
        })
        .catch(err => alert(err))
        .then(() => {
            setFetching(false)
        })
    }

    const zIndex = new FixedZIndex(999)

    return (
        <div style={{
            zIndex: selected ? 2 : 1,
            position: 'absolute',
            // background: selected ? "red" : "green"
        }}>
            <TapArea 
                tapStyle="compress"
                accessibilityExpanded={open}
                accessibilityControls="main-example"
                onTap={() => setOpen(!open)}
                ref={anchorRef}
                size="lg"
            >
                <div style={{  
                    width: 54, 
                    // height: 54,
                    // padding: 10,
                    // background: _getProcessColor(order.current_process.name),
                    borderRadius: 27 
                }}>
                    <Box position='relative'>
                        {
                            order.driver && (
                                <div style={{
                                    position: 'absolute',
                                    top: -30,
                                    left: 0,
                                    right: 0,
                                }}>
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                        textAlign: 'center',
                                        textShadow: "2px 2px #fff",
                                        color: _getProcessColor(order.current_process.name)
                                        // textStroke: '2px red'
                                    }}>
                                        {order.driver.sap}
                                    </p>
                                    {/* <Text size="300" weight='bold' align='center'>{order.driver.sap}</Text> */}
                                </div>
                            )
                        }
                        <Box height={60}>
                            <Image 
                                src={_getProcessImg(order.current_process.name)}
                                naturalHeight={1}
                                naturalWidth={1}
                            />
                            {/* <img 
                                
                                style={{
                                    height: 60,
                                    width: '100%'
                                }}
                            /> */}
                        </Box>
                    </Box>
                </div>
            </TapArea>
            {open && (
                <Layer zIndex={zIndex}>
                    <Modal 
                        onDismiss={() => setOpen(false)}
                        // heading={
                        //     <Box>
                        //         <Text size={400} align='center' weight='bold'>Хуваарилах уу?</Text>
                        //     </Box>
                        // }
                        footer={
                            <Box display='flex' marginTop={4}>
                                <Button 
                                    text='Хаах'
                                    fullWidth
                                    onClick={() => setOpen(false)}
                                />

                                <Box width={12} />

                                {
                                    fetching ? <Spinner show={true} /> : (
                                        <Button
                                            text='Хадгалах'
                                            fullWidth
                                            color='red'
                                            onClick={onConfirm}
                                        />
                                    )
                                }
                                
                            </Box>
                        }
                    >
                        <Box>
                            <Box flex="grow" padding={2}>
                                <Box color='secondary' display='flex' justifyContent='between' paddingY={2} marginBottom={4}>
                                    <Box display='flex'>
                                        <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                            <LocationOn />
                                        </Box>
                                        <Box flex='grow' marginEnd={6}>
                                            <Text>{order.from_location.name}</Text>
                                        </Box>
                                    </Box>
                                    <Box display='flex' paddingX={2}>
                                        <Text>{order.car.mark}</Text>
                                        <Box width={10}/>
                                        <Text weight='bold'>{order.car.plate}</Text>
                                    </Box>
                                </Box>
                                <Box marginBottom={2}>
                                    <Text>
                                        Жолооч хувиарлах
                                    </Text>
                                </Box>
                                <DriverSelector
                                    order={order}
                                    drivers={drivers}
                                    driver={driver}
                                    onDriverChange={(driver) => setDriver(driver)}
                                />
                            </Box>
                        </Box>
                    </Modal>
                </Layer>
            )}
        </div>
    )
}

export default LocationPin