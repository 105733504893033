import React, { useEffect, useState } from 'react'
import { Layer, Modal, Button, Box, Text, Spinner } from 'gestalt'
import _isEmpty from 'lodash/isEmpty'

import { getOrderLogs } from '../dashboard/components/OrderDashboardApi'
import { PrevOrders } from '../operator/component/OperatorDashboard'
import { OrderItemInline } from '../dashboard/components/OrderList'

const EditOrder = (props) => {
    const { order = {} } = props
    const [fetching, setFetching] = useState(false)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        getOrderHistories()
    }, [])

    const getOrderHistories = () => {
        setFetching(true)
        getOrderLogs({
            phone: order.phone
        })
        .then(res => {
            if(res.data.code == 0) {
                setOrders(res.data.orders)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Layer>
            <Modal 
                onDismiss={props.onClose}
                heading={
                    <Box>
                        <Text align='center' size={400} weight='bold'>Дуудлага түүх</Text>
                    </Box>
                }
                footer={
                    <Box display='flex'>
                        <Button fullWidth text="Хаах" onClick={props.onClose} />
                    </Box>
                }
            >
                <Box>
                    {
                        fetching && <Spinner show={true} />
                    }
                </Box>
                <Box paddingY={2} rounding={2} color='secondary' marginBottom={2} display='flex' alignItems='center'>
                    <Box paddingX={3}>
                        <Text inline align='center'>{orders.length} дуудлага өгсөн</Text>
                    </Box>
                </Box>
                <Box height={400}>
                    {
                        orders.map((order, index) => (
                            <OrderItemInline 
                                key={index}
                                order={order}
                                onTap={() => {}}
                                onCancelOrderDone={() => {}}
                            />
                        ))
                    }
                    {/* <PrevOrders 
                        isOpen={true}
                        orders={orders}
                    /> */}
                </Box>
            </Modal>
        </Layer>
    )
}

export default EditOrder