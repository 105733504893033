import React, { useCallback, useState, useEffect } from 'react'
import { TextField } from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchPhoneAutocomplete } from '../../../utils/RestApi'
import _debounce from 'lodash/debounce'

const AsyncAutoComplete = (props) => {
    const { 
        label = "Утасны дугаар",
        optionLabel = "phone"
    } = props
    
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        // verify(query)
        // _debounce
        getData()
    }, [query])

    const verify = useCallback(
        _debounce(name => {
            getData()
        }, 200), []
    )

    const getData = () => {
        setLoading(true)
        fetchPhoneAutocomplete({
            phone: query
        })
        .then(res => {
            if(res.data.code == 0) {
                setData(res.data.phones)
            }
            // setData(res.data.data)
        })
        .catch(err => alert(err))
        .then(() => setLoading(false))
    }

    return (
        <Autocomplete
            {...props}
            options={data}
            getOptionLabel={(option) => option[optionLabel]}
            freeSolo
            onInputChange={(event, value, reason) => {
                if (reason === 'input' || reason === 'clear') {
                    setQuery(value);
                }
            }}
            onFocus={() => {
                props.onFocus && props.onFocus()
            }}
            onBlur={() => {
                props.onBlur(query)
            }}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    type="number"
                    label={label}
                    variant="outlined"
                    // onBlur={() => {
                    //     props.onTextFieldBlur || props.onTextFieldBlur(query)
                    // }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
                
    )
} 

export default AsyncAutoComplete