import React, { useState, useEffect } from "react";
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    TextField,
    Step,
    Stepper,
    StepLabel
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { saveCustomer, saveCustomerCompany } from "../utils/RestApi";

// styles
import useStyles from "./login/styles";
// logo
import logo from "./login/iscaff.png";

// context
import { useUserDispatch, loginUser } from "../context/UserContext";
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next"
import { setLocale } from "faker";

function RegisterDetail(props) {
  var classes = useStyles();
  const { t } = useTranslation();
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [firstName, setFirstName] = useState("");
  var [email, setEmail] = useState("");
  var [phone, setPhone] = useState("");
  var [lastName, setLastName] = useState("");
  var [password, setPassword] = useState("");
  const [activeStep, setActiveStep] = React.useState(1);

  function getSteps() {
    return ["Register Company", "Register Customer"];
  }

  const id = props.match.params.id

  const steps = getSteps();

  // alert(`${props.location.state.nameMn} == ${props.location.state.nameEn}`)

  const onRegister = () => {
    setIsLoading(true)

    saveCustomerCompany({
        registration_id: id,
        name_mn: props.location.state.nameMn,
        name_en: props.location.state.nameEn 
      })
      .then((res) => {
  
        if(res.data.code == 0) {
  
            saveCustomer({
                phones: phone,
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                company: res.data.company._id
            })
            .then(res => {
                if(res.data.code != 0) {
                    toast.warning(res.data.errors[0].msg)
                    return
                }
                
                if(res.data.code == 0) {
                    const { user, token } = res.data

                    localStorage.setItem("token", token)
                    localStorage.setItem("user", JSON.stringify(user))

                    toast.success(t("message.success.saved"))

                    localStorage.setItem("role", 'customer')
                    return props.history.push(`/app/my-order`)
                }
            })
            .catch((err) => alert(err))
            .then(() => setIsLoading(false))
        }
  
      })
      .catch((err) => alert(err))
      .then(() => setIsLoading(false))
  }

  const onBack = () => {
    props.history.goBack()
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>i-SCAFF</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
              
        <Typography variant="h1" className={classes.greeting}>
                REGISTER!
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                DETAIL
              </Typography>

              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                margin="normal"
                placeholder="First Name"
                type="normal"
                fullWidth
              />
              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                margin="normal"
                placeholder="Last Name"
                fullWidth
              />

              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                type="email"
                placeholder="Email"
                fullWidth
              />
            <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={phone}
                type='number'
                onChange={e => setPhone(e.target.value)}
                margin="normal"
                placeholder="Phone"
                fullWidth
              />
            <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={password}
                type='password'
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                placeholder="Password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={onRegister}
                    disabled={password.length === 0 || firstName.length === 0 || lastName.length === 0 || email.length === 0 || phone.length === 0}
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    {t("auth.signup")}
                  </Button>
                )}
              </div>

              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={onBack}
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    {t("action.back")}
                  </Button>
                )}
              </div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
        </div>
        <Typography color="primary" className={classes.copyright}>
          ©2021 Simplood LLC.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(RegisterDetail);
