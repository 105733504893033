import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Paper,
    TableContainer
} from "@material-ui/core";

import { Alert } from '@material-ui/lab'

import { makeStyles } from '@material-ui/core/styles'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import { getWarehouses, getWarehouseInventory, saveInventory } from '../../utils/RestApi'
import { 
    Trash as TrashIcon, 
    Edit as EditIcon, 
    List as ListIcon,
    Lock as LockIcon,
    MapPin as MapPinIcon,

} from 'react-feather'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      //minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const WarehouseBalance = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [resources, setResources] = useState([])
    const balancedResources = resources.filter((cur) => cur.prev_stock_quantity != cur.in_stock_quantity)

    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [warehouses, setWarehouses] = useState([])
    
    const [warehouse, setWarehouse] = useState({})
    const locale = localStorage.getItem('locale')
    const history = useHistory();

    useEffect(() => {
        fetchWarehouses()
    }, [])

    useEffect(() => {
        fetchInventory()
    }, [page])

    useEffect(() => {
        fetchInventory()
    }, [warehouse])

    const fetchWarehouses = () => {
        getWarehouses()
        .then((res) => {
            if(res.data.code == 0) {
                setWarehouses(res.data.result.docs)
            }
        })
        .catch(err => alert(err))
    }

    const fetchInventory = () => {
        setResources([])
        
        if(_isEmpty(warehouse)) {
            return
        }

        setFetching(true)
        getWarehouseInventory(warehouse._id)
        .then((res) => {
            if (res.data.code == 0) {
                setResources(res.data.resources) //users
                // setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onWarehouseChanged = (e) => {
        //alert(e.target.value)
        let warehouse = warehouses.find((cur) => cur._id == e.target.value)

        if(warehouse) {
            setWarehouse(warehouse)
        } else {
            setWarehouse({})
        }
        //setWarehouse()
        //alert(JSON.stringify(warehouse))
    }

    const onBack = () => history.push('/app/inventory');

    const onSaveInventory = () => {
        let inventories = balancedResources.map((resource) => {
            return {
                resource_id: resource._id,
                prev_stock_quantity: resource.prev_stock_quantity,
                in_stock_quantity: resource.in_stock_quantity
            }
        })

        setFetching(true)
        saveInventory(warehouse._id, {
            inventories
        })
        .then((res) => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.push('/app/inventory');
            }
            //
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onSetInventory = (warehouse_id) => {
        history.push(`/app/warehouse/${warehouse_id}/inventory`)
    }

    const onNewWarehouse  = () =>  {
        history.push('/app/warehouse/create')
    }

    const onChangeQuantity = (e, index) => {
        let newResources = [...resources]
        newResources[index].in_stock_quantity = e.target.value
        setResources(newResources)
    } 

    //alert(JSON.stringify(balancedResources))

    return (    
        <div>
            <PageTitle
                title={t("inventory.balance")}
                //button={t("warehouse.configure_inventory")}
                //onButtonClick={onNewWarehouse}  
            />

            <Grid container>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-placeholder-label-label">
                        {t("inventory.select_warehouse")}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={warehouse._id}
                        onChange={onWarehouseChanged}
                        displayEmpty
                        //className={classes.selectEmpty}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            warehouses.map((warehouse, index) => (
                                <MenuItem value={warehouse._id} key={index}>
                                    {warehouse[`name_${locale}`]} <span style={{ marginLeft: 10, color: "#b5b5b5" }}>{warehouse.company[`name_${locale}`]}</span>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>

            {
                _isEmpty(warehouse) && (
                    <div style={{ marginTop: 10 }}>
                        <Grid>  
                            <Alert severity="warning">
                                {t("message.warning.select_warehouse_first")}
                            </Alert>
                        </Grid>
                    </div>
                )
            }

            <div style={{ height: 30 }} />

            {
                fetching && (
                    <div style={{
                        marginBottom: 20
                    }}>
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    </div>
                )
            }

            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('naming.resource')}</TableCell>
                            <TableCell>{t('naming.quanity')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            resources.map((resource, index) => (
                                <TableRow key={index} hover>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{resource[`name_${locale}`]}</TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            value={resource.in_stock_quantity}
                                            onChange={(e) => onChangeQuantity(e, index)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ height: 30 }}></div>
            
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <Grid justify='center' container>
                        <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                            {t("action.back")}  
                        </Button>
                        {
                            fetching && (
                                <Button variant="contained" color="primary" disabled>
                                    {t("action.save")}  
                                </Button>
                            ) || (
                                <Button variant="contained" disabled={balancedResources.length == 0} color={balancedResources.length > 0 ? "primary" : "secondary"} onClick={onSaveInventory}>
                                    {t("action.save")}  
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default WarehouseBalance