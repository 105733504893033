import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Paper,
} from "@material-ui/core";

import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Bar, Pie } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import Chart from "react-apexcharts";

import OrderCancelChart from './OrderCancelChart'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100%',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(100),
            height: theme.spacing(100),
        },
    },
}));

const DashboardChart = (props) => {
    const { orderMaterials, allMaterials, warehouseInventories, all, processes, companyUsers, orderManpowers } = props
    const classes = useStyles();
    const { t } = useTranslation()

    const pie_material = {
        data: {
            labels: [
                `${t("dashboard.main.chart_2.available")} - ${(Math.round(allMaterials / (orderMaterials + allMaterials) * 1000)) / 10}%`,
                `${t("dashboard.main.chart_2.unavailable")} - ${(Math.round(orderMaterials / (orderMaterials + allMaterials) * 1000)) / 10}%`
            ],
            datasets: [
                {
                    data: [allMaterials, orderMaterials],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        },
        option: {
            plugins: {
                legend: {
                    display: true,
                },
                title: {
                    display: true,
                    text: `${t("dashboard.main.chart_2.title")} - ${orderMaterials + allMaterials}`
                },
            },
        },
    }


    return (
        <Grid xs={12} md={6}>
            <Grid xs={12} sm={12} item>
                <Paper elevation={3} className={classes.paper}>
                    <OrderCancelChart />
                </Paper>
            </Grid>
            {/* <Grid containter xs={12} style={{ padding: 50, }}>
                <Chart
                    options={state.options}
                    series={state.series}
                    type="heatmap"
                />
            </Grid> */}
        </Grid>
    )
}
export default DashboardChart