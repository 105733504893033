import React, { useState, useEffect } from "react"
import { Spinner, Text, Box, Button, Layer, Modal, FixedZIndex } from "gestalt"
import PageTitle from "../../components/PageTitle/PageTitle"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'

import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import moment from 'moment';

import { getBanned, deleteBanned, saveBanned } from '../../utils/RestApi'
import { toast } from "react-toastify";
import { SingleDatePicker } from 'react-dates'

const VipList = (props) => {
    const [search_value, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([])
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [focus, setFocus] = useState(false)
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    const [end_at, setEndAt] = useState(moment().add(3, "months").format("YYYY-MM-DD"))

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        fetchVIP()
    }, [page, search_value])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEmployee = () => { setIsOpen(true) }

    const fetchVIP = () => {
        setFetching(true)
        getBanned({
            search_value
        })
        .then(res => {
            if(res.data.code == 0) {
                setEmployees(res.data.result.docs)
                setPages(res.data.result.pages)
            }
        })
        .then(() => setFetching(false))
    }

    const onEdit = () => {}
    const onDelete = (vip_id) => {
        if (window.confirm("Устгахдаа итгэлтэй байна уу") == true) {
            deleteBanned(vip_id)
            .then(res => {
                if(res.data.code != 0) {
                    return toast("Амжилттай устгалаа")
                }

                if(res.data.code == 0) {
                    fetchVIP()
                }
            })
        }
    }

    const onSave = () => {
        if(!note) return toast("Тэмдэглэл оруулна уу")
        if(!phone || String(phone).length != 8) return toast("Утас оруулна уу")

        setSaving(true)
        saveBanned({
            note,
            phone,
            end_at: moment(end_at).toDate()
        })
        .then(res => {
            if(res.data.code != 0) return toast(res.data.errors[0].msg)
            if(res.data.code == 0) {
                fetchVIP()
                setIsOpen(false)
                return toast("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                // title={t("registration.employee.title")}
                // rightView={() => rView()}
                //btnColor="primary"
                button="Хар жагсаалтад нэмэх"
                onButtonClick={onCreateNewEmployee}  
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                    id="outlined-basic"
                    autoComplete="new-password"
                    inputProps={{
                        autoComplete: 'off'
                     }}
                    label="Хайх"
                    variant="outlined"
                    value={search_value}
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                />
            </div>
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Утас</TableCell>
                            <TableCell>Тэмдэглэл</TableCell>
                            <TableCell>Дуусах огноо</TableCell>
                            <TableCell colSpan={3} align='center' style={{ position: "sticky", right: 0, background: "white" }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.phone}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.note}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{moment(employee.end_at).format("YYYY/MM/DD")}</TableCell>
                                        {/* <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onEdit(employee._id)}>
                                                <EditIcon color='gray' />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onDelete(employee._id)}>
                                                <TrashIcon color="red" size={16} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            {
                isOpen && (
                    <Layer>
                        <Modal size='lg' footer={
                            <Box display="flex" marginTop={6}>
                                <Button text="Хаах" fullWidth onClick={() => setIsOpen(false)} />
                                <Box width={12} />
                                {
                                    saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={() => onSave()} />
                                }
                            </Box>
                        } 
                            heading={
                                <Text size='400'>Хар жагсаалтанд бүртгэх</Text>
                            }
                            onDismiss={() => setIsOpen(false)}
                        >
                            <Box>
                                <Box paddingY={4}>
                                    <TextField 
                                        fullWidth
                                        label="Утасны дугаар"
                                        type="number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Box>
                                <Box marginBottom={12}>
                                    <TextField
                                        fullWidth
                                        label="Тэмдэглэл оруулах"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </Box>
                                <Box marginTop={12} position="relative">
                                    <Box marginBottom={1}>
                                        <Text color='subtle'>Дуусах огноо оруулах</Text>
                                    </Box>
                                    
                                    <TextField 
                                        type="date"
                                        value={end_at}
                                        defaultValue={end_at}
                                        onChange={(e) => setEndAt(e.target.value)}
                                    />
                                </Box>
                            </Box>

                            
                        </Modal>
                    </Layer>
                )
            }
        </Box>
    )
};

export default VipList;
