import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(15),
            height: theme.spacing(15)
        },
    },
}));

const ReviewFilter = (props) => {
    const classes = useStyles();
    const { process_name, processes } = props
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            {
                processes.map((process, index) => (
                    <Paper
                        key={index}
                        onClick={() => props.setProcessName(process.name)}
                        style={{
                            border: process_name == process.name ? '3px solid #e11a4d' : 'none',
                        }}
                    >
                        <Grid direction='column' container justify='center' alignItems='center' style={{
                            height: '100%'
                        }}>
                            {process.icon}
                            <div style={{ marginTop: 10 }}>
                                <Typography variant='h2'>
                                    {process.total}
                                </Typography>
                            </div>
                            <div>
                                <Typography>
                                    {t(process.title)}
                                </Typography>
                            </div>
                        </Grid>
                    </Paper>
                ))
            }
        </div>
    )
}

export default ReviewFilter