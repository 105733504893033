import React, { useState, useEffect } from "react"
import { Spinner, Text, Box, Button, Layer, Modal } from "gestalt"
import PageTitle from "../../components/PageTitle/PageTitle"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'

import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import moment from 'moment';

import { getVIP, deleteVIP, saveVIP } from '../../utils/RestApi'
import { toast } from "react-toastify";

const VipList = (props) => {
    const [search_value, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([])
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        fetchVIP()
    }, [page, search_value])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEmployee = () => { setIsOpen(true) }

    const fetchVIP = () => {
        setFetching(true)
        getVIP({
            search_value
        })
        .then(res => {
            if(res.data.code == 0) {
                setEmployees(res.data.result.docs)
                setPages(res.data.result.pages)
            }
        })
        .then(() => setFetching(false))
    }

    const onEdit = () => {}
    const onDelete = (vip_id) => {
        if (window.confirm("Устгахдаа итгэлтэй байна уу") == true) {
            deleteVIP(vip_id)
            .then(res => {
                if(res.data.code != 0) {
                    return toast("Амжилттай устгалаа")
                }

                if(res.data.code == 0) {
                    fetchVIP()
                }
            })
        }
    }

    const onSave = () => {
        if(!name) return toast("Нэр оруулна уу")
        if(!phone || String(phone).length != 8) return toast("Нэр оруулна уу")

        setSaving(true)
        saveVIP({
            name,
            phone
        })
        .then(res => {
            if(res.data.code != 0) return toast(res.data.errors[0].msg)
            if(res.data.code == 0) {
                fetchVIP()
                setIsOpen(false)
                return toast("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                // title={t("registration.employee.title")}
                // rightView={() => rView()}
                //btnColor="primary"
                button="VIP хэрэглэгч нэмэх"
                onButtonClick={onCreateNewEmployee}  
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                    id="outlined-basic"
                    autoComplete="new-password"
                    inputProps={{
                        autoComplete: 'off'
                     }}
                    label="Хайх"
                    variant="outlined"
                    value={search_value}
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                />
            </div>
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Утас</TableCell>
                            <TableCell>Нэр</TableCell>
                            <TableCell colSpan={3} align='center' style={{ position: "sticky", right: 0, background: "white" }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.phone}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.name}</TableCell>
                                        {/* <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onEdit(employee._id)}>
                                                <EditIcon color='gray' />
                                            </IconButton>
                                        </TableCell> */}
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onDelete(employee._id)}>
                                                <TrashIcon color="red" size={16} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            {
                isOpen && (
                    <Layer>
                        <Modal onDismiss={() => setIsOpen(false)}>
                            <Text size='400'>VIP хэрэглэгч бүртгэх</Text>
                            <Box paddingY={4}>
                                <Box paddingY={4}>
                                    <TextField 
                                        fullWidth
                                        label="Утасны дугаар"
                                        type="number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Box>
                                <TextField 
                                    fullWidth
                                    label="Нэр оруулах"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Box>

                            <Box display="flex" marginTop={6}>
                                <Button text="Хаах" fullWidth onClick={() => setIsOpen(false)} />
                                <Box width={12} />
                                {
                                    saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={() => onSave()} />
                                }
                                
                            </Box>
                        </Modal>
                    </Layer>
                )
            }
        </Box>
    )
};

export default VipList;
