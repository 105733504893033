import React from 'react'
import {
    Typography,
    Paper,
} from '@material-ui/core'
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent
} from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

let status = 0
const Dot = (props) => {

    const { process, order } = props
    if (order.current_process.name == process.name) status = 1
    if (status == 0)
        return (
            <div>
                <TimelineDot
                    variant="outlined"
                    style={{
                        background: "green"
                    }}
                />
            </div>
        )
    return (
        <div>
            <TimelineDot
                variant="outlined"
                color={order.current_process.name == process.name ? "primary" : "secondary"}
            />
        </div>
    )
}

const OrderDetailProcess = (props) => {
    const { t } = useTranslation()
    const { order } = props
    return (
        <div style={{ marginTop: 40 }}>
            <Timeline>
                {
                    order.processes.map((process) => (
                        <TimelineItem style={{ marginLeft: '-100%', }}>
                            <TimelineSeparator>
                                <Dot process={process} order={order} />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent >
                                <Paper elevation={3} style={{ padding: 10 }}>
                                    {/* <Typography variant="h6" component="h1">{t("order.detail.process.approve")}</Typography> */}
                                    <Typography variant="h6" component="h1">{t(`order.detail.process.${process.name}`)}</Typography>
                                    {/* <Typography variant="body2" color="textSecondary">Mike - {moment(process.created_at).format('YYYY/MM/DD')}</Typography> */}
                                    <Typography variant="body2" color="textSecondary">{moment(process.created_at).format('YYYY/MM/DD')}</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    ))
                }
            </Timeline>
        </div>
    )
}

export default OrderDetailProcess