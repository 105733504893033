import { Box, Text, TapArea, Spinner } from 'gestalt'

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import ReportIcon from '@mui/icons-material/Report';

const CustomerMood = (props) => {
    const { mood = 3 } = props

    const renderMood = () => {
        switch(mood) {
            case 1:
                return (
                    <Box>
                        <ReportIcon 
                            style={{
                                color: "red"
                            }}
                        />
                    </Box>
                )
            case 2:
                return (
                    <Box>
                        <PriorityHighIcon 
                            style={{
                                color: "red"
                            }}
                        />
                    </Box>
                )
            case 3:
                return (
                    <Box>
                        <MoodBadIcon 
                            style={{
                                color: "orange"
                            }}
                        />
                    </Box>
                )
            case 4:
                return (
                    <Box>
                        <SentimentVeryDissatisfiedIcon 
                            style={{
                                color: "black"
                            }}
                        />
                    </Box>
                )
            case 5:
                return (
                    <Box>
                        <InsertEmoticonIcon 
                            style={{
                                color: "black"
                            }}
                        />
                    </Box>
                )
        }
    }
    
    return (
        <Box>
            { renderMood() }
        </Box>
    )
}

export default CustomerMood