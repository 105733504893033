import React, { useEffect, useState } from 'react'
import { Box, TapArea, Text } from 'gestalt'
import { fetchAbandonedCalls } from '../../utils/RestApi'
import { PhoneDisabled } from '@material-ui/icons'
import { useCalls } from '../../hooks/useCalls'

const InComingCalls = (props) => {
    const { missed_calls } = useCalls()

    if(missed_calls.length == 0) return null

    return (
        <>
            <Box color='light' maxWidth={400}>
                <Box display='flex' color="light" paddingX={6} alignItems='center'>
                    <Box>
                        <PhoneDisabled />
                    </Box>
                    <Box flex='grow' paddingX={2} display='flex' wrap>
                        {
                            missed_calls.map((call, index) => (
                                <TapArea fullWidth={false} key={index} onTap={() => props.onCallPressed(call.phone)}>
                                    <Box paddingX={3}>
                                        <span style={{
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            color: "red",
                                        }}>
                                            {call.phone}
                                        </span>
                                    </Box>
                                </TapArea>
                            ))
                        }
                    </Box>
                </Box>
                <Box height={12} />
            </Box>
        </>
    )
}

export default InComingCalls