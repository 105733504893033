import React from 'react'

import {
    Grid,
    Paper,
    Typography,
    Avatar,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ImageModal from '../../components/ImageModal'

const OrderDetailProject = (props) => {
    const { order } = props
    const { t } = useTranslation()

    return (
        <div>
            <Paper style={{ marginTop: '40px', padding: 20 }}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>{t("order.detail.process.approval_paper")}</Typography>
                    </Grid>
                    <Grid item>
                        {
                            order.approvel_paper_logs.length != 0 ? (
                                <div>
                                    {
                                        order.approvel_paper_logs.map((approvel) => (
                                            <Grid item xs={12}>
                                                <Typography>{approvel.name} - {moment(approvel.created_at).format('YYYY/MM/DD')}</Typography>
                                                <Grid container direction="row">
                                                    <ImageModal
                                                        pictures={approvel.pictures}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </div>
                            ) : (
                                <Typography>{t("order.detail.process.approval_paper_empty")}</Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={{ marginTop: '40px', padding: 20 }}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>{t("order.detail.process.monitoring_note")}</Typography>
                    </Grid>
                    <Grid item>
                        {
                            order.audit_logs.length != 0 ? (
                                <div>
                                    {
                                        order.audit_logs.map((audit) => (
                                            <Grid item xs={12}>
                                                <Typography>{audit.user.first_name} - {moment(audit.created_at).format('YYYY/MM/DD')}</Typography>
                                                <Typography>{audit.note}</Typography>
                                                <Grid container direction="row">
                                                    <ImageModal
                                                        pictures={audit.pictures}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))

                                    }
                                </div>
                            ) : (
                                <Typography>{t("order.detail.process.monitoring_note_empty")}</Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Paper style={{ marginTop: '40px', padding: 20 }}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>{t("order.detail.process.user_monitoring_note")}</Typography>
                    </Grid>
                    <Grid item>
                        {
                            order.external_audit_logs.length != 0 ? (
                                <div>
                                    {
                                        order.external_audit_logs.map((audit) => (
                                            <Grid item xs={12}>
                                                <Typography>{audit.customer.first_name} - {moment(audit.created_at).format('YYYY/MM/DD')}</Typography>
                                                <Typography>{audit.note}</Typography>
                                                <Grid container direction="row">
                                                    <ImageModal
                                                        pictures={audit.pictures}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </div>
                            ) : (
                                <Typography>{t("order.detail.process.user_monitoring_note_empty")}</Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default OrderDetailProject