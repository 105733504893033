import React, {useState, useEffect} from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { 
    Grid, 
    Paper, 
    Button, 
    TextField, 
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell, 
    CircularProgress, 
    FormControl, 
    InputLabel, 
    Select,
    Checkbox
} from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination'
import { toast } from 'react-toastify'
import { getConfigs } from './ConfigApi'
import { useHistory } from 'react-router-dom'
import { getEmployees } from './EmployeeApi'
import { saveCertificate, updateEmployeeCertificate } from '../../utils/RestApi'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        }
    }
}));

const CreateCertificate = (props) =>  {
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([])
    const classes = useStyles();
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false);
    const history = useHistory()
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [certificate, setCertificate] = useState('')
    const [expired_at, setExpired_at] = useState("")
    const [roles, setRoles] = useState([]);
    const [rosters, setRosters] = useState([]);
    const [searchRoster, setSearchRoster] = useState("");
    const [searchRole, setSearchRole] = useState("");
    const [chips, setChips] = useState([])

    const handleChange = (employee, index) => {
        setEmployees(employees.filter(item => item._id !== employee._id))
        // alert(`${employee} and ${index}`)
        setChips([...chips, employee])
    };

    useEffect(() => {
        fetchDatas()
    }, [])

    const fetchDatas = () => {
        getConfigs({})
        .then((res) => {
            if(res.data.code == 0) {
                res.data.config.map((config, index) => {
                    if(config.field === 'Role')
                        setRoles(config.value)

                    if(config.field === 'Roster')
                        setRosters(config.value)
                })
            }
        })
        .catch((err) => console.log(err))
    } 

    useEffect(() => {
        fetchCertificates()
    }, [])

    const fetchCertificates = () => {
        getConfigs({})
        .then((res) => {
            if(res.data.code == 0) {
                res.data.config.map((config, index) => {
                    if(config.field === "Certificate") {
                        setCertificates(config.value)
                    }
                })
            }
        })
        .catch((err) => alert(err))
    }

    useEffect(() => {
        fetchEmployees()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [search_value])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [searchRoster])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [searchRole])

    const fetchEmployees = () => {
        setFetching(true)
        getEmployees({
            page,
            search_value,
            searchRoster,
            searchRole
        })
        .then((res) => {
            if (res.data.code == 0) {
                let newEmployees = res.data.result.docs;
                newEmployees = newEmployees.filter((employee) => {
                    let exist = chips.find((chip) => chip._id == employee._id)
                    return exist ? false : true;
                })
                setEmployees(newEmployees) //users
                setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onSaveCertificate = () => {
        setFetching(true)

        if(!certificate){
            toast.warning(`${t('registration.employee.create_certificate.certificate')} is empty`)
            return setFetching(false)
        }

        if(!chips){
            toast.warning(`${t('registration.employee.create_certificate.employees')} is empty`)
            return setFetching(false)
        }

        if(certificate !== "SG-4 Procedure Training" && !expired_at){
            toast.warning(`${t('registration.employee.create_certificate.expired_at')} is empty`)
            return setFetching(false)
        }

        chips.map((chip, i) => {
            saveCertificate({
                title: certificate,
                expired_at,
                user: chip._id,
                infinity: certificate !== "SG-4 Procedure Training" ? false : true,
            })
            .then(res => {
                if(res.data.code != 0) {
                    toast.warning(res.data.errors[0].msg)
                }
    
                if(res.data.code == 0) {
                    updateEmployeeCertificate(chip._id, {
                        certificate_id: res.data.certificate._id
                    })
                    .then((res) => {
                        if(res.data.code == 0) {
                            toast.success(t("message.success.saved"))
                            history.goBack()
                        } else {
                            alert(res.data.errors[0].msg)
                        }
                    })
                    .catch((err) => alert(err))        
                }
            })
            .catch((err) => alert(err))
        })

        setFetching(false)
      }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onBack  = () =>  props.history.goBack()

    const handleDelete = (chip, i) => {
        setChips(chips.filter(item => item._id !== chip._id))
        setEmployees([...employees, chip])
    };

    return(
        <Paper style={{padding: 30}}>
            <PageTitle 
                title={t("registration.employee.create_certificate.title")}
                //btnColor="primary"
                    //button={t("order.new_order")}
                //onButtonClick={onCreateNewOrder}  
            />
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="#">{t("registration.employee.create_certificate.certificate")}</InputLabel>
                            <Select
                                native
                                value={certificate}
                                onChange={(e) => setCertificate(e.target.value)}
                                label={t("registration.employee.create_certificate.certificate")}
                                inputProps={{
                                    name: 'certificate',
                                }}
                            >
                        <option aria-label="None" value="" />
                        {
                            certificates.map((certificate, index) => (
                                <option value={certificate.title}>{certificate.title}</option>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {
                certificate !== "SG-4 Procedure Training" ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} alignItems='center'>
                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                <TextField 
                                    id='outlined-basic' 
                                    value={expired_at} 
                                    onChange={(e) => setExpired_at(e.target.value)} 
                                    label={t('registration.employee.create_certificate.expired_at')} 
                                    type="date" 
                                    variant="outlined" 
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )
            }
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <Paper variant="outlined" className={classes.chip}>
                            {
                                chips.map((chip, i) => (
                                    <Chip label={`${chip.last_name}, ${chip.first_name}`} onClick={() => handleDelete(chip, i)} color="primary" />
                                ))
                            }
                        </Paper>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={8}  alignItems='center'>
                    <TextField
                    id="outlined-basic"
                    label={t("registration.employee.search")}
                    variant="outlined"
                    value={search_value}
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                />
                </Grid>
                <Grid item xs={2}  alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="#">{t("user.role")}</InputLabel>
                            <Select
                                native
                                value={searchRole}
                                onChange={(e) => setSearchRole(e.target.value)}
                                label={t("user.role")}
                                inputProps={{
                                    name: 'role',
                                }}
                            >
                        <option aria-label="None" value="" />
                        {
                            roles.map((role, index) => (
                                <option value={role.title}>{role.title}</option>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}  alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="#">{t("user.roster")}</InputLabel>
                            <Select
                                native
                                value={searchRoster}
                                onChange={(e) => setSearchRoster(e.target.value)}
                                label={t("user.roster")}
                                inputProps={{
                                    name: 'roster',
                                }}
                            >
                        <option aria-label="None" value="" />
                        {
                            rosters.map((roster, index) => (
                                <option value={roster.title}>{roster.title}</option>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div style={{marginTop: 10}} />
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('user.sap').toUpperCase()}</TableCell>
                            <TableCell>{t('user.last_name')}</TableCell>
                            <TableCell>{t('user.first_name')}</TableCell>
                            <TableCell>{t('user.role')}</TableCell>
                            <TableCell>{t('user.roster')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover>
                                        <TableCell>
                                        <Checkbox
                                            checked={false}
                                            onChange={() => handleChange(employee, index + ((page - 1) * 10))}
                                        />
                                        </TableCell>
                                        <TableCell>{index + 1 + ((page - 1) * 10)}</TableCell>
                                        <TableCell>{employee.sap}</TableCell>
                                        <TableCell>{employee.last_name}</TableCell>
                                        <TableCell>{employee.first_name}</TableCell>
                                        <TableCell>{employee.roles[0].title}</TableCell>
                                        <TableCell>{employee.roster}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <Grid justify='center' container>
                        <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                            {t("action.back")}  
                        </Button>
                        {
                            fetching ? (
                                <CircularProgress />
                            ) : (
                                <Button variant="contained" color="primary" onClick={onSaveCertificate}>
                                    {t("action.save")}  
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CreateCertificate