import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { updateLocation, takeLocation } from '../../utils/RestApi'
import { searchAreas } from './AreaApi'
import { Grid, Paper, Button, TextField, IconButton, CircularProgress, FormControl, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateLocation = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const [areas, setAreas] = useState([])
    const [area, setArea] = useState()
    const locale = localStorage.getItem('locale')
    const [locationEn, setLocationEn] = useState('')
    const [locationMn, setLocationMn] = useState('')

    const [fetching, setFetching] = useState(false);

    const findId = props.match.params.location_id;

    useEffect(() => {
        fetchAreas()
    }, [])

    useEffect(() => {
        fetchLocations()
    }, [])

    const fetchAreas = () => {
        searchAreas()
        .then((res) => {
            if (res.data.code == 0) {
                setAreas(res.data.areas)
            }
        })
        .catch((err) => console.log(err))
    }

    const fetchLocations = () => {
        takeLocation(findId).then((res) => {
            if(res.data.code == 0) {
                setArea(res.data.location.area._id)
                setLocationEn(res.data.location.location_en)
                setLocationMn(res.data.location.location_mn)
            }
        }).catch((err) => console.log(err))
    }

    const onUpdateLocation = () => {
        setFetching(true)
        
        if(!locationEn) {
            toast.warning(`${t('registration.location.create_new.location_en')} is empty`)
            return setFetching(false)
        } 
        
        if(!locationMn) {
            toast.warning(`${t('registration.location.create_new.location_mn')} is empty`)
            return setFetching(false)
        } 
        
        if(!area) {
            toast.warning(`${t('registration.location.create_new.area')} is empty`)
            return setFetching(false)
        }

        updateLocation(findId, {
            location_en: locationEn,
            location_mn: locationMn,
            area
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                props.history.goBack()
            }
        })
        .catch((err) => {
            alert(err)
        }).then(() => setFetching(false))
      }

    const onBack  = () => props.history.goBack()

    return(
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.location.update")}
                    //btnColor="primary"
                       //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.location.create_new.area")}</InputLabel>
                                <Select
                                    native
                                    value={area}
                                    onChange={(e) => setArea(e.target.value)}
                                    label={t("registration.location.create_new.area")}
                                    inputProps={{
                                        name: 'area',
                                    }}
                                >
                                <option aria-label="None" value="" />
                            {
                                areas.map((are, index) => (
                                    <option keys={index} value={are._id}>{are[`area_${locale}`]} - {are.company ? are.company[`name_${locale}`] : ""}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={locationMn} onChange={(e) => setLocationMn(e.target.value)} label={t('registration.location.create_new.location_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={locationEn} onChange={(e) => setLocationEn(e.target.value)} label={t('registration.location.create_new.location_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onUpdateLocation}>
                                        {t("action.save")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CreateLocation