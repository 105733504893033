import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Button,
    TextField,
    Paper,
    TableContainer
} from "@material-ui/core";
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import { getInventoryLogsDetail, saveInventory } from '../../utils/RestApi'
import { 
    Trash as TrashIcon, 
    Edit as EditIcon, 
    List as ListIcon,
    Lock as LockIcon,
    MapPin as MapPinIcon,

} from 'react-feather'
import { toast } from 'react-toastify'

const InventoryLogs = (props) => {
    const inventory_id = props.match.params.inventory_id
    const { t } = useTranslation();
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [log_type, setLogType] = useState('')
    const [logs, setLogs] = useState([])

    const locale = localStorage.getItem('locale')
    const history = useHistory();

    useEffect(() => {
        fetchInventoryLog()
    }, [page])

    const fetchInventoryLog = () => {
        setFetching(true)
        getInventoryLogsDetail(inventory_id)
        .then((res) => {
            if (res.data.code == 0) {
                if(res.data.type == 'balance') {
                    setLogs(res.data.balances)
                } else {
                    setLogs(res.data.movements)
                }

                setLogType(res.data.type)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onBack = () => history.push('/app/inventory');

    return (    
        <div>
            <PageTitle
                title={t("inventory.log_detail")}
                hasBack
                //button={t("warehouse.configure_inventory")}
                //onButtonClick={onNewWarehouse}  
            />
            {
                log_type == 'balance' && (
                    <TableContainer component={Paper}>
                        <Table className="mb-0">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('№')}</TableCell>
                                    <TableCell>{t('naming.warehouse')}</TableCell>
                                    <TableCell>{t('naming.resource')}</TableCell>
                                    <TableCell>{t('naming.quanity')}</TableCell>
                                    <TableCell>{t("naming.action_by")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    logs.map((log, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{log.warehouse[`name_${locale}`]}</TableCell>
                                            <TableCell>{log.resource[`name_${locale}`]}</TableCell>
                                            <TableCell>
                                                {log.in_stock_quantity}
                                            </TableCell>
                                            <TableCell>
                                                {log.employee_by.first_name} @{log.employee_by.email}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

            {
                log_type == 'movement' && (
                    <TableContainer component={Paper}>
                        <Table className="mb-0">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('№')}</TableCell>
                                    <TableCell>{t('inventory.from_location')}</TableCell>
                                    <TableCell>{t('inventory.to_location')}</TableCell>
                                    <TableCell>{t('naming.resource')}</TableCell>
                                    <TableCell>{t('naming.quanity')}</TableCell>
                                    <TableCell>{t('naming.action_by')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    logs.map((log, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{log.from_location[`name_${locale}`]}</TableCell>
                                            <TableCell>
                                                {log.to_location[`name_${locale}`]}
                                            </TableCell>
                                            <TableCell>
                                                {log.resource[`name_${locale}`]}
                                            </TableCell>
                                            <TableCell>
                                                {log.quantity}
                                            </TableCell>
                                            <TableCell>
                                                {log.employee_by.first_name} @{log.employee_by.email}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </div>
    )
}

export default InventoryLogs