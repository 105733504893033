import React, { useState, useEffect } from "react"
import { Spinner, Text, Box, RadioGroup, Button, Layer, Modal, FixedZIndex, OverlayPanel, RadioButton } from "gestalt"
import PageTitle from "../../components/PageTitle/PageTitle"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'

import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import moment from 'moment';

import { getAssignedDriversWithQuery, setKmData } from '../employee/EmployeeApi'
import { toast } from "react-toastify";
import { currencyFormat } from "../../utils/helpers";

const VipList = (props) => {
    const [search_value, setSearchValue] = useState('');
    const [drivers, setDrivers] = useState([])
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [focus, setFocus] = useState(false)
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    const [end_at, setEndAt] = useState(moment().add(3, "months").format("YYYY-MM-DD"))
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))

    const [isOpen, setIsOpen] = useState(false)
    const [selected_driver, setSelectedDriver] = useState(null)

    useEffect(() => {
        fetchData()
    }, [date])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEmployee = () => { setIsOpen(true) }

    const fetchData = () => {
        setFetching(true)
        getAssignedDriversWithQuery(date, {
            role: "Түгээгч",
        })
        .then(res => {
            if(res.data.code == 0) {
                setDrivers(res.data.drivers)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onSave = () => {
        setSaving(true)
        setKmData(moment(selected_driver.date).format("YYYY-MM-DD"), {
            driver_id: selected_driver.driver._id,
            start_km: selected_driver.start_km,
            end_km: selected_driver.end_km,
            norm_km: selected_driver.norm_km,
            norm_price: selected_driver.norm_price,
            diff_price: selected_driver.end_at == 0 ? 0 : ((selected_driver.end_km - selected_driver.start_km) - selected_driver.norm_km) <= 0 ? 0 : Math.ceil(((selected_driver.norm_price) / selected_driver.norm_km) * (selected_driver.end_km - selected_driver.start_km) - selected_driver.norm_km) - selected_driver.norm_price,
            ebarimt: selected_driver.ebarimt
        })
        .then(res => {
            if(res.data.code == 0) {
                toast("Амжилттай хадгаллаа")
                setIsOpen(false)
                fetchData()
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const onEdit = (current) => {
        setSelectedDriver(current)
        setIsOpen(true)
    }

    const onAttrChanged = (field, value) => {
        let newSelectedDriver = {...selected_driver}
        newSelectedDriver[field] = value
        setSelectedDriver(newSelectedDriver)
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                // title={t("registration.employee.title")}
                // rightView={() => rView()}
                //btnColor="primary"
                // button="Хар жагсаалтад нэмэх"
                // onButtonClick={onCreateNewEmployee}  
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                    id="outlined-basic"
                    type="date"
                    variant="outlined"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </div>
            {   
                fetching && (
                    <Grid
                        container
                        justify='center'
                    >
                        <CircularProgress />
                    </Grid>
                )
            }
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Дугаар</TableCell>
                            <TableCell>Нэр</TableCell>
                            <TableCell>Утасны дугаар</TableCell>
                            <TableCell>Эхэлсэн нийт км</TableCell>
                            <TableCell>Дууссан нийт км</TableCell>
                            <TableCell>Норм км</TableCell>
                            <TableCell>Норм зөрүү км</TableCell>
                            <TableCell>Бензин нэмэлт</TableCell>
                            <TableCell>ИБаримт авсан эсэх</TableCell>
                            <TableCell align='center' style={{ position: "sticky", right: 0, background: "white" }}>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            drivers.map((current, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.driver.sap}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.driver.first_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.driver.phones}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.start_km} км</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.end_km} км</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.norm_km} км</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{current.end_km > 0 ? (current.end_km - current.start_km) - current.norm_km : 0} км</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{currencyFormat(current.diff_price)}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>
                                            <Text>
                                            {
                                                current.ebarimt == null ? (
                                                    "-"
                                                ) : current.ebarimt ? "Авсан" : "Аваагүй"
                                            }
                                            </Text>
                                        </TableCell>

                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 30 }}>
                                            <IconButton onClick={() => onEdit(current)}>
                                                <EditIcon color='gray' />
                                            </IconButton>
                                        </TableCell>
                                        
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                
                </TableContainer>
                
            {
                isOpen && (
                    <Layer zIndex={new FixedZIndex(9999)}>
                        <OverlayPanel size='lg' footer={
                                <Box display="flex" marginTop={6}>
                                    <Button text="Хаах" fullWidth onClick={() => setIsOpen(false)} />
                                    <Box width={12} />
                                    {
                                        saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={() => onSave()} />
                                    }
                                </Box>
                            } 
                            onDismiss={() => setIsOpen(false)}
                        >
                            <Box paddingY={4}>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Эхэлсэн нийт км: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        // label="Утасны дугаар"
                                        type="number"
                                        value={selected_driver.start_km}
                                        onChange={(e) => onAttrChanged("start_km", e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Дууссан нийт км: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        type="number"
                                        value={selected_driver.end_km}
                                        onChange={(e) => onAttrChanged("end_km", e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Нийт явсан км: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        // label="Утасны дугаар"
                                        type="number"
                                        disabled
                                        value={selected_driver.end_km - selected_driver.start_km}
                                        
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Норм км: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        // label="Утасны дугаар"
                                        type="number"
                                        value={selected_driver.norm_km}
                                        onChange={(e) => onAttrChanged("norm_km", e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Норм төгрөг: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        // label="Утасны дугаар"
                                        type="number"
                                        value={selected_driver.norm_price}
                                        // onChange={(e) => setPhone(e.target.value)}
                                        onChange={(e) => onAttrChanged("norm_price", e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Норм зөрүү км: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                        // label="Утасны дугаар"
                                        type="number"
                                        value={selected_driver.end_km > 0 ? (selected_driver.end_km - selected_driver.start_km) - selected_driver.norm_km : 0}
                                        // onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Бензин нэмэлт: </Text>
                                    </Box>
                                    <TextField 
                                        fullWidth
                                        variant="outlined"
                                        disabled
                                        // label="Утасны дугаар"
                                        type="number"
                                        value={((selected_driver.end_km - selected_driver.start_km) - selected_driver.norm_km) <= 0 ? 0 : Math.ceil(((selected_driver.norm_price) / selected_driver.norm_km) * (selected_driver.end_km - selected_driver.start_km) - selected_driver.norm_km) - selected_driver.norm_price}
                                        // onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Box>
                                <Box paddingY={1} display="flex" alignItems="center">
                                    <Box column={6}>
                                        <Text>Баримт авсан эсэх: </Text>
                                    </Box>
                                    <RadioGroup
                                        direction="row"
                                        id="bestPracticeDo"
                                        // legend="If you had to pick one, which hobby would you pick?"
                                    >
                                        <RadioGroup.RadioButton
                                            checked={selected_driver.ebarimt}
                                            id="yes"
                                            label="Тийм"
                                            name="yes"
                                            onChange={() => onAttrChanged("ebarimt", true)}
                                            value={true}
                                        />
                                        <RadioGroup.RadioButton
                                            checked={!selected_driver.ebarimt}
                                            id="no"
                                            label="Үгүй"
                                            name="no"
                                            onChange={() => onAttrChanged("ebarimt", false)}
                                            value={false}
                                        />
                                    </RadioGroup>
                                </Box>
                            </Box>

                            
                        </OverlayPanel>
                    </Layer>
                )
            }
        </Box>
    )
};

export default VipList;
