import axios from 'axios'

import { API_URL, LOCAL_URL } from './env'

const TIMEOUT = 200000

export function reportRequest(token = null) {
    //responseType: 'blob'
    let query = {
        baseURL: API_URL,
        timeout: TIMEOUT,
      }
  
      if(token) {
        Object.assign(query, {
              headers: {
                  Authorization: 'bearer ' + token
              },
          })
      }
  
      return axios.create(query)
}

export function request(token = null) {
    let query = {
      baseURL: API_URL,
      timeout: TIMEOUT,
    }

    if(token) {
        Object.assign(query, {
            headers: {
                Authorization: 'bearer ' + token
            },
        })
    }

    return axios.create(query)
}

export function localRequest(token = null) {
    let query = {
      baseURL: LOCAL_URL,
      timeout: TIMEOUT,
    }

    if(token) {
        Object.assign(query, {
            headers: {
                Authorization: 'bearer ' + token
            },
        })
    }

    return axios.create(query)
}