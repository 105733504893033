import React, { useState } from "react"
import { TextField, Layer, Box, Spinner, Button, OverlayPanel, Text, FixedZIndex, TextArea, RadioGroup } from "gestalt"
import moment from "moment";

import { createCrashLog } from '../../utils/RestApi'
import { toast } from "react-toastify";

const OrderCrashLog = (props) => {
    const [saving, setSaving] = useState(false)
    const { order } = props

    const [crash_id, setCrashId] = useState("")
    const [note, setNote] = useState("")
    const [status, setStatus] = useState("waiting") 

    const onSave = () => {
        setSaving(true)
        createCrashLog(order._id, {
            crash_id,
            note,
            status
        })
        .then((res) => {
            if(res.data.code == 0) {
                toast("Амжилттай бүртгэлээ")
                props.onSave(res.data.crash)
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const calculateEndDate = (order) => {
        let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

        if(end.status == 'pending') return "-"

        return moment(end.start_at).format("HH:mm")
    }

    return (
        <Layer zIndex={new FixedZIndex(9999)}>
            <OverlayPanel 
                onDismiss={props.onClose}
                footer={
                    <Box display="flex" marginTop={6}>
                        <Button text="Хаах" fullWidth onClick={props.onClose} />
                        <Box width={12} />
                        {
                            saving ? <Spinner show={true} /> : <Button text="Хадгалах" fullWidth color="red" onClick={() => onSave()} />
                        }
                    </Box>
                } 
            >
                <Box>
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Утас</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{order.phone}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Машины дугаар</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{order.car.mark} {order.car.plate}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Хаанаас</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{order.from_location.name}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Хаашаа</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{order.to_location.name}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Жолооч</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{order.driver.sap} - {order.driver.first_name}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Эхэлсэн цаг</Text>
                        </Box>
                        <Box column={6}>
                            <Text weight="bold">{moment(order.created_at).format("YYYY.MM.DD HH:mm")}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box display="flex">
                        <Box column={6}>
                            <Text>Дууссан цаг</Text>
                        </Box>
                        <Box column={6}>
                        <Text weight="bold">{moment(order.created_at).format("YYYY.MM.DD")} {calculateEndDate(order)}</Text>
                        </Box>
                    </Box>
                    <Box height={4} />
                    <Box marginTop={10}>
                        <Text>№</Text>
                        <TextField 
                            value={crash_id}
                            onChange={({ value }) => setCrashId(value)}
                        />
                    </Box>
                    <Box marginTop={2}>
                        <TextArea
                            label="Ослын мэдээлэл"
                            value={note}
                            onChange={({ value }) => setNote(value)}
                        />
                    </Box>
                    <Box height={4} />
                    <Box marginTop={4}>
                        <Text>Төлөв</Text>
                    </Box>

                    <RadioGroup
                        direction="row"
                        id="bestPracticeDo"
                        // legend="If you had to pick one, which hobby would you pick?"
                    >
                        <RadioGroup.RadioButton
                            checked={status == "waiting"}
                            id="waiting"
                            label="Хүлээлтэнд"
                            name="waiting"
                            onChange={() => setStatus("waiting")}
                            value="waiting"
                        />
                        <RadioGroup.RadioButton
                            checked={status == "deal"}
                            id="deal"
                            label="Тохиролцсон"
                            name="deal"
                            onChange={() => setStatus("deal")}
                            value="deal"
                        />
                        <RadioGroup.RadioButton
                            checked={status == "insurance"}
                            id="insurance"
                            label="Даатгалаар шийдсэн"
                            name="insurance"
                            onChange={() => setStatus("insurance")}
                            value="insurance"
                        />
                    </RadioGroup>                    
                </Box>
            </OverlayPanel>
        </Layer>
    )
};

export default OrderCrashLog;
