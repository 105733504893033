import React, { useState, useEffect, useCallback } from 'react'
import { Box, TextField, ButtonGroup, Button as GButton, Column, Text, TapArea } from 'gestalt'
import PageTitle from "../../components/PageTitle/PageTitle"
import { getEmployees, getAssignedDrivers, setDriverState } from '../employee/EmployeeApi'
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment'
import { toast } from 'react-toastify'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    // TextField,
    CircularProgress,
    Button,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";

const EmployeeItem = (props) => {
    const { employee, date, drivers = [], index } = props

    const selected = drivers.find((current) => String(current.driver) == String(employee._id))
    const state = selected ? selected.state : ''

    const onNotResponded = () => {
        onSetDriverState('not_responded')
    }

    const onAssigned = () => {
        onSetDriverState('assigned')
    }

    const onNotAvailable = () => {
        onSetDriverState('not_available')
    }

    const onSetDriverState = (state) => {
        setDriverState(date, {
            driver_id: employee._id,
            state,
            prev_state: selected ? selected.state : ''
        })
        .then(res => {
            if(res.data.code == 1) {
                console.log(res.data.errors[0].msg)
                // alert(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success('Амжилттай төлөв солигдлоо')
                props.onStateChanged(employee._id, state)
            } 
        })
        .catch(err => alert(err))
    }

    return (
        <TableRow hover style={{
            background: index % 2 == 0 ? "#f6f6f6" : "#fff"
        }}>
            <TableCell>{employee.sap}</TableCell>
            <TableCell>{employee.last_name}</TableCell>
            <TableCell>{employee.first_name}</TableCell>
            <TableCell>{employee.phones}</TableCell>
            <TableCell>{employee.roles.length > 0 && employee.roles[0].title}</TableCell>
            <TableCell>75%</TableCell>
            <TableCell align='center'>
                <Box paddingY={2}>
                    <ButtonGroup>
                        <GButton size='sm' color={state == 'assigned' ? "blue" : "gray"} text="Гарна" onClick={onAssigned} />
                        <GButton size='sm' color={state == 'not_available' ? "red" : "gray"} text="Боломжгүй" onClick={onNotAvailable}/>
                        <GButton size='sm' color={state == 'not_responded' ? "red" : "gray"} text="Хариулаагүй" onClick={onNotResponded} />
                    </ButtonGroup>
                </Box>
            </TableCell>
        </TableRow>
    )
}

const CalendarDateView = (props) => {
    const [search_value, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [calendar, setCalendar] = useState({})
    const [drivers, setDrivers] = useState([])
    const [selected_filter, setSelectedFilter] = useState('assigned')

    const date = props.match.params.date

    useEffect(() => {
        fetchEmployees()
    }, [page])

    // useEffect(() => {
    //     if(page == 1) {
    //         fetchEmployees()
    //     } else {
    //         setPage(1)
    //     }
    // }, [selected_filter])

    useEffect(() => {
        fetchAssignedDrivers()
    }, [])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [search_value])

    const getEmployeesRefresh = (state) => {
        setSelectedFilter(state)
        if(page == 1) return fetchEmployees(state)
        setPage(1)
    }

    const onStateChanged = (driver, state) => {
        let newDrivers = [...drivers]

        let index = newDrivers.findIndex((current) => String(current.driver) == String(driver))

        if(index != -1) {
            newDrivers[index].state = state
            setDrivers(newDrivers)
        } else {
            fetchAssignedDrivers()
        }
    }

    const fetchAssignedDrivers = () => {
        // alert(date)
        getAssignedDrivers(date, {
            date
        })
        .then(res => {
            if(res.data.code == 0) {
                setCalendar(res.data.calendar)
                setDrivers(res.data.drivers)

                // console.log(res.data)
            }
        })
        .catch(err => alert(err))
    }

    const fetchEmployees = (filter) => {
        setEmployees([])
        setFetching(true)
        getEmployees(date, {
            page,
            limit: 30,
            search_value,
            selected_filter: filter ? filter : selected_filter,
        })
        .then((res) => {
            if (res.data.code == 0) {
                setEmployees(res.data.result.docs) //users
                setPages(res.data.result.pages)
                setTotal(res.data.result.total)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                title={`Ээлжийн бүрдүүлэлт ${moment(date).format('MM/DD')}`}
                // rightView={() => rView()}
                //btnColor="primary"
                // button={t("registration.employee.new_employee")}
                // onButtonClick={onCreateNewEmployee}  
            />
            <Box>
            <Box display='flex' marginBottom={4}>
                <TapArea tapStyle="compress" onTap={() => {
                    // setSelectedFilter('assigned')
                    getEmployeesRefresh('assigned')
                }}>
                    <Box paddingX={1}>
                        <Box paddingX={4} paddingY={2} color={selected_filter.name == 'assigned' ? "brand" : "dark"}>
                            <Text align='center' color="light">Ээлжинд гарах</Text>
                            <Text size={500} weight="bold" align='center' color="light">{drivers.filter((driver) => driver.state == 'assigned').length}</Text>
                        </Box>
                    </Box>
                </TapArea>

                <TapArea tapStyle="compress" onTap={() => {
                    // setSelectedFilter('available')
                    getEmployeesRefresh('available')
                }}>
                    <Box paddingX={1}>
                        <Box paddingX={4} paddingY={2} color={selected_filter.name == 'available' ? "brand" : "dark"}>
                            <Text align='center' color="light">Хүсэлт илгээсэн</Text>
                            <Text size={500} weight="bold" align='center' color="light">{drivers.filter((driver) => driver.state == 'available').length}</Text>
                        </Box>
                    </Box>
                </TapArea>

                <TapArea tapStyle="compress" onTap={() => {
                    // setSelectedFilter('not_contacted')
                    getEmployeesRefresh('not_contacted')
                }}>
                    <Box paddingX={1}>
                        <Box paddingX={4} paddingY={2} color={selected_filter.name == 'assigned' ? "brand" : "dark"}>
                            <Text align='center' color="light">Холбогдоогүй</Text>
                            <Text size={500} weight="bold" align='center' color="light">-</Text>
                        </Box>
                    </Box>
                </TapArea>

                <TapArea tapStyle="compress" onTap={() => {
                    // setSelectedFilter('not_available')
                    getEmployeesRefresh('not_available')
                }}>
                    <Box paddingX={1}>
                        <Box paddingX={4} paddingY={2} color={selected_filter.name == 'assigned' ? "brand" : "dark"}>
                            <Text align='center' color="light">Боломжгүй</Text>
                            <Text size={500} weight="bold" align='center' color="light">{drivers.filter((driver) => driver.state == 'not_available').length}</Text>
                        </Box>
                    </Box>
                </TapArea>

                <TapArea tapStyle="compress" onTap={() => {
                    // setSelectedFilter('not_responded')
                    getEmployeesRefresh('not_responded')
                }}>
                    <Box paddingX={1}>
                        <Box paddingX={4} paddingY={2} color={selected_filter.name == 'assigned' ? "brand" : "dark"}>
                            <Text align='center' color="light">Хариулаагүй</Text>
                            <Text size={500} weight="bold" align='center' color="light">{drivers.filter((driver) => driver.state == 'not_responded').length}</Text>
                        </Box>
                    </Box>
                </TapArea>
            </Box>
            <div style={{ marginBottom: 20 }}>
                <TextField
                    autoComplete="off"
                    placeholder="Хайх"
                    value={search_value}
                    onChange={({ value }) => setSearchValue(value)}
                />
            </div>
            <Box display='flex'>
                <Column span={12}>
                    <TableContainer component={Paper}>
                        <Table className="mb-0">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дугаар</TableCell>
                                    <TableCell>Овог</TableCell>
                                    <TableCell>Нэр</TableCell>
                                    <TableCell>Утас</TableCell>
                                    <TableCell>Албан тушаал</TableCell>
                                    <TableCell>Ирц</TableCell>
                                    <TableCell align='center'>Үйлдэл</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employees.map((employee, index) => { 
                                        return (
                                            <EmployeeItem 
                                                key={index}
                                                index={index}
                                                employee={employee}
                                                date={date}
                                                drivers={drivers}
                                                onStateChanged={onStateChanged}
                                            />
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        {   
                            fetching && (
                                <Grid
                                    container
                                    justify='center'
                                >
                                    <CircularProgress />
                                </Grid>
                            )
                        }
                        </TableContainer>
                        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <Grid container alignItems='center' justify="center">
                                <Pagination
                                    page={page}
                                    count={pages}
                                    color="primary"
                                    onChange={onPagingChange}
                                />
                            </Grid>
                        </div>
                </Column>
            </Box>
            </Box>
        </Box>
    )
}

export default CalendarDateView

