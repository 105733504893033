import React, {useState, useEffect} from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { getCompanies } from '../company/CompanyApi'
import { saveWarehouse } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, IconButton, FormControl, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateWarehouse = (props) =>  {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState('')
    const [name_en, setNameEn] = useState('')
    const [name_mn, setNameMn] = useState('')
    const classes = useStyles();
    const locale = localStorage.getItem('locale')
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        fetchCompanies()
    }, [])

    const fetchCompanies = () => {
        getCompanies({
            limit: 1000
        })
        .then((res) => {
            if (res.data.code == 0) {
                setCompanies(res.data.company.docs)
            }
        })
        .catch((err) => alert(err))
    }

    const onSaveWarehouse = () => {
        if(!company) {
            return alert(t("message.warning.choose_company_first"))
        }
        
        if(!name_mn) {
            return alert(t("message.warning.name_mn"))
        }

        if(!name_en) {
            return alert(t("message.warning.name_en"))
        }

        setFetching(true)
        saveWarehouse({
            name_en,
            name_mn,
            company
        })
        .then(res => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                props.history.push('/app/warehouse')
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
      }

    const onMyLocationList  = () =>  props.history.push('/app/warehouse')

    return(
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.warehouse.new_warehouse")}
                    //btnColor="primary"
                       //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.location.create_new.companies")}</InputLabel>
                                <Select
                                    native
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    label={t("registration.location.create_new.companies")}
                                    inputProps={{
                                        name: 'company',
                                    }}
                                >
                            <option aria-label="None" value="" />
                            {
                                companies.map((company, index) => (
                                    <option value={company._id}>{company[`name_${locale}`]}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={name_mn} onChange={(e) => setNameMn(e.target.value)} label={t('naming.name_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={name_en} onChange={(e) => setNameEn(e.target.value)} label={t('naming.name_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onMyLocationList} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching && (
                                    <Button variant="contained" color="primary" disabled>
                                        {t("action.save")}  
                                    </Button>
                                ) || (
                                    <Button variant="contained" color="primary" onClick={onSaveWarehouse}>
                                        {t("action.save")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CreateWarehouse