import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import { getWarehouses } from '../../utils/RestApi'
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import { toast } from 'react-toastify'

const WarehouseList = (props) => {
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [warehouses, setWarehouses] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const locale = localStorage.getItem('locale')
    const history = useHistory();

    useEffect(() => {
        fetchWarehouse()
    }, [page])

    const fetchWarehouse = () => {
        setFetching(true)
        getWarehouses({
            page
        })
        .then((res) => {
            if (res.data.code == 0) {
                setWarehouses(res.data.result.docs) //users
                setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onEdit = (id) => {
        //history.push(`/app/employee/${id}`)
    }

    const onDelete = (id) => {
        const is_confirm = window.confirm(t('delete'))

        if(is_confirm){
            // setFetching(true)
            // deleteEmployee(id)
            // .then(res => {
            //     if(res.data.code == 0) {
            //         toast.success(t("message.success.deleted"))
            //         fetchWarehouse()
            //     }
            // })
            // .catch(err => alert(err))
            // .then(() => setFetching(false))
        }
    }

    const onNewWarehouse  = () =>  {
        history.push('/app/warehouse/create')
    }

    return (    
        <div>
            <PageTitle
                title={t("registration.warehouse.title")}
                button={t("registration.warehouse.new_warehouse")}
                onButtonClick={onNewWarehouse}  
            />
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('naming.name_mn')}</TableCell>
                            <TableCell>{t('naming.name_en')}</TableCell>
                            <TableCell>{t('naming.company')}</TableCell>
                            <TableCell colSpan={2} align='center'>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            warehouses.map((warehouse, index) => (
                                <TableRow key={index} hover>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{warehouse.name_mn}</TableCell>
                                    <TableCell>{warehouse.name_en}</TableCell>
                                    <TableCell>{warehouse.company[`name_${locale}`]}</TableCell>
                                    <TableCell style={{ width: 30 }}>
                                        <IconButton onClick={() => onEdit(warehouse._id)}>
                                            <EditIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
        </div>
    )
}

export default WarehouseList