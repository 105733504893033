import React, { useState, useEffect } from "react"
import {
    Paper,
    Grid,
    Typography,
    Avatar,
    Box,
    Modal,
} from '@material-ui/core'
import { X } from 'react-feather'
import Flickity from "react-flickity-component"

const ImageModal = (props) => {
    const { pictures } = props
    const [toggle, setToggle] = useState(false)
    const [initialIndex, setInitialIndex] = useState(0)
    return (
        <>
            {
                pictures.map((picture, index) => (
                    <Grid item spacing={1}>
                        <Avatar
                            variant="square"
                            src={picture}
                            alt="X"
                            style={{ height: 100, width: 100 }}
                            onClick={() => {
                                setInitialIndex(index)
                                setToggle(true)
                            }}
                        />
                    </Grid>
                ))
            }
            <Modal
                open={toggle}
                onClose={() => setToggle(false)}
                style={{ padding: 20 }}
            >
                <Box>
                    <Box onClick={() => setToggle(false)} style={{ height: 75, width: 75, position: "absolute", zIndex: 1 }}>
                        <X color="white" style={{ height: 75, width: 75 }} />
                    </Box>
                    <Flickity
                        options={{ initialIndex: initialIndex, resize: true }}
                    >
                        {
                            pictures.map((picture) => (
                                <img src={picture} style={{ marginRight: 50, height: window.innerHeight - 50 }} />
                            ))
                        }
                    </Flickity>
                </Box>
            </Modal>
        </>
    )
}
export default ImageModal
