import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { saveEquipment } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, FormControl, CircularProgress } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateEquipment = (props) =>  {
    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [fetching, setFetching] = useState(false);
    const classes = useStyles();

    const onSaveEquipment = () => {
        setFetching(true)

        if(!id) {
            toast.warning(`${t('registration.equipment.create_new.id')} is empty`)
            return setFetching(false)
        } 
        
        if(!name) {
            toast.warning(`${t('registration.equipment.create_new.name')} is empty`)
            return setFetching(false)
        } 
        
        if(!location) {
            toast.warning(`${t('registration.equipment.create_new.location')} is empty`)
            return setFetching(false)
        }

        saveEquipment({
            equipment_id: id,
            name,
            location
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                props.history.goBack()
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    const onBack  = () =>  props.history.goBack()

    return(
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.equipment.create_new.title")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={id} onChange={(e) => setId(e.target.value)} name="id" label={t('registration.equipment.create_new.id')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={name} onChange={(e) => setName(e.target.value)} name="name" label={t('registration.equipment.create_new.name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={location} onChange={(e) => setLocation(e.target.value)} name="location" label={t('registration.equipment.create_new.location')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveEquipment}>
                                        {t("registration.equipment.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CreateEquipment