import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(15),
            height: theme.spacing(15)
        },
    },
}));

const OrderFilter = (props) => {
    const classes = useStyles();
    const { process_name, processes } = props
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            {
                processes.map((process, index) => (
                    <Paper
                        key={index}
                        // elevation={}
                        onClick={() => props.setProcessName(process.name)}
                        style={{
                            border: process_name == process.name ? '3px solid #F41D24' : 'none',
                        }}
                    >
                        <Grid direction='column' container justify='center' alignItems='center' style={{
                            height: '100%'
                        }}>
                            <div style={{ height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 4, paddingRight: 4 }}>
                                <Typography align='center'>
                                    {t(process.title)}
                                </Typography>
                            </div>
                            <div>
                                <span style={{
                                    color: process.color,
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                }}>
                                    {process.total}
                                </span>
                            </div>
                        </Grid>
                    </Paper>
                ))
            }
        </div>
    )
}

export default OrderFilter