import React, { useRef, useState, useEffect } from 'react'
import {
    Grid,
    CircularProgress,
} from "@material-ui/core";
import _isEmpty from 'lodash/isEmpty'

import { Box, Button, Text, TapArea, Layer, Popover } from 'gestalt'
import moment from 'moment';

import { getFilteredOrder } from './OrderDashboardApi'
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router-dom";
import LocationOn from '@mui/icons-material/LocationOn'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PersonPin from '@mui/icons-material/PersonPin'
import EditIcon from '@mui/icons-material/Edit'
import CustomerMoodView from '../../operator/component/CustomerMoodView'
import CustomerResponse from '../../operator/component/CustomerResponse'
import { currencyFormat } from '../../../utils/helpers';
import OrderCancel from '../../operator/component/OrderCancel';

export const OrderItemInline = (props) => {
    const { order, onTap = () => {}} = props
    const { car, current_process, driver } = order 
    const [open, setOpen] = useState(false)
    const anchorRef = useRef();

    const [fetching, setFetching] = useState(false)
    const [isOpenCancel, setIsOpenCancel] = useState(false)

    const _getColor = () => {
        if(order.cancel_reason) {
            return "#000"
        }

        switch(current_process.name) {
            case "created":
                return "#00A3FF";
            case "assigned":
                return "#2A42C8";
            case "ready_to_drive":
                return "#FF6915";
            case "in_driving":
                return "#F41D24";
            case "ready_to_drop":
                return "#51D698";
            case "waiting_to_pickup":
                return "#51D698";
            default:
                return "#51D698";
        }
    }

    const _calculateBgColor = () => {
        if(order.mood < 3) {
            if(order.mood == 1)
                return "#edc380"
            if(order.mood == 2)
                return "#e4ed80"
        }

        return "#fff"
    }

    const renderDuration = () => {
        if(
            order.current_process.name == 'in_driving' ||
            order.current_process.name == 'ready_to_drop'
        ) {
            let start = order.processes.find((proces) => proces.name == 'in_driving')
            const diration = moment().diff(moment(start.start_at), 'minutes')
            return <Text weight='bold'>{diration} ж/мин</Text>
        }

        if(order.current_process.name == 'ready_to_drive') {
            const diration = moment().diff(moment(order.current_process.start_at), 'minutes')
            return <Text weight='bold'>{diration} хүл/м</Text>
        }

        if(
            order.current_process.name == 'ready_to_drop' ||
            order.current_process.name == 'ready_to_payment' ||
            order.current_process.name == 'waiting_to_pickup'
        ) {
            let start = order.processes.find((proces) => proces.name == 'in_driving')
            let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

            let duration = moment(end.start_at).diff(moment(start.start_at), 'minutes')

            return <Text weight='bold'>{duration} мин</Text>
        }

        return <Text weight='bold'>{String(moment().diff(moment(order.created_at), 'minutes')).substring(0, 2)} д/мин</Text>
    }

    const onCancelOrder = () => {
        setIsOpenCancel(true)
    }

    const onCancelOrderDone = () => {
        setIsOpenCancel(false)
        props.onCancelOrderDone && props.onCancelOrderDone(order)
    }

    return (
        <Box display='flex' marginBottom={2}>
            <Box flex="grow">
                <div style={{
                    background: _calculateBgColor()
                }}>
                    <Box borderStyle='shadow' display='flex' position='relative'>
                        <div
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                width: 8,
                                background: _getColor()
                            }}
                        />
                        <TapArea fullWidth tapStyle='compress' onTap={() => onTap(order)}>
                            <Box paddingY={1}>
                                {
                                    order.from_location.name && (
                                        <Box display='flex'>
                                            <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                                <DirectionsCarIcon />
                                            </Box>
                                            <Box flex='grow' marginEnd={6}>
                                                <Text>{order.from_location.name}</Text>
                                            </Box>
                                        </Box>
                                    )
                                }
                                
                                <Box height={4} />
                                {
                                    order.to_location.name && (
                                        <Box display='flex'>
                                            <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                                <LocationOn />
                                            </Box>
                                            <Box flex='grow' marginEnd={6}>
                                                <Text>{order.to_location.name}</Text>
                                            </Box>
                                        </Box>
                                    )
                                }
                                
                                <Box height={4} />
                                {
                                    car && (
                                        <Box display='flex'>
                                            <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                                <Box width={24} />
                                            </Box>
                                            <Box flex='grow'>
                                                <Box display='flex'>
                                                    <Text>{car.mark}</Text>
                                                    <Box width={10}/>
                                                    <Text weight='bold'>{car.plate}</Text>

                                                    <Box width={10}/>
                                                    <Text weight='bold' lineClamp={1}>{order.phone}</Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                }
                                
                                {
                                    driver && driver.sap && (
                                        <Box>
                                            <Box height={4} />
                                            <Box display='flex'>
                                                <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                                    <PersonPin />
                                                </Box>
                                                <Box flex='grow' display='flex' wrap>
                                                    <Text overflow="breakWord">{driver.sap} {driver.first_name} {driver.phones}</Text>
                                                    <Box display='flex' marginStart={4}>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>        
                                    )
                                }
                                
                                <Box display='flex' marginTop={1} paddingX={3}>
                                    <Box width={28} />
                                    <Box display='flex' flex="grow">
                                        <Text>{currencyFormat(order.price)}</Text>
                                        {
                                            order.additional_price > 0 && (
                                                <Box display='flex'>
                                                    <Box paddingX={2}>
                                                        <Text> + </Text>
                                                    </Box>
                                                    <Text color="error">{currencyFormat(order.additional_price)}</Text>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                </Box>
                                {
                                    order.cancel_reason && (
                                        <Box paddingX={3} display="flex">
                                            <Box width={28} />
                                            <Text inline>Цуцласан: <Text inline weight='bold' color="error">{order.cancel_reason}</Text></Text>
                                        </Box>
                                    )
                                }
                                
                            </Box>
                        </TapArea>
                    </Box>
                </div>
            </Box>
        </Box>
    )
}

export const OrderItem = (props) => {
    const { order, onTap = () => {}} = props
    const { car, current_process, driver } = order 
    const [open, setOpen] = useState(false)
    const anchorRef = useRef();

    const [fetching, setFetching] = useState(false)
    const [isOpenCancel, setIsOpenCancel] = useState(false)

    const _getColor = () => {
        switch(current_process.name) {
            case "created":
                return "#00A3FF";
            case "assigned":
                return "#2A42C8";
            case "ready_to_drive":
                return "#FF6915";
            case "in_driving":
                return "#F41D24";
            case "ready_to_drop":
                return "#51D698";
            case "waiting_to_pickup":
                return "red";
            default:
                return "red";
        }
    }

    const _calculateBgColor = () => {
        if(order.mood < 3) {
            if(order.mood == 1)
                return "#edc380"
            if(order.mood == 2)
                return "#e4ed80"
        }

        return "#fff"
    }

    const renderDuration = () => {
        if(
            order.current_process.name == 'in_driving' ||
            order.current_process.name == 'ready_to_drop'
        ) {
            let start = order.processes.find((proces) => proces.name == 'in_driving')
            const diration = moment().diff(moment(start.start_at), 'minutes')
            return <Text weight='bold'>{diration} ж/мин</Text>
        }

        if(order.current_process.name == 'ready_to_drive') {
            const diration = moment().diff(moment(order.current_process.start_at), 'minutes')
            return <Text weight='bold'>{diration} хүл/м</Text>
        }

        if(
            order.current_process.name == 'ready_to_drop' ||
            order.current_process.name == 'ready_to_payment' ||
            order.current_process.name == 'waiting_to_pickup'
        ) {
            let start = order.processes.find((proces) => proces.name == 'in_driving')
            let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

            let duration = moment(end.start_at).diff(moment(start.start_at), 'minutes')

            return <Text weight='bold'>{duration} мин</Text>
        }

        return <Text weight='bold'>{String(moment().diff(moment(order.created_at), 'minutes')).substring(0, 2)} д/мин</Text>
    }

    const onCancelOrder = () => {
        setIsOpenCancel(true)
    }

    const onCancelOrderDone = () => {
        setIsOpenCancel(false)
        props.onCancelOrderDone && props.onCancelOrderDone(order)
    }

    return (
        <Box display='flex' marginBottom={2}>
            <Box flex="grow">
                <div style={{
                    background: _calculateBgColor()
                }}>
                    <Box borderStyle='shadow' display='flex' position='relative'>
                        <div
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                width: 8,
                                background: _getColor()
                            }}
                        />
                        <TapArea fullWidth tapStyle='compress' onTap={() => onTap(order)}>
                            <Box paddingY={1}>
                                <Box display='flex'>
                                    <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                        <DirectionsCarIcon />
                                    </Box>
                                    <Box flex='grow' marginEnd={6}>
                                        <Text>{order.from_location.name}</Text>
                                    </Box>
                                </Box>
                                <Box height={4} />
                                <Box display='flex'>
                                    <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                        <LocationOn />
                                    </Box>
                                    <Box flex='grow' marginEnd={6}>
                                        <Text>{order.to_location.name}</Text>
                                    </Box>
                                </Box>
                                <Box height={4} />
                                <Box display='flex'>
                                    <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                        <Box width={24} />
                                    </Box>
                                    <Box flex='grow'>
                                        <Box display='flex'>
                                            <Text>{car.mark}</Text>
                                            <Box width={10}/>
                                            <Text weight='bold'>{car.plate}</Text>

                                            <Box width={10}/>
                                            <Text weight='bold' lineClamp={1}>{order.phone}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    driver && (
                                        <Box>
                                            <Box height={4} />
                                            <Box display='flex'>
                                                <Box display='flex' justifyContent='center' alignItems='center' paddingX={2}>
                                                    <PersonPin />
                                                </Box>
                                                <Box flex='grow' display='flex' wrap>
                                                    <Text overflow="breakWord">{driver.sap} {driver.first_name} {driver.phones}</Text>
                                                    <Box display='flex' marginStart={4}>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>        
                                    )
                                }
                                <Box display='flex' marginTop={1} paddingX={3}>
                                    <Box width={28} />
                                    <Box display='flex' flex="grow">
                                        <Text>{currencyFormat(order.price)}</Text>
                                        {
                                            order.additional_price > 0 && (
                                                <Box display='flex'>
                                                    <Box paddingX={2}>
                                                        <Text> + </Text>
                                                    </Box>
                                                    <Text color="error">{currencyFormat(order.additional_price)}</Text>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                </Box>

                                {
                                    order.cancel_reason && (
                                        <div style={{
                                            border: "2px solid red"
                                        }}>
                                            <Box paddingX={3} marginStart={3} marginTop={2} paddingY={1} marginEnd={3}>
                                                <Text size='100'>Цуцлах хүсэлт илгээсэн байна</Text>
                                                <Text weight='bold'>Шалтгаан: {order.cancel_reason}</Text>
                                                
                                                <Box height={8} />

                                                <TapArea tapStyle='compress' onTap={() => {
                                                    onCancelOrder(order)
                                                }}>
                                                    <div style={{
                                                        background: "#000",
                                                        borderRadius: 5,
                                                    }}>
                                                        <Box paddingX={4} paddingY={2} rounding={2}> 
                                                            <Text weight='bold' align='center' color="light">Цуцлах</Text>
                                                        </Box>
                                                    </div>
                                                </TapArea>
                                            </Box>
                                        </div>
                                    )
                                }
                            </Box>
                        </TapArea>

                        <div style={{
                            position: 'absolute',
                            right: 4,
                            top: 4,
                        }}>
                            <TapArea tapStyle="compress" onTap={() => props.onEdit(order)}>
                                <EditIcon />
                            </TapArea>
                        </div>
                        {open && (
                            <Layer>
                            <Popover
                                accessibilityLabel="Save to board"
                                anchor={anchorRef.current}
                                id="main-example"
                                idealDirection="down"
                                onDismiss={() => setOpen(false)}
                                positionRelativeToAnchor={false}
                                size="xl"
                                showDismissButton
                            >
                                <Box width={300}>
                                    <CustomerResponse 
                                        order={order}
                                        addable={false}
                                    />
                                </Box>
                            </Popover>
                            </Layer>
                        )}
                    </Box>
                </div>
            </Box>
            <Box width={3} />
            <Box color="light" minWidth={70} display='flex' direction='column' alignItems='center' justifyContent="evenly">
                <CustomerMoodView
                    mood={order.mood}
                />

                <Box height={1} width="100%" color="dark" />
                
                <Button 
                    size='sm'
                    fullWidth
                    color="transparent"
                    accessibilityHaspopup
                    accessibilityExpanded={open}
                    accessibilityControls="main-example"
                    text={`${order.response_logs.length} чат`}
                    onClick={() => setOpen(!open)}
                    ref={anchorRef}
                />

                <Box height={1} width="100%" color="dark" />

                {
                    renderDuration()
                }
            </Box>

            {
                isOpenCancel && <OrderCancel 
                    mode="edit"
                    order={order}      
                    onClose={() => setIsOpenCancel(false)}
                    onCancelOrderDone={onCancelOrderDone}
                />
            }
        </Box>
    )
}

const OrderList = (props) => {
    const { search_value, process_name, filterFetching, force_refreshing } = props
    const { t } = useTranslation();
    const locale = localStorage.getItem('locale')
    const [pageFetching, setPageFetching] = useState(false)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [orders, setOrders] = useState([])
    const [filter, setFilter] = useState(process_name)
    const id = 13131

    useEffect(() => {
        setPageFetching(true)
        fetchOrders()
    }, [page])

    useEffect(() => {
        props.setFilterFetching(true)
        if (page == 1) {
            fetchOrders()
        }
        else {
            setPage(1)
        }
    }, [search_value, process_name, force_refreshing])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const FetchingIndicator = () => (
        <Grid
            container
            justify='center'
            style={{ paddingTop: 30 }}
        >
            <CircularProgress />
        </Grid>
    )

    const fetchOrders = () => {
        getFilteredOrder({
            search_value,
            process_name,
            page,
            id,
        })
            .then(res => {
                if (res.data.code == 0) {
                    setPages(res.data.result.pages)
                    setOrders(res.data.result.docs)
                }
            })
            .catch(err => alert(err))
            .then(() => {
                props.setFilterFetching(false)
                props.setForceRefreshing(false)
            })
            .then(() => setPageFetching(false))
    }

    const onCancelOrderDone = (order) => {
        setOrders(orders.filter((current) => String(current._id) != String(order._id)))
    }

    return (
        <>
            {
                filterFetching ? (
                    <FetchingIndicator />
                ) : (
                    <Box>
                        {
                            orders.map((order, index) => (
                                <OrderItem 
                                    key={index}
                                    order={order}
                                    onTap={props.onOrderSelected}
                                    onCancelOrderDone={onCancelOrderDone}
                                />
                            ))
                        }
                    </Box>
                )
            }
        </>
    )
}

export default withRouter(OrderList);