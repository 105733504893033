import React, { useEffect, useState } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"

import { Grid, Paper, Button, TextField, Modal, MenuItem, IconButton, FormControl, InputLabel, Select, InputAdornment } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { LoadingButton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom'
import { getCompanies, getLocations, getAreas, sendOrderRequest, getPrevOrders } from '../../utils/RestApi'
import { toast } from 'react-toastify'

import CreateCompanyModal from './CreateCompanyModal'
import CreateAreaModal from './CreateAreaModal'
import CreateLocationModal from './CreateLocationModal'

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    modalPaper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const CreateOrder = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [currency, setCurrency] = React.useState('Choose');
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "mn"

    const [fetching, setFetching] = useState(false)
    const role = localStorage.getItem("role")
    const user = JSON.parse(localStorage.getItem("user"))

    const [order_type, setOrderType] = useState("scaffolding")
    const [company, setCompany] = useState("")
    const [related_order, setReleatedOrder] = useState("")
    const [area, setArea] = useState("")
    const [location, setLocation] = useState("")
    const [detail, setDetail] = useState("")
    const [deadline_at, setDeadlineAt] = useState(null)
    const [duration, setDuration] = useState(0)

    const [orders, setOrders] = useState([])
    const [companies, setCompanies] = useState([])
    const [locations, setLocations] = useState([])
    const [areas, setAreas] = useState([])

    const [is_open_new_company, setIsOpenNewCompany] = useState(false)
    const [is_open_new_area, setIsOpenNewArea] = useState(false)
    const [is_open_new_location, setIsOpenNewLocation] = useState(false)

    useEffect(() => {
        fetchCompanies()
        fetchOrders()
    }, [])

    useEffect(() => {
        setArea("")
        setAreas([])
        fetchAreas()
    }, [company])

    useEffect(() => {
        if(role == 'customer') {
            let company = companies.find((cur) => String(cur._id) == String(user.company))
            if(company) {
                setCompany(company._id)
            }
        }
    }, [companies])

    useEffect(() => {
        setLocation("")
        setLocations([])
        fetchLocations()
    }, [area])

    const fetchAreas = () => {
        getAreas()
            .then((res) => {
                if (res.data.code == 0) {
                    setAreas(res.data.result.docs)
                }
            })
            .catch(err => alert(err))
    }

    const fetchLocations = () => {
        getLocations({
            area
        })
            .then((res) => {
                if (res.data.code == 0) {
                    setLocations(res.data.result.docs)
                }
            })
            .catch(err => alert(err))
    }

    const fetchCompanies = () => {
        getCompanies()
            .then((res) => {
                if (res.data.code == 0) {
                    setCompanies(res.data.company.docs)
                }
            })
            .catch(err => alert(err))
    }

    const fetchOrders = () => {
        getPrevOrders()
            .then((res) => {
                if (res.data.code == 0) {
                    setOrders(res.data.orders)
                }
            })
            .catch(err => alert(err))
    }

    const onMyOrder = () => props.history.push('/app/my-order')
    const onCreateOrderArea = () => {
        if (!company) {
            return alert(t("message.warning.choose_company_first"))
        }

        setIsOpenNewArea(true)
    }
    const onCreateOrderLocation = () => setIsOpenNewLocation(true)
    const onCreateOrderProject = () => setIsOpenNewCompany(true)

    const onCompanySaved = (newCompany) => {
        setCompany(newCompany._id)
        setIsOpenNewCompany(false)
        fetchCompanies()
    }

    const onAreaSaved = (newArea) => {
        setArea(newArea._id)
        setIsOpenNewArea(false)
        fetchAreas()
    }

    const onLocationSaved = (newLocation) => {
        setLocation(newLocation._id)
        setIsOpenNewLocation(false)
        fetchLocations()
    }

    const onBack = () => {
        props.history.goBack()
    }

    const onSendRequest = () => {
        if (order_type == 'scaffolding') {
            if (!company) {
                return toast.warning(t("message.warning.company"))
            }

            if (!area) {
                return toast.warning(t("message.warning.area"))
            }

            if (!location) {
                return toast.warning(t("message.warning.location"))
            }
        } else {
            if (!related_order) {
                return toast.warning(t("message.warning.related_order"))
            }
        }

        if (!deadline_at) {
            return toast.warning(t("message.warning.date_required"))
        }

        if (!detail) {
            return toast.warning(t("message.warning.brief"))
        }

        setFetching(true)
        sendOrderRequest({
            order_type,
            company,
            related_order,
            area,
            location,
            detail,
            deadline_at,
            duration
        })
            .then((res) => {
                if (res.data.code != 0) {
                    alert(res.data.errors[0].msg)
                    return
                }

                if (res.data.code == 0) {
                    onBack()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
    }

    return (
        <div>
            <Paper style={{ padding: 30 }}>
                <PageTitle
                    title={t("order.create.title")}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("order.create.fields.work_type.title")}</InputLabel>
                            <Select
                                native
                                value={order_type}
                                onChange={(e) => {
                                    setOrderType(e.target.value)
                                }}
                                label={t("order.create.fields.work_type.title")}
                                inputProps={{
                                    name: 'project',
                                    id: 'choose_project',
                                }}
                            >
                                <option value="scaffolding">{t("order.create.fields.work_type.build")}</option>
                                <option value="dismantling">{t("order.create.fields.work_type.dismantle")}</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 30 }} />

                {
                    order_type == 'dismantling' ? (
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={11} alignItems='center'>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="#">{t("order.create.fields.related_order.title")}</InputLabel>
                                        <Select
                                            native
                                            value={related_order}
                                            onChange={(event) => {
                                                setReleatedOrder(event.target.value)
                                            }}
                                            label={t("order.create.fields.related_order.title")}
                                            inputProps={{
                                                name: 'project',
                                                id: 'choose_project'
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                orders.map((order, index) => (
                                                    <option key={index} value={order._id}>{order.order_id} - {order.location[`location_${locale}`]}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={onCreateOrderProject}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 30 }} />
                        </div>
                    ) : null
                }

                {
                    order_type == 'scaffolding' ? (
                        <div>
                            {
                                role != 'customer' && [
                                    <Grid container spacing={3}>
                                        <Grid item xs={11} alignItems='center'>
                                            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                                <InputLabel htmlFor="#">{t("order.create.fields.company.title")}</InputLabel>
                                                <Select
                                                    native
                                                    value={company}
                                                    onChange={(event) => {
                                                        setCompany(event.target.value)
                                                    }}
                                                    label={t("order.create.fields.company.title")}
                                                    inputProps={{
                                                        name: 'project',
                                                        id: 'choose_project'
                                                    }}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {
                                                        companies.map((company, index) => (
                                                            <option 
                                                                key={index} 
                                                                value={company._id}
                                                            >
                                                                {company[`name_${locale}`]}
                                                            </option>        
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={onCreateOrderProject}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>,
                                    <div style={{ height: 30 }} />
                                ]
                            }
                            
                            {/* <Grid container spacing={3}>
                                <Grid item xs={12} alignItems='center'>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="#">{t("order.create.fields.states.title")}</InputLabel>
                                        <Select
                                            native
                                            value={state.project}
                                            onChange={handleChange}
                                            label={t("order.create.fields.states.title")}
                                            inputProps={{
                                                name: 'state',
                                                id: 'choose_state',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value={10}>Төсөл 1</option>
                                            <option value={20}>Төсөл 2</option>
                                            <option value={30}>Төсөл 3</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid> */}
                            <Grid container spacing={3}>
                                <Grid item xs={11} alignItems='center'>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="#">{t("order.create.fields.areas.title")}</InputLabel>
                                        <Select
                                            native
                                            value={area}
                                            onChange={(event) => {
                                                setArea(event.target.value)
                                            }}
                                            label={t("order.create.fields.areas.title")}
                                            inputProps={{
                                                name: 'area',
                                                id: 'choose_area',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                areas.map((area, index) => (
                                                    <option key={index} value={area._id}>{area[`area_${locale}`]}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton disabled={!company} onClick={onCreateOrderArea}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 30 }} />
                            <Grid container spacing={3}>
                                <Grid item xs={11} alignItems='center'>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="#">{t("order.create.fields.locations.title")}</InputLabel>
                                        <Select
                                            native
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                            label={t("order.create.fields.locations.title")}
                                            inputProps={{
                                                name: 'location',
                                                id: 'choose_location',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                locations.map((location, index) => (
                                                    <option value={location._id} key={index}>{location[`location_${locale}`]}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton disabled={!area} onClick={onCreateOrderLocation}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div style={{ height: 30 }} />
                        </div>
                    ) : null
                }

                {/* <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("location.action.choose")}</InputLabel>
                            <Select
                                native
                                value={state.project}
                                onChange={handleChange}
                                label={t("location.action.choose")}
                                inputProps={{
                                    name: 'location',
                                    id: 'choose_location',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={10}>Төсөл 1</option>
                                <option value={20}>Төсөл 2</option>
                                <option value={30}>Төсөл 3</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            id="outlined-multiline-static"
                            label={t("order.detail.purpose")}
                            multiline
                            value={detail}
                            onChange={(e) => setDetail(e.target.value)}
                            rows={4}
                            placeholder={t("order.detail.purpose")}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <div style={{ height: 30 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={order_type == 'scaffolding' ? t("order.detail.build_date") : t("order.detail.dismantle_date")}
                            type="date"
                            value={deadline_at}
                            onChange={(e) => setDeadlineAt(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <div style={{ height: 30 }} />
                {
                    order_type == 'scaffolding' ? (
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12} alignItems='center'>
                                    <TextField fullWidth
                                        id="#"
                                        label={t("order.detail.est_dis_date")}
                                        // type="date"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ height: 30 }} />
                        </div>
                    ) : null
                }

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack}>
                                {t("action.back")}
                            </Button>
                            <div style={{ width: 30 }} />

                            <Button disabled={fetching} variant="contained" color="primary" onClick={onSendRequest}>
                                {t("order.create.send_request")}
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <CreateCompanyModal
                is_open={is_open_new_company}
                onClose={() => setIsOpenNewCompany(false)}
                onSave={onCompanySaved}
            />

            <CreateAreaModal
                is_open={is_open_new_area}
                company={companies.find((cur) => cur._id == company)}
                company_id={company}
                onClose={() => setIsOpenNewArea(false)}
                onSave={onAreaSaved}
            />

            <CreateLocationModal
                is_open={is_open_new_location}
                area={areas.find((cur) => cur._id == area)}
                area_id={area}
                onClose={() => setIsOpenNewLocation(false)}
                onSave={onLocationSaved}
            />
        </div>
    )
}

export default withRouter(CreateOrder)