import React, { useCallback, useState, useEffect } from 'react'
import { Box, Spinner, Text, RadioGroup, SegmentedControl, Icon, TapArea, TextField as GestaltTextField, IconButton, Button, Sticky } from 'gestalt'
import PageTitle from "../../components/PageTitle/PageTitle"
import moment from 'moment'
import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableCell,
    Paper,
    CircularProgress,
    TextField,
    TableBody,
    TableContainer
} from '@material-ui/core'

import Autocomplete from '@mui/material/Autocomplete';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Pagination from '@material-ui/lab/Pagination'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getOrderHistory, getCalendarDrivers, getPickups } from '../../utils/RestApi'
import { currencyFormat, _getProcessColor, _getProcessTitle } from '../../utils/helpers'
import _ from 'lodash'
import { saveOrder } from '../dashboard/components/OrderDashboardApi'
import EditOrder from './EditOrder'
import OrderLogs from './OrderLogs'
import OrderCrashLog from './OrderCrashLog'
import { toast } from 'react-toastify'
import { ShieldOff as ShieldOffIcon } from 'react-feather'

const OrderHistory = (props) => {
    const [fetching, setFetching] = useState(false)
    const [orders, setOrders] = useState([])
    const [histories, setHistories] = useState([])


    const [filtered_orders, setFilteredOrders] = useState([])
    const [filtered_histories, setFilteredHistories] = useState([])

    const [drivers, setDrivers] = useState([])
    const [pickup_agents, setPickupAgents] = useState([])
    const [page, setPage] = useState(false)
    const [pages, setPages] = useState(0)

    const [driver, setDriver] = useState(null)
    const [selected_pickup, setSelectedPickup] = useState(null)
    const [total_price, setTotalPrice] = useState(0)
    const [total_qpay_price, setTotalQPayPrice] = useState(0)
    const [total_cash_price, setTotalCashPrice] = useState(0)
    const [itemIndex, setItemIndex] = useState(0);
    const [isOpenLog, setIsOpenLog] = useState(false)

    // const []

    const [search_text, setSearchText] = useState("")
    
    const handleOnInputChange = (value) => {
        setSearchText(value)
    }

    const handler = useCallback(_.debounce(handleOnInputChange, 500), [])

    const [start_at, setStartAt] = useState(dayjs(moment().subtract(1, 'day').toDate()))
    const [end_at, setEndAt] = useState(dayjs(moment().toDate()))

    const [current_process, setCurrentProcess] = useState("all")
    const [pickup_type, setPickupType] = useState("all")

    const [pickups, setPickups] = useState([])
    const [fetching_pickup, setFetchingPickup] = useState(false)
    const [refreshing, setRefreshing] = useState(false)

    const [isOpenEditing, setIsOpenEditing] = useState(false)
    const [isOpenCrash, setIsOpenCrash] = useState(false)

    const [selected_order, setSelectedOrder] = useState({})

    // const functional = _.debounce(_debounceOrderHistory, 500)

    const items = [
        'Дуудлага',
        'Түгээлт',
    ];
    
    const onPagingChange = (event, value) => {
        setPage(value)
    }

    useEffect(() => {
        if(itemIndex == 0) {
            fetchOrderHistory()
        }
    }, [start_at, end_at, driver, current_process])

    useEffect(() => {
        if(search_text.trim()) {
            // debugger
            let filtered_histories = histories.filter((order) => {
                let query_text = "" 
                if(order.from_location) query_text += order.from_location.name
                if(order.to_location) query_text += order.to_location.name
                query_text += order.phone
                if(order.driver) query_text += (order.driver.sap + order.driver.first_name + order.driver.last_name)
                query_text += order.note  

                return query_text.toLowerCase().includes(search_text.toLowerCase())
            })

            let filtered_orders = orders.filter((order) => {
                let query_text = "" 
                if(order.from_location) query_text += order.from_location.name
                if(order.to_location) query_text += order.to_location.name
                query_text += order.phone
                if(order.driver) query_text += (order.driver.sap + order.driver.first_name + order.driver.last_name)
                query_text += order.note  

                return query_text.toLowerCase().includes(search_text.toLowerCase())
            })

            setFilteredHistories(filtered_histories)
            setFilteredOrders(filtered_orders)
        } else {
            setFilteredHistories(histories)
            setFilteredOrders(orders)
        }
    }, [search_text])

    useEffect(() => {
        if(itemIndex == 1) fetchPickups()
    }, [itemIndex, selected_pickup, pickup_type])

    useEffect(() => {
        let totalPrice = 0
        let total_cash_price = 0
        let total_qpay_price = 0

        orders.forEach((order) => {
            totalPrice += (order.price + order.additional_price)
            if(order.payment_type == "CASH") total_cash_price += (order.price + order.additional_price)
            if(order.payment_type == "QPAY") total_qpay_price += (order.price + order.additional_price)
        })

        histories.forEach((order) => {
            totalPrice += (order.price + order.additional_price)
            if(order.payment_type == "CASH") total_cash_price += (order.price + order.additional_price)
            if(order.payment_type == "QPAY") total_qpay_price += (order.price + order.additional_price)
        })

        setTotalPrice(totalPrice)
        setTotalCashPrice(total_cash_price)
        setTotalQPayPrice(total_qpay_price)

        setFilteredHistories(histories)
        setFilteredOrders(orders)
    }, [orders, histories])

    useEffect(() => {
        setPickupAgents(_.uniqBy(pickups, function(current) { return current.pickup && current.pickup._id }))
    }, [pickups])

    useEffect(() => {
        let drivers = []

        drivers = drivers.concat(orders.filter((order) => order.driver).map((order) => order.driver))
        drivers = drivers.concat(histories.filter((order) => order.driver).map((order) => order.driver))


        setDrivers(_.uniqBy(drivers, function(current) { return current._id }))
    }, [orders, histories])

    const onEditOrder = (order) => {
        setSelectedOrder(order)
        setIsOpenEditing(true)
    }
    
    const onAddCrash = (order) => {
        setSelectedOrder(order)
        setIsOpenCrash(true)
    }

    const onCrashDone = (crash) => {
        setIsOpenCrash(false)
    }

    const onOpenLog = (order) => {
        setSelectedOrder(order)
        setIsOpenLog(true)
    }

    const onRefreshOrder = (order) => {
        if (window.confirm("Дуудлагаа сэргээхдээ итгэлтэй байна уу?") == true) {

            // console.log({
            //     from_location: order.from_location,
            //     to_location: order.to_location,
            //     price: order.price,
            //     note: order.note,
            //     car_plate: order.car.plate,
            //     car_mark: order.car.mark
            // })
            
            setRefreshing(true)
            saveOrder({
                from_location: order.from_location,
                to_location: order.to_location,
                price: order.price,
                phone: order.phone,
                note: order.note,
                car_plate: order.car.plate,
                car_mark: order.car.mark
            })
            .then(res => {
                if(res.data.code == 0) {
                    setOrders([res.data.order].concat(orders))
                }
            })
            .catch(err => alert(err))
            .then(() => setRefreshing(false))
        } else {
            // text = "You canceled!";
        }
    }

    // useEffect(() => {})
    
    const fetchDrivers = () => {
        getCalendarDrivers({
            start_at: start_at.toDate(),
            end_at: end_at.toDate()
        })
        .then(res => {
            if(res.data.code == 0) {
                // let drivers = res.data.drivers
                // let exclude = 
                setDrivers(_.uniqBy(res.data.drivers, function(current) { return current.driver._id }))
            }
        })
        .catch(err => alert(err))
        // .then(() =>)
    }

    const fetchPickups = () => {
        setFetchingPickup(true)
        getPickups({
            start_at: start_at.toDate(),
            end_at: end_at.toDate(),
            pickup: selected_pickup ? selected_pickup.value : "",
            pickup_type
        })
        .then(res => {
            if(res.data.code == 0) {
                setPickups(res.data.pickups.filter((current) => current.pickup))
            }
        })
        .catch(err => alert(err))
        .then(() => setFetchingPickup(false))
    }

    const _debounceOrderHistory = () => {
        fetchOrderHistory()
    }

    const onRefresh = (order) => {
        let newOrderHistories = [...histories]

        let orderIndex = newOrderHistories.findIndex((current) => String(current._id) == String(order._id))

        if(orderIndex != -1) {
            newOrderHistories[orderIndex].note = order.note
            newOrderHistories[orderIndex].from_location = order.from_location
            newOrderHistories[orderIndex].to_location = order.to_location
            newOrderHistories[orderIndex].price = order.price

            setHistories(newOrderHistories)
            setIsOpenEditing(false)
            toast.success("Амжилттай хадгаллаа")
        }
    }

    const fetchOrderHistory = () => {
        setHistories([])
        setOrders([])

        setFetching(true)
        getOrderHistory({
            start_at: start_at.toDate(),
            end_at: end_at.toDate(),
            driver_id: driver ? driver.value : "",
            current_process,
            limit: 1000,
            search_text
        })
        .then(res => {
            if(res.data.code == 0) {
                setOrders(res.data.orders.filter((cur) => cur.processes && cur.processes.length > 0))
                setHistories(res.data.histories.filter((cur) => cur.processes && cur.processes.length > 0))
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const driverProps = {
        options: drivers.map((current) => { return { label: `[${current.sap}] ${current.first_name}.${current.last_name.slice(0, 1)}`, value: current._id }}),
        getOptionLabel: (option) => option.label,
    }

    const pickupProps = {
        options: pickup_agents.map((current) => { return { label: `[${current.pickup.sap}] ${current.pickup.first_name}.${current.pickup.last_name.slice(0, 1)}`, value: current.pickup._id }}),
        getOptionLabel: (option) => option.label,
    }

    const calculateDuration = (order) => {
        let start = order.processes.find((proces) => proces.name == 'in_driving')
        let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

        if(start.start_at == null || end.start_at == null) return '-'

        let duration = moment(end.start_at).diff(moment(start.start_at), 'minutes')

        // return start.start_at + " - " + end.start_at
        return duration + ' минут'
    }

    const calculateStartDate = (order) => {
        let start = order.processes.find((proces) => proces.name == 'in_driving')

        if(start.status == 'pending') return "-"

        return moment(start.start_at).format("HH:mm")
    }

    const calculateEndDate = (order) => {
        let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

        if(end.status == 'pending') return "-"

        return moment(end.start_at).format("HH:mm")
    }

    const renderDuration = (order) => {
        if(order.current_process.name == 'in_driving') {
            const diration = moment().diff(moment(order.current_process.start_at), 'minutes')
            return <Text weight='bold' color='light'>{diration} ж/мин</Text>
        }

        if(order.current_process.name == 'ready_to_drive') {
            const diration = moment().diff(moment(order.current_process.start_at), 'minutes')
            return <Text weight='bold' color='light'>{diration} хүл/м</Text>
        }

        if(order.current_process.name == 'ready_to_drop') {
            let start = order.processes.find((proces) => proces.name == 'in_driving')
            const diration = moment().diff(moment(start.start_at), 'minutes')
            return <Text weight='bold' color='light'>{diration} хүл/м</Text>
        }

        if(
            order.current_process.name == 'ready_to_drop' ||
            order.current_process.name == 'ready_to_payment' ||
            order.current_process.name == 'waiting_to_pickup'
        ) {
            let start = order.processes.find((proces) => proces.name == 'ready_to_drive')
            let end = order.processes.find((proces) => proces.name == 'ready_to_payment')

            let duration = moment(end.start_at).diff(moment(start.start_at), 'minutes')

            return <Text weight='bold' color='light'>{duration} мин</Text>
        }

        return <Text weight='bold' color='light'>{String(moment().diff(moment(order.created_at), 'minutes')).substring(0, 2)} д/мин</Text>
    }

    const renderOrderView = () => {
        return (
            <Box>
                <Box marginBottom={4}>
                    <GestaltTextField 
                        // value={search_text}
                        placeholder='Дугаар, жолоочийн нэр, тэмдэглэл, хаяг хайх'
                        onChange={({ value }) => handler(value)}
                    />
                </Box>
                <TableContainer component={Paper}>
                    <Table className="mb-0">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Огноо</TableCell>
                                <TableCell>Хаанаас</TableCell>
                                <TableCell>Хаашаа</TableCell>
                                <TableCell>Дугаар</TableCell>
                                <TableCell>Жолооч</TableCell>
                                <TableCell>Төлбөр</TableCell>
                                <TableCell>Хугацаа</TableCell>
                                <TableCell>Эхэлсэн</TableCell>
                                <TableCell>Дууссан</TableCell>
                                {/* <TableCell>Т</TableCell> */}
                                <TableCell>Дуудлага</TableCell>
                                <TableCell>Тэмдэглэл</TableCell>
                                
                                <TableCell>Төлөв</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filtered_orders.map((order, index) => { 
                                    return (
                                        <TableRow key={index} hover style={{
                                            background: _getProcessColor(order.current_process.name)
                                        }}>
                                            <TableCell>
                                                <Text>{index + 1}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text color='light'>
                                                    [{order.order_number}]
                                                </Text>
                                            </TableCell>
                                            <TableCell><Text color='light'>{moment(order.created_at).format("YYYY:MM:DD HH:mm")}</Text></TableCell>
                                            <TableCell><Text color='light'>{order.from_location.name}</Text></TableCell>
                                            <TableCell><Text color='light'>{order.to_location.name}</Text></TableCell>
                                            <TableCell>
                                                <Button 
                                                    text={order.phone}
                                                    onClick={() => onOpenLog(order)}
                                                    // color=
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Text color='light'>
                                                {
                                                    order.driver && (
                                                        <span style={{ color: "#fff" }}>[{order.driver.sap}] {order.driver.first_name}.{order.driver.last_name.slice(0,1)}</span>
                                                    )
                                                }
                                                </Text>
                                            </TableCell>
                                            <TableCell><Text color='light'>{currencyFormat(order.price + order.additional_price)}</Text></TableCell>
                                            <TableCell><Text color='light'>{renderDuration(order)}</Text></TableCell>
                                            <TableCell><Text color='light'>{calculateStartDate(order)}</Text></TableCell>
                                            <TableCell><Text color='light'>{calculateEndDate(order)}</Text></TableCell>
                                            <TableCell><Text color='light'>{order.phone} {order.car.mark}|{order.car.plate}</Text></TableCell>
                                            <TableCell><Text color='light'>{order.note}</Text></TableCell>
                                            <TableCell>
                                                <Text color='light'>
                                                {_getProcessTitle(order.current_process.name)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                            {
                                filtered_histories.map((order, index) => { 
                                    return (
                                        <TableRow key={index} hover style={{
                                            background: order.is_success ? "#90EE90" : "#4f4f4f"
                                        }}>
                                            <TableCell>
                                                <Text>{index + 1}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    [{order.order_number}]
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    {moment(order.created_at).format("YYYY:MM:DD HH:mm")}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    {order.from_location && order.from_location.name}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    {order.to_location && order.to_location.name}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Button 
                                                    text={order.phone}
                                                    size='sm'
                                                    onClick={() => onOpenLog(order)}
                                                    // color=
                                                />
                                                {/* <Text color={order.is_success ? "dark" : "light"}>
                                                    {order.phone}
                                                </Text> */}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    order.driver && (
                                                        <span style={{ color: order.is_success ? "#000" : "#fff" }}>[{order.driver.sap}] {order.driver.first_name}.{order.driver.last_name.slice(0,1)}</span>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    {currencyFormat(order.price + order.additional_price)} [{order.payment_type}]
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                    {calculateDuration(order)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                {calculateStartDate(order)}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                {calculateEndDate(order)}</TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                {order.phone} {order.car.mark}|{order.car.plate}
                                                </Text>
                                            </TableCell>
                                            <TableCell><Text size='200' color={order.is_success ? "dark" : "light"}>{order.note}</Text></TableCell>
                                            <TableCell>
                                                <Text size='200' color={order.is_success ? "dark" : "light"}>
                                                {order.is_success ? `Амжилттай: ${order.created_by ? order.created_by.first_name : ""}` : (
                                                    <span>
                                                        Цуцласан: [{`${order.created_by ? order.created_by.first_name : ""}`}] - {order.cancel_reason}
                                                    </span>
                                                )}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Box display='flex'>
                                                    <IconButton size='sm' icon="refresh" bgColor="white" onClick={() => onRefreshOrder(order)} />
                                                    <Box width={2} />
                                                    <IconButton size='sm' icon="edit" bgColor="white" onClick={() => onEditOrder(order)} />
                                                    <Box width={2} />
                                                    <TapArea tapStyle="compress" onTap={() => onAddCrash(order)}>
                                                        <Box height={32} width={32} rounding="circle" color="light" display='flex' alignItems='center' justifyContent='center' padding={1}>
                                                            <img 
                                                                src="https://d110hwq6svvzha.cloudfront.net/geru-by-me/uploads/4_dYarO2lqb1hgz4EjBRqScreen_Shot_2024-09-23_at_19.06.34-removebg-preview%20(1).png"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "contain"
                                                                }}
                                                            />
                                                            <ShieldOffIcon size={14} />
                                                        </Box>
                                                    </TapArea>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Sticky bottom={0}>
                    <Box display='flex' justifyContent='end' paddingX={4} paddingY={2} alignItems="end" color='light' borderStyle='sm'>
                        <Box display='flex'>
                            <Text>QPay: </Text>
                            <Box width={4} />
                            <Text weight='bold'> {currencyFormat(total_qpay_price)}</Text>
                        </Box>
                        <Box width={24} />
                        <Box display='flex'>
                            <Text>Бэлнээр: </Text>
                            <Box width={4} />
                            <Text weight='bold'> {currencyFormat(total_cash_price)}</Text>
                        </Box>
                        <Box width={24} />
                        <Box display='flex'>
                            <Text>Нийт үнэ: </Text>
                            <Box width={4} />
                            <Text weight='bold'>{filtered_histories.length + filtered_orders.length} / {currencyFormat(total_price)}</Text>
                        </Box>
                    </Box>
                </Sticky>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            </Box>
        )
    }

    const renderPickupView = () => {
        return (
            <Box>
                <Box display='flex' marginBottom={4} justifyContent='end'>
                    <TapArea tapStyle='compress' fullWidth={false} onTap={() => setPickupType("all")}>
                        <Box display='flex'>
                            <Box height={24} rounding="circle" width={24} color='secondary' display='flex' justifyContent='center' alignItems='center'>
                                {
                                    pickup_type == "all" && (
                                        <Icon 
                                            size={8}
                                            color="dark"
                                            icon="check"
                                        />
                                    )
                                }
                            </Box>

                            <Box width={4} />

                            <Text>Бүгд</Text>
                        </Box>
                    </TapArea>

                    <Box width={20} />
                    <TapArea tapStyle='compress' fullWidth={false} onTap={() => setPickupType("pickup")}>
                        <Box display='flex'>
                            <Box height={24} rounding="circle" width={24} color="successBase" display='flex' justifyContent='center' alignItems='center'>
                                {
                                    pickup_type == "pickup" && (
                                        <Icon 
                                            size={8}
                                            color="light"
                                            icon="check"
                                        />
                                    )
                                }
                            </Box>

                            <Box width={4} />

                            <Text>Авсан</Text>
                        </Box>
                    </TapArea>

                    <Box width={20} />

                    <TapArea tapStyle='compress' fullWidth={false} onTap={() => setPickupType("drop")}>
                        <Box display='flex'>
                            <Box height={24} rounding="circle" width={24} color="recommendationBase" display='flex' justifyContent='center' alignItems='center'>
                                {
                                    pickup_type == "drop" && (
                                        <Icon 
                                            size={8}
                                            color="light"
                                            icon="check"
                                        />
                                    )
                                }
                            </Box>

                            <Box width={4} />

                            <Text>Буулгасан</Text>
                        </Box>
                    </TapArea>
                </Box>

                <Box>
                    <TableContainer component={Paper}>
                        <Table className="mb-0">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Байршил</TableCell>
                                    <TableCell>Жолооч</TableCell>
                                    <TableCell>Түгээлт</TableCell>
                                    <TableCell>Төлөв</TableCell>
                                    <TableCell>Огноо</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {
                                pickups.map((pickup, index) => (
                                    <TableRow hover key={index} style={{
                                        background: pickup.pickup_type == 'drop' ? "#812ae7" : "#008753"
                                    }}>
                                        <TableCell>
                                            <Text color="light">
                                                {index + 1}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text color="light">
                                                {pickup.location.name || pickup.location.full_address}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text color="light">
                                                [{pickup.driver.sap}] {pickup.driver.first_name}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                pickup.pickup && (
                                                    <Text color="light">
                                                        [{pickup.pickup.sap}] {pickup.pickup.first_name}
                                                    </Text>
                                                )
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Text color="light">
                                                {pickup.pickup_type == 'drop' ? "Буулгасан" : "Авсан"}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text color="light">
                                                {moment(pickup.created_at).format("YYYY:MM:DD hh:mm")}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))                                
                            }
                            </TableBody>
                        </Table>
                        {/* <Box display='flex' justifyContent='end' paddingX={4} paddingY={2} alignItems="end">
                            <Text>Нийт үнэ: </Text>
                            <Box width={4} />
                            <Text weight='bold'> {currencyFormat(total_price)}</Text>
                        </Box> */}
                        
                    </TableContainer>
                </Box>
                {   
                    fetching_pickup && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
            </Box>
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box paddingX={10} color="light">
                <PageTitle
                    title="Түүх шүүх"
                />
                <Box marginBottom={4} display='flex' alignItems='center'>
                    <Box display='flex'>
                        <DateTimePicker
                            label="Эхлэх"
                            value={start_at}

                            inputFormat="yyyy:MM:dd HH:mm"
                            format='YYYY:MM:DD HH:mm'
                            ampm={false}
                            onChange={(newValue) => setStartAt(newValue)}
                        />

                        <Box width={20} />

                        <DateTimePicker 
                            label="дуусах"
                            value={end_at}
                            inputFormat="yyyy:MM:dd HH:mm"
                            format='YYYY:MM:DD HH:mm'
                            ampm={false}
                            onChange={(newValue) => setEndAt(newValue)}
                        />
                    </Box>

                    <Box width={20} />

                    <Box width={240}>
                        <SegmentedControl
                            items={items}
                            selectedItemIndex={itemIndex}
                            onChange={({ activeIndex }) => setItemIndex(activeIndex)}
                        />
                    </Box>

                    <Box width={20} />

                    {
                        itemIndex == 0 && (
                            <Box width={240}>
                                <Autocomplete
                                    {...driverProps}
                                    id="controlled-demo"
                                    value={driver}
                                    // freeSolo
                                    onChange={(event, newValue) => {
                                        setDriver(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}  variant="outlined" label="Жолооч сонгох" />
                                    )}
                                />
                            </Box>
                        )
                    }

                    {
                        itemIndex == 1 && (
                            <Box width={240}>
                                <Autocomplete
                                    {...pickupProps}
                                    id="controlled-demo"
                                    value={selected_pickup}
                                    // freeSolo
                                    onChange={(event, newValue) => {
                                        setSelectedPickup(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params}  variant="outlined" label="Түгээлт сонгох" />
                                    )}
                                />
                            </Box>
                        )
                    }
                </Box>

                {
                    itemIndex == 0 && (
                        <Box marginBottom={6}>
                            <RadioGroup legend="Төлөвөөр шүүх" id="header-example" direction='row'>
                                <RadioGroup.RadioButton
                                    checked={current_process == 'all'}
                                    id="all"
                                    label="Бүгд"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('all')}
                                    value="all"
                                />

                                <RadioGroup.RadioButton
                                    checked={current_process == 'created'}
                                    id="created"
                                    label="Хүлээлтэнд"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('created')}
                                    value="created"
                                />

                                <RadioGroup.RadioButton
                                    checked={current_process == 'assigned'}
                                    id="assigned"
                                    label="Явж байгаа"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('assigned')}
                                    value="assigned"
                                />

                                <RadioGroup.RadioButton
                                    checked={current_process == 'ready_to_drive'}
                                    id="ready_to_drive"
                                    label="Очсон"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('ready_to_drive')}
                                    value="ready_to_drive"
                                />

                                <RadioGroup.RadioButton
                                    checked={current_process == 'in_driving'}
                                    id="in_driving"
                                    label="Авсан"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('in_driving')}
                                    value="in_driving"
                                />
                                
                                <RadioGroup.RadioButton
                                    checked={current_process == 'done'}
                                    id="done"
                                    label="Амжилттай"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('done')}
                                    value="done"
                                />

                                <RadioGroup.RadioButton     
                                    checked={current_process == 'canceled'}
                                    id="canceled"
                                    label="Цуцласан"
                                    name="gender-pref"
                                    onChange={() => setCurrentProcess('canceled')}
                                    value="canceled"
                                />
                            </RadioGroup>              
                        </Box>
                    )
                }

                

                {
                    (refreshing || fetching) && (
                        <Box paddingY={4}>
                            <Spinner show={true} />
                        </Box>
                    )
                }

                {
                    itemIndex == 0 && renderOrderView()
                }

                {
                    itemIndex == 1 && renderPickupView()
                }

                {
                    isOpenEditing && (
                        <EditOrder order={selected_order} onClose={() => setIsOpenEditing(false)} onRefresh={onRefresh} />
                    )
                }

                {
                    isOpenLog && (
                        <OrderLogs order={selected_order} onClose={() => setIsOpenLog(false)} />
                    )
                }

                {
                    isOpenCrash && (
                        <OrderCrashLog order={selected_order} onClose={() => setIsOpenCrash(false)} onSave={onCrashDone} />
                    )
                }
            </Box>
        </LocalizationProvider>
    )
}

export default OrderHistory