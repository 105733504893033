import React, { useState, useEffect } from 'react'
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import PageTitle from "../../../components/PageTitle/PageTitle";
import AsyncAutoComplete from '../../../components/AsyncAutoComplete/AsyncAutoComplete'
import { fetchLocation } from '../OperatorApi'
import { saveOrder, editOrder, getOrderFilterCount, getCurrentOrder } from '../../dashboard/components/OrderDashboardApi'
import OrderFilter from '../../dashboard/components/OrderFilter'
import OrderList from '../../dashboard/components/OrderList'
import { Box, Button, TapArea, Text, Spinner, Divider } from 'gestalt'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOn from '@mui/icons-material/LocationOn'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment'
import _isObject from 'lodash/isObject'

import { toast } from 'react-toastify'
import PhoneAutoComplete from './PhoneAutoComplete'
import DriverSelector from './DriverSelector'
import _ from 'lodash'
import { _getProcessTitle, currencyFormat } from '../../../utils/helpers'

import CustomerMood from './CustomerMood'
import CustomerResponse from './CustomerResponse'
import OrderCancel from './OrderCancel'
import OrderCurrentProcess from './OrderCurrentProcess'

import {
    Clipboard,
    Bell,
    PlusSquare,
    Map,
    Zap,
    Activity,
    Shield as ShieldIcon
} from 'react-feather'

export const priceList = [
    // 20000,
    0,
    5000,
    // 25000,
    30000,
    35000,
    40000,
    45000,
    50000,
    55000,
    60000,
    65000,
    70000,
    75000,
    80000,
    85000,
    90000,
    95000,
    100000,
    105000,
    110000,
    115000,
    120000,
    125000,
    130000,
    135000,
    140000,
    145000,
    150000,
    155000,
    160000,
]

export const PrevOrders = (props) => {
    const { orders } = props
    const [isOpen, setIsOpen] = useState(props.isOpen || false)

    if(orders.length == 0) return null

    const successRides = orders.filter((order) => order.price != 0).length

    return (
        <Box>
            <Box paddingX={4}>
                <TapArea onTap={() => setIsOpen(!isOpen)}>
                    <Box paddingY={2} rounding={2} color='secondary' marginBottom={2} display='flex' alignItems='center'>
                        <Box paddingX={3}>
                            <Text inline align='center'>Амжилттай <Text align='center' weight='bold' inline>{successRides} дараалсан</Text> дуудлага өгсөн</Text>
                        </Box>
                        <Box paddingX={2}>
                            <ArrowDropDownIcon />
                        </Box>
                    </Box>
                </TapArea>
            </Box>
            {
                isOpen && (
                    <Box height={400} overflow="scrollY">
                        {
                            orders.map((order, index) => (
                                <Box key={index} color={index % 2 == 0 ? "secondary" : "light"} paddingY={2} paddingX={4}>
                                    <Box display='flex' justifyContent='between' >
                                        <Text>{order.from_location.full_address || order.from_location.name}</Text>    

                                        <Text>{order.price} ₮</Text>
                                    </Box>
                                    <Box display='flex' justifyContent='between' >
                                        <Text color='subtle'>{moment(order.created_at).format("YYYY/MM/DD")}</Text>

                                        <Text color={order.is_success == false ? 'error' : 'success'}>{(order.is_success == false) ? (order.note + order.cancel_reason) : "Амжилттай"}</Text>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                )
            }
        </Box>
    )
}

const OperatorDashboard = (props) => {
    const { simple_view = false, hasBorderStyle = true, selected_order, parent_phone, hidePhone = false } = props
    const [saving, setSaving] = useState(false)
    const [mode, setMode] = useState('new')//
    const [phone, setPhone] = useState(null)
    const [car_plate, setCarPlate] = useState("")
    const [car_mark, setCarMark] = useState("")
    const [price, setPrice] = useState(null)
    const [note, setNote] = useState("")


    const [fetching, setFetching] = useState(false)
    const [driver, setDriver]= useState("")
    const [vip, setVip] = useState(null)
    const [banned, setBanned] = useState(null)
    const [existing_order, setExistingOrder] = useState({})
    const [prev_orders, setPrevOrders] = useState([])
    const [isOpenCancel, setIsOpenCancel] = useState(false)

    const [from_location, setFromLocation] = useState(null)
    const [to_location, setToLocation] = useState(null)
    const [filterFetching, setFilterFetching] = useState(false)
    const [force_refreshing, setForceRefreshing] = useState(false)
    const [process_name, setProcessName] = useState('active')
    const [processes, setProcesses] = useState([{
        _id: 0,
        title: "dashboard.order.filters.all",
        name: 'active',
        color: "#2C2C2C",
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <Clipboard />,
        total: 0,
      }, {
        _id: 1,
        title: "Хүлээлтэнд байгаа",
        name: 'created',
        color: "#00A3FF",
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <Bell />,
        total: 0,
      }, {
        _id: 2,
        title: "Явж байгаа",
        name: 'assigned',
        color: "#2A42C8",
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <Map />,
        total: 0,
      }, {
        _id: 3,
        title: "Очсон",
        color: "#FF6915",
        name: 'ready_to_drive',
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <Zap />,
        total: 0,
      }, {
        _id: 4,
        title: "Авсан",
        color: "#F41D24",
        name: 'in_driving',
        start_at: null,
        end_at: null,
        status: 'idle',
        icon: <Activity />,
        total: 0,
      }, {
        _id: 5,
        title: "Буух дөхсөн",
        color: "#51D698",
        name: 'ready_to_drop',
        start_at: null,
        end_at: null,
        status: 'idle',
        icon: <Activity />,
        total: 0,
      }, {
        _id: 6,
        title: "Түгээлт авах",
        color: "#51D698",
        name: 'waiting_to_pickup',
        start_at: null,
        end_at: null,
        status: 'idle',
        icon: <Activity />,
        total: 0,
      }])

    useEffect(() => {
        fetchOrderFilterCount()
    }, [])

    useEffect(() => {
        resetInputData()
        if(phone) {
            if(_isObject(phone)) {
                if(phone && phone.phone.length == 8) {
                    fetchCurrentOrder(phone.phone)
                }
            } else {
                fetchCurrentOrder(phone)
                setPhone({
                    phone
                })
            }
        }
        
    }, [phone, selected_order])

    useEffect(() => {
        if(vip) {
            setPrice({
                value: 0,
                label: currencyFormat(0)
            })
        }
    }, [vip])

    useEffect(() => {
        if(parent_phone) {
            if(_isObject(parent_phone)) {
                if(parent_phone && parent_phone.phone.length == 8) {
                    setPhone({
                        phone: parent_phone.phone
                    })
                }
            } else {
                setPhone({
                    phone: parent_phone
                })
            }
        }
    }, [parent_phone])

    useEffect(() => {
        if(!_.isEmpty(existing_order)) {
            setCarMark(existing_order.car.mark)
            setCarPlate(existing_order.car.plate)
            setFromLocation(existing_order.from_location)
            setToLocation(existing_order.to_location)
            setNote(existing_order.note)
            setPrice({
                value: existing_order.price,
                label: currencyFormat(existing_order.price)
            })
        }
    }, [existing_order])

    const onRefresh = () => {
        onReset()
        props.onCancelDone && props.onCancelDone()
    }

    const onCancelOrder = () => {
        //modal open
        setIsOpenCancel(true)
    }
    
    const resetInputData = () => {
        setFromLocation(null)
        setToLocation(null)
        setPrice(null)
        setNote("")
        setCarMark("")
        setCarPlate("")
        setExistingOrder({})
        setPrevOrders([])
    }

    const onReset = () => {
        resetInputData()

        setPhone(null)
        setFromLocation(null)
        setToLocation(null)
        setPrice(null)
        setCarMark("")
        setCarPlate("")
        setNote("")
        setMode('new')
    }

    const onSplitOrder = () => {
        setMode("new")
    }

    const fetchCurrentOrder = (phone) => {        
        // alert(selected_order._id)
        setFetching(true)
        getCurrentOrder({
            phone,
            selected_order: _.isEmpty(selected_order) ? "" : selected_order._id
        })
        .then((res) => {
            if(res.data.code == 0) {
                // alert(res.data.type)
                // const { order } = res.data
                if(res.data.type == 'archive') {
                    setMode("new")
                    setPrevOrders(res.data.orders)
                    setExistingOrder({})

                    const defaultPrice = priceList[2]

                    setPrice({
                        label: currencyFormat(defaultPrice),
                        value: defaultPrice
                    })

                    let latestOrder = res.data.orders[0]

                    if(latestOrder && latestOrder.car) {
                        setCarMark(latestOrder.car.mark)
                        setCarPlate(latestOrder.car.plate)
                    }
                }
                    
                if(res.data.type != 'archive') {
                    //edit mode
                    const { order } = res.data

                    setMode("edit")
                    setPrevOrders([])
                    setExistingOrder(order)
                }

                setVip(res.data.vip)
                setBanned(res.data.banned)
            }
        })
        .then(() => setFetching(false))
    }

    const onOrderSelected = (order) => {
        setPhone({
            phone: order.phone
        })
    }

    const fetchOrderFilterCount = () => {
        let newProcesses = [...processes]
        setFilterFetching(true)
        getOrderFilterCount()
          .then(res => {
            if (res.data.code == 0) {
                let total = 0

                res.data.result.forEach((result) => {
                    if(result._id == 'created') newProcesses[1].total = result.total
                    if(result._id == 'assigned') newProcesses[2].total = result.total
                    if(result._id == 'ready_to_drive') newProcesses[3].total = result.total
                    if(result._id == 'in_driving') newProcesses[4].total = result.total
                    if(result._id == 'ready_to_drop') newProcesses[5].total = result.total
                    if(result._id == 'waiting_to_pickup') newProcesses[6].total = result.total
                    if(result._id == 'ready_to_payment') newProcesses[6].total = result.total

                    total += result.total
                })


                newProcesses[0].total = total
                setProcesses(newProcesses)
            }
          })
          .catch(err => console.log(err))
    }

    const onCancelOrderDone = () => {
        setForceRefreshing(true)  
        onReset() 
        setIsOpenCancel(false)
        props.onCancelDone && props.onCancelDone()
    }

    const onEditOrder = () => {
        if(!phone) {
            return toast.warning("Утасны дугаар оруулна уу")
        }

        if(!from_location) {
            return toast.warning("Хаанаас очиж авах вэ?")
        }

        if(!to_location) {
            return toast.warning("Хаашаа хүргэх вэ?")
        }

        if(!price || price.value == null) {
            return toast.warning('Үнэ тохируулна уу')
        }

        if(_.isEmpty(existing_order)) {
            return toast.warning('Дуудлага олдсонгүй')
        }

        setSaving(true)
        editOrder(existing_order._id, {
            from_location,
            to_location,
            price: price.value,
            note,
            phone: phone.phone,
            car_plate,
            car_mark,
        })  
        .then(res => {
            if(res.data.code != 0) {
                alert(res.data.errors[0].msg)
                return
            }

            if(res.data.code == 0) {
                setForceRefreshing(true)  
                props.onSaveFulfilled && props.onSaveFulfilled()
                onReset() 
                setPhone({
                    phone: phone.phone
                })
            }
        }) 
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const onSaveOrder = () => {
        if(mode == 'edit') {
            onEditOrder()
            return
        }

        if(!phone) {
            return toast.warning("Утасны дугаар оруулна уу")
        }

        if(!from_location) {
            return toast.warning("Хаанаас очиж авах вэ?")
        }

        if(!to_location) {
            return toast.warning("Хаашаа хүргэх вэ?")
        }

        if(!price) {
            return toast.warning('Үнэ тохируулна уу')
        }

        

        setSaving(true)
        saveOrder({
            from_location,
            to_location,
            price: price.value,
            note,
            phone: phone.phone,
            car_plate,
            car_mark,
        })  
        .then(res => {
            if(res.data.code != 0) {
                alert(res.data.errors[0].msg)
                return
            }

            if(res.data.code == 0) {
                setForceRefreshing(true)  
                props.onSaveFulfilled && props.onSaveFulfilled()
                onReset() 
                setPhone({
                    phone: phone.phone
                })
            }
        }) 
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const priceProps = {
        options: priceList.map((value) => { return { label: currencyFormat(value), value }}),
        getOptionLabel: (option) => option.label,
    }

    return (
        <Grid container>
            <Grid item xs={simple_view ? 12 : 3}>
                <Box 
                    color='light' 
                    borderStyle={hasBorderStyle ? "shadow" : "none"} 
                    paddingY={simple_view ? 0 : 10} 
                    width="100%"
                    height={simple_view ? "80vh" : "100vh"}
                    overflow="scrollY"
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {
                                !hidePhone && (
                                    <Box paddingX={4}>
                                        <PhoneAutoComplete 
                                            // inputValue={phone && phone.phone}
                                            value={phone}
                                            onChange={(event, newValue) => {
                                                setPhone(newValue)
                                            }}
                                            onBlur={(value) => {
                                                setPhone({
                                                    phone: value
                                                })
                                            }}
                                        />
                                    </Box>
                                )
                            }
                            

                            {
                                mode == 'edit' && !_.isEmpty(existing_order) && (
                                    <Box paddingY={6}>
                                        <Box display='flex' color='infoWeak' alignItems='center'>
                                            <Box paddingY={2} marginTop={2} paddingX={4} flex='grow'>
                                                <Text size='200'>Дуудлага төлөв: </Text>
                                                <Text weight='bold'>{_getProcessTitle(existing_order.current_process.name)}</Text>
                                            </Box>
                                            <TapArea fullWidth={false} onTap={onSplitOrder}>
                                                <Box marginEnd={4}>
                                                    <PlusSquare
                                                        size={30}
                                                        color="#828282"
                                                    />
                                                </Box>
                                            </TapArea>
                                        </Box>
                                        <Box paddingY={2} color='secondary' paddingX={4}>
                                            <Text>Дуудлага өгөөд өнгөрсөн минут:</Text>

                                            <Text weight='bold'>
                                                {moment().diff(moment(existing_order.created_at), 'minutes')} минут
                                            </Text>
                                        </Box>
                                        <OrderCurrentProcess 
                                            order={existing_order}
                                            onRefresh={onRefresh}
                                        />

                                        <CustomerMood 
                                            order={existing_order}
                                        />

                                        <Divider />

                                        <CustomerResponse 
                                            order={existing_order}
                                        />
                                    </Box>
                                )
                            }
                            {/* <Autocomplete
                                {...defaultProps}
                                id="controlled-demo"
                                value={phone}
                                variant="outlined"
                                freeSolo
                                onChange={(event, newValue) => {
                                    setPhone(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Утасны дугаараар хайх" />
                                )}
                            /> */}
                        </Grid>
                        
                        
                        <Grid item xs={12}>
                            <Box display='flex' paddingX={4}>
                                <Box display='flex' justifyContent='center' alignItems='center' width={40}>
                                    <DirectionsCarIcon 
                                    />
                                </Box>
                                <Box flex='grow'>
                                    <AsyncAutoComplete 
                                        id="test" 
                                        label='Хаанаас'
                                        value={from_location}
                                        onChange={(event, newValue) => {
                                            setFromLocation(newValue);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' paddingX={4}>
                                <Box display='flex' justifyContent='center' alignItems='center' width={40}>
                                    <LocationOn />
                                </Box>
                                <Box flex='grow'>
                                    <AsyncAutoComplete 
                                        id="test" 
                                        label='Хаашаа'
                                        value={to_location}
                                        onChange={(event, newValue) => {
                                            setToLocation(newValue);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {
                            fetching && (
                                <Grid item xs={12}>
                                    <Box display='flex' justifyContent='center' paddingY={3}>
                                        <Spinner show={true} />
                                    </Box>
                                </Grid>
                            )
                        }

                        {
                            banned ? (
                                <Box padding={4} paddingX={7}>
                                    <Box paddingX={4} color="dark" paddingY={2} display='flex' alignItems='center' rounding={3}>
                                        <Box>
                                            <Text color="light" weight='bold'>Хар жагсаалтанд байгаа хэрэглэгч</Text>
                                            <Box marginTop={2}>
                                                <Text color="light">Шалтгаан: {banned.note}</Text>
                                                <Box marginTop={1}>
                                                    <Text color="light" weight='bold'>{moment(banned.end_at).format("YYYY/MM/DD")} хүртэл</Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : 
                            <Grid container spacing={3} item xs={12}>
                                <Grid item xs={12}>
                                    {
                                        vip && (
                                            <Box padding={4}>
                                                <Box paddingX={4} color="dark" paddingY={2} display='flex' alignItems='center' rounding={3}>
                                                    <ShieldIcon color="white" /> 
                                                    <Box width={20} />
                                                    <Text color="light" weight='bold'>VIP хэрэглэгч</Text>
                                                </Box>
                                            </Box>
                                        )
                                    }

                                    <Box paddingX={4}>
                                        <Autocomplete
                                            {...priceProps}
                                            id="controlled-demo"
                                            value={price}
                                            freeSolo
                                            onChange={(event, newValue) => {
                                                setPrice(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}  variant="outlined" label="Үнэ" />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box paddingX={4}>
                                        <TextField 
                                            value={car_plate}
                                            onChange={(e) => setCarPlate(e.target.value)}
                                            label="Машины дугаар"
                                            variant="outlined"
                                        />
                                    </Box>
                                    {/* <AsyncAutoComplete 
                                        id="test" 
                                        label='Машины дугаар'
                                        value={to_location}
                                        onChange={(event, newValue) => {
                                            setToLocation(newValue);
                                        }}
                                    /> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <Box paddingX={4}>
                                        <TextField 
                                            value={car_mark}
                                            onChange={(e) => setCarMark(e.target.value)}
                                            label="Машины марк"
                                            variant="outlined"
                                        />
                                    </Box>
                                    {/* <AsyncAutoComplete 
                                        id="test" 
                                        label='Машины марк'
                                        value={to_location}
                                        onChange={(event, newValue) => {
                                            setToLocation(newValue);
                                        }}
                                    /> */}
                                </Grid>

                                <Grid item xs={12}>
                                    <Box paddingX={4}>
                                        <TextField 
                                            value={note}
                                            fullWidth
                                            onChange={(e) => setNote(e.target.value)}
                                            label="Нэмэлт тэмдэглэл"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <PrevOrders 
                                        orders={prev_orders}
                                    />
                                </Grid>
                            </Grid>
                        }

                        

                        {/* <Grid item xs={12}>
                            <Box paddingX={4}>
                                <DriverSelector 
                                    driver={driver}
                                    onDriverChange={(event) => setDriver(event.target.value)}
                                />
                            </Box>
                        </Grid> */}
                    </Grid>

                    {
                        !banned && (
                            <Box paddingY={6} paddingX={4}>
                                {
                                    saving ? <Spinner show={true} /> : (
                                        <Button text="Дуудлага хадгалах" fullWidth color='blue' onClick={onSaveOrder} />
                                    )
                                }
                                
                                <Box height={4} />

                                <Button text="Дуудлага цуцлах" fullWidth color='red' onClick={onCancelOrder} />
                            </Box>
                        )
                    }

                    
                </Box>
            </Grid>
            {
                !simple_view && (
                    <Grid item xs={9}>
                        <Box paddingX={6} mdPaddingX={10}>
                            <Box diplay='flex' justifyContent='center' paddingY={4}>
                                <OrderFilter 
                                    processes={processes}
                                    process_name={process_name}
                                    setProcessName={setProcessName}
                                />
                            </Box>
                            <Box>
                                <OrderList
                                    // is_write={is_write}
                                    // search_value={search_value}
                                    process_name={process_name}
                                    filterFetching={filterFetching}
                                    force_refreshing={force_refreshing}
                                    setFilterFetching={setFilterFetching}
                                    setForceRefreshing={setForceRefreshing}
                                    onOrderSelected={onOrderSelected}
                                />
                            </Box>
                        </Box>
                    </Grid>
                )
            }
            {
                isOpenCancel && <OrderCancel 
                            mode={mode}
                            order={mode == 'edit' ? existing_order : {
                                from_location,
                                to_location,
                                price: price && price.price,
                                note,
                                phone: phone && phone.phone,
                                car_plate,
                                car_mark,
                            }}      
                            onClose={() => setIsOpenCancel(false)}
                            onCancelOrderDone={onCancelOrderDone}
                        />
            }
        </Grid>
    )
} 

export default OperatorDashboard