import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import AddPhone from "../../components/AddPhone/AddPhone"
import { useTranslation } from "react-i18next"
import { Grid, Paper, Button, TextField, Chip, FormControl, InputLabel, CircularProgress, Select, MenuItem, Input } from  '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { takeEmployee, updateEmployee } from '../../utils/RestApi'
import { getConfigs } from './ConfigApi'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const UpdateEmployee = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [addRoster, setAddRoster] = React.useState("");
    const [addExpat, setAddExpat] = React.useState(false);
    const [addSap, setAddSap] = React.useState("");
    const [addFirstName, setAddFirstName] = React.useState("");
    const [addLastName, setAddLastName] = React.useState("");
    const [addEmail, setAddEmail] = React.useState("");
    const [addRoles, setAddRoles] = React.useState([]);
    const [fetching, setFetching] = React.useState(false)
    const [phones, setPhones] = React.useState([""]);
    const [roles, setRoles] = React.useState([]);
    const [rosters, setRosters] = React.useState([]);
    const [driver_licence_date, setDriverLicenceDate] = useState(null)

    const [manual_wheel_option, setManualWheelOption] = useState(false) 
    const [driver_type, setDriverType] = useState("B")
    const [registry_number, setRegistryNumber] = React.useState("");

    const findId = props.match.params.employee_id;

    useEffect(() => {
        fetchEmployees()
    }, [])

    const fetchEmployees = () => {
        takeEmployee(findId)
        .then((res) => {
            if (res.data.code == 0) {
                setAddExpat(res.data.user.is_expat)
                setAddFirstName(res.data.user.first_name)
                setAddLastName(res.data.user.last_name)
                if(res.data.user.phones != ""){
                    setPhones(res.data.user.phones)
                }//d ["342340", "@34324"]
                setAddRoles(res.data.user.roles)
                setAddEmail(res.data.user.email)
                setAddRoster(res.data.user.roster)
                setAddSap(res.data.user.sap)
                setRegistryNumber(res.data.user.registry_number)
                setManualWheelOption(res.data.user.manual_wheel_option)
                setDriverType(res.data.user.driver_type)
                setDriverLicenceDate(dayjs(res.data.user.driver_licence_date))
            }
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchDatas()
    }, [])

    const fetchDatas = () => {
        setRoles([{
            title: "Цагийн ажилтан"
        }, {
            title: "Түгээгч"
        }, {
            title: "Жолооч"
        }, {
            title: "Ээлжийн ахлагч"
        }, {
            title: "Оператор"
        }])
    } 

    const phoneRemove = index => {
        const list = [...phones];
        list.splice(index, 1);
        setPhones(list);
      };
     
    const phoneAdd = () => {
        setPhones([...phones, ""]);
    };

    const getPhones = (e, i) => {
        let newPhones = [...phones]

        newPhones[i] = e.target.value
        setPhones(newPhones)
    }

    const onMyEmployeeList  = () =>  props.history.push('/app/employee');
    
    const onUpdateEmployee = () => {
        setFetching(true)

        if(!phones) {
            toast.warning(`${t('user.phones')} is empty`)
            return setFetching(false)
        } else if(!addSap) {
            toast.warning(`${t('user.sap')} is empty`)
            return setFetching(false)            
        } else if(!addFirstName) {
            toast.warning(`${t('user.first_name')} is empty`)
            return setFetching(false)            
        } else if(!addLastName) {
            toast.warning(`${t('user.last_name')} is empty`)
            return setFetching(false)            
        } else if(!addRoles) {
            toast.warning(`${t('user.roles')} is empty`)
            return setFetching(false)            
        }

        updateEmployee(findId,{
            phones: phones.filter((phone) => phone),
            roster: addRoster,
            is_expat: addExpat,
            sap: addSap,
            first_name: addFirstName,
            last_name: addLastName,
            email: addEmail,
            roles: addRoles,
            registry_number,
            manual_wheel_option,
            auto_wheel_option: true,
            driver_licence_date,
            driver_point: 10,
            driver_type,
        })
        .then(res => {
            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                props.history.push('/app/employee')
            }
        })
        .catch(() => {
            console.log(t("error.request_failed"))
        }).then(() => setFetching(false))
      }

    //   alert(driver_type)
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.employee.update")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addSap} onChange={(e) => setAddSap(e.target.value)} label={t('user.sap')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addLastName} onChange={(e) => setAddLastName(e.target.value)} label={t('user.last_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addFirstName} onChange={(e) => setAddFirstName(e.target.value)} label={t('user.first_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={registry_number} onChange={(e) => setRegistryNumber(e.target.value)} label="Регистрийн дугаар" variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DatePicker 
                            fullWidth 
                            label={'Жолооч болсон жил сонгох'} 
                            openTo="year" 
                            value={driver_licence_date}
                            onChange={(value) => {
                                setDriverLicenceDate(value)
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={["B", "C", "D"]}
                                onChange={(e, roles) => {
                                    setDriverType(String(roles))
                                    // setAddRoles(roles.map((role, i) => role.title)) 
                                }}
                                getOptionLabel={(roles) => roles}
                                value={driver_type.split(",")}
                                filterSelectedOptions
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Жолоочын ангилал"
                                />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">Механик машин барих эсэх</InputLabel>
                                <Select
                                    native
                                    value={manual_wheel_option}
                                    onChange={(e) => setManualWheelOption(e.target.value)}
                                    label="Механик машин барих эсэх"
                                    inputProps={{
                                        name: 'roster',
                                    }}
                                >
                                {
                                    [{
                                        label: "Үгүй",
                                        value: "Үгүй"
                                    },{
                                        label: "Тийм",
                                        value: true
                                    }].map((roster, index) => (
                                        <option value={roster.value} key={index}>{roster.label}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                    <FormControl fullWidth className={classes.formControl}>
                            {/* <InputLabel id="demo-mutiple-chip-label" htmlFor="#">{t("user.role")}</InputLabel>
                            <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={addRoles}
                            onChange={(e) => setAddRoles(e.target.value)}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                            >
                            {roles.map((name, i) => (
                                <MenuItem key={name.title} value={name.title} style={getStyles(name.title, addRoles, theme)}>
                                    {name.title}
                                </MenuItem>
                            ))}
                            </Select> */}
                            
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={roles}
                                onChange={(e, roles) => {
                                    setAddRoles(roles.map((role, i) => role)) 
                                }}
                                getOptionLabel={(option) => option.title}
                                value={addRoles.map((role, i) => role)}
                                filterSelectedOptions
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={t("user.role")}
                                />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                
                <div style={{ height: 20 }} />
                {
                    phones.map((phone, i) => (
                        <AddPhone 
                            setPhone={getPhones}
                            datas={phones}
                            data={phone} //99225
                            index={i}
                            onAdd={phoneAdd}
                            onRemove={phoneRemove}
                        />
                    ))
                }
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' onChange={(e) => setAddEmail(e.target.value)} value={addEmail} label={t('user.email')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onMyEmployeeList} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onUpdateEmployee}>
                                        {t("registration.employee.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
        </LocalizationProvider>
    )
}

export default UpdateEmployee