import React, { useState, useEffect } from 'react'

import ReactPlaceholder from 'react-placeholder';

import 'react-placeholder/lib/reactPlaceholder.css';
import {
    Paper,
    Tabs,
    Tab,
    Box,
    CircularProgress,
} from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"

import OrderDetailMain from './OrderDetailMain'
import OrderDetailProject from './OrderDetailProject'
import OrderDetailProcess from './OrderDetailProcess'
import { useParams } from "react-router-dom";
import { getSelectedOrder } from '../dashboard/components/OrderDashboardApi'

const OrderDetail = (props) => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [order, setSelectedOrder] = useState({})

    const onCreateNewOrder = () => props.history.push('/app/order/create')
    let { order_id } = useParams()

    useEffect(() => {
        fetchSelectedOrder()
    }, [order_id])

    const renderOrderTab = () => {
        switch (index) {
            case 0:
                return <OrderDetailMain order={order} />
            case 1:
                return <OrderDetailProject order={order} />
            case 2:
                return <OrderDetailProcess order={order} />
            default:
                return
        }
    }
    const fetchSelectedOrder = () => {
        getSelectedOrder(order_id)
            .then(res => {
                if (res.data.code == 0) {
                    setSelectedOrder(res.data.order)
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
    }

    return (
        <div>
            {
                fetching == false ? (
                    <Box>
                        <PageTitle
                            hasBack
                            title={t("order.detail.title.main")}
                            //button={t("order.detail.actions.monitoring")}
                        />

                        <Paper square>
                            <Tabs
                                value={index}
                                onChange={(event, newIndex) => setIndex(newIndex)}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label={t("order.detail.title.main_desc")} />
                                <Tab label={t("order.detail.title.additional_desc")} />
                                <Tab label={t("order.detail.title.process_desc")} />
                            </Tabs>
                        </Paper>

                        {
                            renderOrderTab()
                        }
                    </Box>
                ) : (
                    <Box xs={12} justify='center' align="center" style={{ paddingTop: 50 }}>
                        <ReactPlaceholder showLoadingAnimation type="TextBlock" rows={15} />
                    </Box>
                )
            }
        </div>
    )
}

export default OrderDetail