import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import LocationList from '../../pages/Location/components/LocationList'
import Dashboard from '../../pages/dashboard/Dashboard'
import OrderDashboard from "../../pages/dashboard/OrderDashboard"
import MyOrderList from '../../pages/dashboard/MyOrderList'
import CalendarView from '../../pages/dashboard/CalendarView'
import CalendarDateView from '../../pages/dashboard/CalendarDateView'
import EmployeeList from '../../pages/employee/components/EmployeeList'
import InsuranceList from '../../pages/employee/InsuranceList'
import RaportList from '../../pages/employee/RaportList'
import CrashLogs from '../../pages/employee/CrashLogs'
import PickupTracking from '../../pages/employee/PickupTracking'
import ResourceList from '../../pages/resource/components/ResourceList'
import CreateOrder from '../../pages/order/CreateOrder'
import CreateOrderLocation from '../../pages/order/CreateOrderLocation'
import CreateOrderArea from '../../pages/order/CreateOrderArea'
import CreateOrderProject from '../../pages/order/CreateOrderProject'
import OrderMapList from '../../pages/order/OrderMapList'
import OrderHistory from '../../pages/history/OrderHistory'
import CreateWarehouse from '../../pages/inventory/CreateWarehouse'
import OrderDetail from '../../pages/order/OrderDetail'
import ReviewList from '../../pages/review/ReviewList'
import OperatorDashboard from '../../pages/operator/component/OperatorDashboard'

import VipList from '../../pages/customer/VipList'
import BannedList from '../../pages/customer/BannedList'
import CreateEmployee from '../../pages/employee/CreateEmployee'
import UpdateEmployee from '../../pages/employee/UpdateEmployee'
import CreateCertificate from '../../pages/employee/CreateCertificate'

import WarehouseInventory from '../../pages/inventory/WarehouseInventory'
import WarehouseBalance from '../../pages/inventory/WarehouseBalance'
import InventoryMovement from '../../pages/inventory/InventoryMovement'
import InventoryLogs from '../../pages/inventory/InventoryLogs'

import InventoryManagement from '../../pages/inventory/InventoryManagement'
import WarehouseList from '../../pages/resource/WarehouseList'
import CreateResource from '../../pages/resource/CreateResource'
import UpdateResource from '../../pages/resource/UpdateResource'
import CreateLocation from '../../pages/Location/CreateLocation'
import UpdateLocation from '../../pages/Location/UpdateLocation'
import CreateArea from '../../pages/Location/CreateArea'
import CreateEquipment from '../../pages/equipment/CreateEquipment'
import UpdateEquipment from '../../pages/equipment/UpdateEquipment'
import CreateResourceCategory from '../../pages/resource/CreateResourceCategory'

import CustomerList from "../../pages/customer/components/CustomerList"
import CreateCustomer from '../../pages/customer/CreateCustomer'
import UpdateCustomer from '../../pages/customer/UpdateCustomer'
import CreateCompany from '../../pages/customer/CreateCompany'
import Privacy from '../../pages/customer/Privacy'
import DownloadLink from '../../pages/customer/DownloadLink'

import CompanyList from '../../pages/company/component/CompanyList'

import MonitoringNote from '../../pages/order/MonitoringNote.js'
import Feedback from '../../pages/order/Feedback.js'
import EquipmentList from '../../pages/equipment/components/EquipmentList'

import { useLayoutState } from "../../context/LayoutContext";

import { getRoles } from '../../utils/RestApi'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={props =>
          isAuthenticated ? (
              <Component {...props} />
          ) : (
              <Redirect to="/login" />
          )
      }
    />
  )
}

function Layout(props) {
  var classes = useStyles();

  var layoutState = useLayoutState();

  const [roles, setRoles] = useState([])

  useEffect(() => {
    fetchRoles()
  }, [])

  const fetchRoles = () => {
    getRoles()
      .then(res => {
        if (res.data.code == 0) {
          setRoles(res.data.roles)
        }
      })
  }

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar roles={roles} />

          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/app/privacy" exact component={Privacy} />
            <Route path="/app/download" exact component={() => <DownloadLink />} />
            
            <PrivateRoute path="/app/dashboard" component={() => <Dashboard /> } />
            <PrivateRoute path="/app/order" exact component={() => <OrderDashboard roles={roles} /> } />
            <PrivateRoute path="/app/review" exact component={() => <ReviewList />} />
            <PrivateRoute path="/app/employee" exact component={() => <EmployeeList />} />
            <PrivateRoute path="/app/resource" exact component={() => <ResourceList />} />
            <PrivateRoute path="/app/vip" exact component={() => <VipList />} />
            <PrivateRoute path="/app/banned" exact component={() => <BannedList />} />
            <PrivateRoute path="/app/insurance" exact component={() => <InsuranceList />} />
            <PrivateRoute path="/app/raport" exact component={() => <RaportList />} />
            <PrivateRoute path="/app/pickup" exact component={() => <PickupTracking />} />
            <PrivateRoute path="/app/crash" exact component={() => <CrashLogs />} />
            
            
            <PrivateRoute path="/app/history" exact component={() => <OrderHistory /> } />
            {/* <PrivateRoute path="/app/history" exact component={() => <LocationList /> } /> */}
            <PrivateRoute path="/app/calendar" exact component={() => <CalendarView />} />
            <PrivateRoute path="/app/calendar/:date" exact component={CalendarDateView} />
            <PrivateRoute path="/app/location" exact component={() => <LocationList />} />
            <PrivateRoute path="/app/equipment" exact component={() => <EquipmentList />} />
            <PrivateRoute path="/app/warehouse" exact component={() => <WarehouseList />} />
            <PrivateRoute path="/app/company" exact component={() => <CompanyList />} />
            <PrivateRoute path="/app/customer" exact component={() => <CustomerList />} />
            <PrivateRoute path="/app/operator" exact component={() => <OperatorDashboard /> } />
            <PrivateRoute path="/app/map" exact component={() => <OrderMapList /> } />
            <PrivateRoute path="/app/warehouse/balance" exact component={() => <WarehouseBalance />} />
            <PrivateRoute path="/app/inventory" exact component={InventoryManagement} />
            <PrivateRoute path="/app/inventory/:inventory_id/logs" exact component={InventoryLogs} />

            <PrivateRoute path="/app/inventory/movement" exact component={InventoryMovement} />

            <PrivateRoute path="/app/warehouse/:warehouse_id/inventory" exact component={WarehouseInventory} />

            <PrivateRoute path="/app/customer/create" exact component={CreateCustomer} />
            <PrivateRoute path="/app/customer/createCompany" exact component={CreateCompany} />
            <PrivateRoute path="/app/customer/:customer_id" exact component={UpdateCustomer} />

            <PrivateRoute path="/app/employee/create" exact component={CreateEmployee} />
            <PrivateRoute path="/app/employee/createCertificate" exact component={CreateCertificate} />
            <PrivateRoute path="/app/employee/:employee_id" exact component={UpdateEmployee} />

            <PrivateRoute path="/app/location/create" exact component={CreateLocation} />
            <PrivateRoute path="/app/location/createArea" exact component={CreateArea} />
            <PrivateRoute path="/app/location/:location_id" exact component={UpdateLocation} />

            <PrivateRoute path="/app/equipment/create" exact component={CreateEquipment} />
            <PrivateRoute path="/app/equipment/:equipment_id" exact component={UpdateEquipment} />

            <PrivateRoute path="/app/warehouse/create" exact component={CreateWarehouse} />

            <PrivateRoute path="/app/resource/create" exact component={CreateResource} />
            <PrivateRoute path="/app/resource/createCategory" exact component={CreateResourceCategory} />
            <PrivateRoute path="/app/resource/:resource_id" exact component={UpdateResource} />

            <PrivateRoute path="/app/my-order" exact component={MyOrderList} />
            <PrivateRoute path="/app/order/create" exact component={CreateOrder} />
            <PrivateRoute path="/app/order/create/location" exact component={CreateOrderLocation} />
            <PrivateRoute path="/app/order/create/area" exact component={CreateOrderArea} />
            <PrivateRoute path="/app/order/create/project" exact component={CreateOrderProject} />
            <PrivateRoute path="/app/order/:order_id" exact component={OrderDetail} />
            <PrivateRoute patn="/app/order/:order_id/note" component={MonitoringNote} />
            <PrivateRoute patn="/app/order/feedback" exact component={Feedback} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
