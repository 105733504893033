import React, {useState, useEffect} from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { getCompanies } from '../company/CompanyApi'
import { saveArea } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, IconButton, CircularProgress, FormControl, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateArea = (props) =>  {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState('')
    const [areaEn, setAreaEn] = useState('')
    const [areaMn, setAreaMn] = useState('')
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const history = useHistory()
    

    useEffect(() => {
        fetchCompanies()
    }, [])

    const fetchCompanies = () => {
        getCompanies({})
        .then((res) => {
            if (res.data.code == 0) {
                setCompanies(res.data.company.docs)
            }
        })
        .catch((err) => alert(err))
    }

    const onSaveArea = () => {
        setFetching(true)

        if(!areaEn) {
            toast.warning(`${t('registration.location.create_new.area_en')} is empty`)
            return setFetching(false)
        } 
        
        if(!areaMn) {
            toast.warning(`${t('registration.location.create_new.area_mn')} is empty`)
            return setFetching(false)
        } 
        
        if(!company) {
            toast.warning(`${t('registration.location.create_new.companies')} is empty`)
            return setFetching(false)
        }

        saveArea({
            area_en: areaEn,
            area_mn: areaMn,
            company
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.goBack()
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    const onBack  = () =>  props.history.goBack()

    return(
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.location.new_area")}
                    //btnColor="primary"
                       //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={areaMn} onChange={(e) => setAreaMn(e.target.value)} label={t('registration.location.create_new.area_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={areaEn} onChange={(e) => setAreaEn(e.target.value)} label={t('registration.location.create_new.area_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.location.create_new.companies")}</InputLabel>
                                <Select
                                    native
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    label={t("registration.location.create_new.companies")}
                                    inputProps={{
                                        name: 'company',
                                    }}
                                >
                            <option aria-label="None" value="" />
                            {
                                companies.map((company, index) => (
                                    <option value={company._id}>{company.business_name}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveArea}>
                                        {t("registration.location.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CreateArea