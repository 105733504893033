import React from "react";
import { Button } from "@material-ui/core";

// styles
import useStyles from "./styles";
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();
  const { t } = useTranslation()
  const history = useHistory()
  const { btnColor = "primary" } = props

  const onClick = () => {
    if(props.onButtonClick) {
        props.onButtonClick()
    }
  }

  const onBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.pageTitleContainer}>
      {
        props.hasBack && (
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="secondary"
            onClick={onBack}
          >
            {t("action.back")}
          </Button>
        )
      }

      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
          {
            props.rightView && props.rightView()
          }
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color={btnColor}
          onClick={onClick}
        >
          {props.button}
        </Button>
      )}
    </div>
  );
}
