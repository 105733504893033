import React, {useState, useEffect} from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { saveCustomerCompany } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, IconButton, CircularProgress, FormControl, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateCompany = (props) =>  {
    const { t } = useTranslation();
    const [businessName, setBusinessName] = useState('')
    const [registrationID, setRegistrationID] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [nameMn, setNameMn] = useState('')
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const history = useHistory()

    const onSaveCompany = () => {
        setFetching(true)

        if(!nameEn) {
            toast.warning(`${t('registration.customer.create_company.name_en')} is empty`)
            return setFetching(false)
        } 
        
        if(!nameMn) {
            toast.warning(`${t('registration.customer.create_company.name_mn')} is empty`)
            return setFetching(false)
        } 
        
        if(!businessName) {
            toast.warning(`${t('registration.customer.create_company.business_name')} is empty`)
            return setFetching(false)
        }

        if(!registrationID) {
            toast.warning(`${t('registration.customer.create_company.registration_id')} is empty`)
            return setFetching(false)
        }

        saveCustomerCompany({
            name_en: nameEn,
            name_mn: nameMn,
            business_name: businessName,
            registration_id: registrationID
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.goBack()
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    const onBack  = () =>  props.history.goBack()

    return(
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.customer.new_company")}
                    //btnColor="primary"
                       //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={registrationID} onChange={(e) => setRegistrationID(e.target.value)} label={t('registration.customer.create_company.registration_id')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={businessName} onChange={(e) => setBusinessName(e.target.value)} label={t('registration.customer.create_company.business_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={nameMn} onChange={(e) => setNameMn(e.target.value)} label={t('registration.customer.create_company.name_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={nameEn} onChange={(e) => setNameEn(e.target.value)} label={t('registration.customer.create_company.name_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveCompany}>
                                        {t("action.save")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CreateCompany