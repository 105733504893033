import React, { useState } from "react"
import xlsx from "json-as-xlsx"

const Xlsx = () => {
    const [loading, setLoading] = useState(false)

    const downloadXlsx = (data, {
        fileName
    }) => {
        
          
        let settings = {
            fileName, // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
            // RTL: true, // Display the columns from right-to-left (the default value is false)
        }
          
        xlsx(data, settings)
    }

    return {
        loading,
        downloadXlsx
    }
};

export default Xlsx;
