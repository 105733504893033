import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import ReviewFilter from './ReviewFilter'

import {
    Clipboard,
    Bell,
    Map,
    Zap,
    Star as StarIcon,
    Activity,
} from 'react-feather'

const ReviewList = () => {
    const { t } = useTranslation();
    const [process_name, setProcessName] = useState('active')
    const [processes, setProcesses] = useState([{
        _id: 0,
        title: "dashboard.order.filters.all",
        name: 'all',
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <StarIcon />,
        total: 0,
      }, {
        _id: 1,
        title: "5 star",
        name: 'five_star',
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <StarIcon />,
        total: 0,
      }, {
        _id: 2,
        title: "4 star",
        name: 'four_star',
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <StarIcon />,
        total: 0,
      }, {
        _id: 3,
        title: "3 star",
        name: 'three_star',
        start_at: null,
        end_at: null,
        status: 'active',
        icon: <StarIcon />,
        total: 0,
      }, {
        _id: 4,
        title: "2 star",
        name: 'two_star',
        start_at: null,
        end_at: null,
        status: 'idle',
        icon: <StarIcon />,
        total: 0,
      }, {
        _id: 5,
        title: "1 star",
        name: 'one_star',
        start_at: null,
        end_at: null,
        status: 'idle',
        icon: <StarIcon />,
        total: 0,
      }])


    return (
        <div>
            <PageTitle
                // hasBack
                title={t("order.detail.title.main")}
                //button={t("order.detail.actions.monitoring")}
            />
            <ReviewFilter
                processes={processes}
                process_name={process_name}
                setProcessName={setProcessName}
            />
        </div>
    )
}

export default ReviewList