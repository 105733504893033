import React, { useState, useEffect } from 'react'
import { Box, Button, Spinner } from 'gestalt'
import { onOrderNextProcess, doneProject } from '../../../utils/RestApi'

const OrderCurrentProcess = (props) => {
    const { order } = props
    const [fetching, setFetching] = useState(false)

    // alert(order.current_process.name)

    const onReadyToDrive = () => {
        let text = "Та хүлээлгэж эхэлсэн төвөлт шилжүүлэхдээ итгэлтэй байна уу?";
        if (window.confirm(text) == true) {
            setFetching(true)
            onOrderNextProcess(order._id)
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                props.onRefresh()
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        } else {
            // text = "You canceled!";
        }
    }

    const onStartDrive = () => {
        let text = "Та дуудлага эхлүүлэхдээ итгэлтэй байна уу?";
        if (window.confirm(text) == true) {
            setFetching(true)
            onOrderNextProcess(order._id)
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                props.onRefresh()
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        } else {
            // text = "You canceled!";
        }
    }

    const onConfirm = () => {
        setFetching(true)
        onOrderNextProcess(order._id)
        .then((res) => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }

            props.onRefresh()
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const onEndDrive = () => {
        let text = "Та дуудлага дуусгахдаа итгэлтэй байна уу?";
        if (window.confirm(text) == true) {
            setFetching(true)
            onOrderNextProcess(order._id)
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }
                // alert
                if(order.current_process.name == 'in_driving') {
                    onConfirm()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        } else {
            // text = "You canceled!";
        }
    }

    const onPaymentDone = () => {
        let text = "Та дуудлага эхлүүлэхдээ итгэлтэй байна уу?";
        if (window.confirm(text) == true) {
            setFetching(true)
            doneProject(order._id)
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                props.onRefresh()
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        } else {
            // text = "You canceled!";
        }
    }

    if(fetching) {
        return (
            <Box display='flex' justifyContent='center' paddingY={2}>
                <Spinner show={true} />
            </Box>
        )
    }

    if(order.current_process.name == 'assigned') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Хүлээлгэж эхлэх"
                    onClick={onReadyToDrive}
                />
            </Box>
        )
    }

    if(order.current_process.name == 'ready_to_drive') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Дуудлага эхлүүлэх"
                    onClick={onStartDrive}
                />
            </Box>
        )
    }

    if(order.current_process.name == 'waiting_to_pickup') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Дуудлага дуусгах"
                    onClick={onPaymentDone}
                />
            </Box>
        )
    }

    if(order.current_process.name == 'ready_to_drop') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Дуудлага дуусгах"
                    onClick={onEndDrive}
                />
            </Box>
        )
    }

    if(order.current_process.name == 'in_driving') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Дуудлага дуусгах"
                    onClick={onEndDrive}
                />
            </Box>
        )
    }

    if(order.current_process.name == 'ready_to_payment') {
        return (
            <Box>
                <Button 
                    fullWidth
                    color="red"
                    text="Төлбөр төлсөн"
                    onClick={onPaymentDone}
                />
            </Box>
        )
    }

    return null
}

export default OrderCurrentProcess