import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button, TextField, Modal, MenuItem, IconButton, FormControl, InputLabel, Select, InputAdornment } from '@material-ui/core';
import { useTranslation } from "react-i18next"
import { Typography } from '@material-ui/core';
import { saveLocationV2 } from '../../utils/RestApi'
import { toast } from 'react-toastify'

function getModalStyle() {
    const top = 50
    const left = 50
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    modalPaper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CreateLocationModal = (props) => {
    const { is_open, area = {}, area_id } = props
    const [modalStyle] = React.useState(getModalStyle);
    const locale = localStorage.getItem("locale")
    const { t } = useTranslation();
    const classes = useStyles();

    const [fetching, setFetching] = useState(false)
    
    const [name_en, setNameEn] = useState("")
    const [name_mn, setNameMn] = useState("")

    const onSaveLocation = () => {
        if(!name_en) return toast(t("message.warning.location_name_en"))
        if(!name_mn) return toast(t("message.warning.location_name_mn"))

        setFetching(true)
        saveLocationV2({
            area_id,
            name_en,
            name_mn
        })
        .then((res) => {
            if(res.data.code != 0) {
                return alert(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                setNameEn("")
                setNameMn("")
                props.onSave(res.data.location)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Modal
            open={is_open}
            onClose={props.onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.modalPaper}>
                <div style={{ marginBottom: 20 }}>
                    <Typography>{t("location.create.title")}</Typography>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            label={t("area.create.name")}
                            type="text"
                            variant="outlined"
                            disabled
                            value={area[`area_${locale}`]}
                        />
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            label={t("location.create.name")}
                            type="text"
                            variant="outlined"
                            value={name_mn}
                            onChange={(e) => setNameMn(e.target.value)}
                        />
                    </Grid>
                </Grid>
                
                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            label={t("location.create.name_en")}
                            type="text" 
                            variant="outlined"
                            value={name_en}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.location.create_new.companies")}</InputLabel>
                                <Select
                                    native
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    label={t("registration.location.create_new.companies")}
                                    inputProps={{
                                        name: 'company',
                                    }}
                                >
                            <option aria-label="None" value="" />
                            {
                                companies.map((company, index) => (
                                    <option value={company._id}>{company.business_name}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={props.onClose} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching && (
                                    <Button variant="contained" color="primary" disabled>
                                        {t("action.save")}  
                                    </Button>
                                ) || (
                                    <Button variant="contained" color="primary" onClick={onSaveLocation}>
                                        {t("action.save")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

export default CreateLocationModal