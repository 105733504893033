import { Modal, Spinner, Box, Text, Layer, FixedZIndex, Button } from 'gestalt'
import React, { useEffect, useState } from 'react'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextField } from "@material-ui/core";
import { getOrderCancelReason, orderCancel, createNewOrderReason } from '../../../utils/RestApi'
import { toast } from 'react-toastify' 

const OrderCancel = (props) => {
    const { order, mode } = props
    const zIndex = new FixedZIndex(99999)
    const [reasons, setReasons] = useState([])
    const [text, setText] = React.useState("")
    const [fetching, setFetching] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [saving, setSaving] = React.useState(false)

    useEffect(() => {
        fetchReasons()
    }, [])

    const onCancelOrder = () => {
        setSaving(true)
        orderCancel({
            order,
            reason,
            mode
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.error(res.data.errors[0].msg)
            }
            if(res.data.code == 0) {
                toast.success("Амжилттай цуцлалаа")
                props.onCancelOrderDone()
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const fetchReasons = () => {
        getOrderCancelReason()
        .then(res => {
            if(res.data.code == 0) {
                setReasons(res.data.reasons)
            }
        })
        .catch(err => alert(err))
    }

    const onNewReason = () => {
        setFetching(true)
        createNewOrderReason({
            reason: text
        })
        .then(res => {
            if(res.data.code == 0) {
                toast.success("Амжилттай нэмлээ")
                setText("")
                setReasons([...reasons, res.data.reason])
                setReason(res.data.reason.reason)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Layer zIndex={zIndex}>
            <Modal
                heading={
                    <Box>
                        <Text size='400'>Дуудлага ямар шалтгаагаар цуцлагдаж байгаа вэ?</Text>
                    </Box>
                }
                onDismiss={props.onClose}
                footer={
                    <Box display='flex'>
                        <Button text="Хаах" fullWidth onClick={props.onClose} />
                        <Box width={12} />
                        {
                            saving ? <Spinner show={true} /> : <Button color='red' fullWidth text="Цуцлах" onClick={onCancelOrder} /> 
                        }
                    </Box>
                }
            >
                <Box>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Шалтгаан сонгох</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                        >
                            {
                                reasons.map((reason, index) => (
                                    <FormControlLabel value={reason.reason} key={index} control={<Radio />} label={reason.reason} />
                                ))
                            }
                        </RadioGroup>

                    </FormControl>
                    <Box marginTop={4}>
                        <Spinner show={fetching} />
                        <TextField 
                            value={text} 
                            onChange={(e) => setText(e.target.value)} 
                            fullWidth 
                            variant="filled" 
                            label="Шинэ шалтгаан оруулах"
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                // Do code here
                                ev.preventDefault();
                                onNewReason()
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Modal>
        </Layer>
    )
}

export default OrderCancel