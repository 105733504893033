import React from 'react'
import { Box, TapArea, Text } from 'gestalt'

const OrderFilters = (props) => {
    const { selected_filter, filters = []} = props

    return (
        <Box display='flex' color='dark' paddingY={4}>
            {
                filters.map((filter, index) => (
                    <TapArea key={index} tapStyle="compress" onTap={() => props.onChange(filter)}>
                        <Box paddingX={1}>
                            <Box paddingX={3} paddingY={1} color={selected_filter.name == filter.name ? "education" : "light"}>
                                <Text align='center' color={selected_filter.name == filter.name ? "light" : "dark"}>{filter.title}</Text>
                                <Text size={400} weight="bold" align='center' color={selected_filter.name == filter.name ? "light" : "dark"}>{filter.total}</Text>
                            </Box>
                        </Box>
                    </TapArea>
                ))
            }
        </Box>
    )
}

export default OrderFilters