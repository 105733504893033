import React from 'react'
// import { Page } from '../../../components'
// import { MainWrapper } from '../../layout/styles'
import { Box, Text, Heading } from 'gestalt'

const Privacy = () => {
    return (
            <Box padding={10}>
                <Box paddingX={4} paddingY={10}>
                    <Heading>Нууцлалын бодлого</Heading>

                    <Box paddingY={4}>
                        <Text size='lg'>Энд дурдагдсан мэдээллийн нууцлалын бодлого гэдэгт Жолооч103 компани, Жолооч103 онлайн худалдааны платформд бүртгүүлсэн худалдан авагч таны бүртгэлийн мэдээллийн нууцлалт, ашиглалт болон гуравдагч байгууллагад ашиглуулах нөхцөлүүдийг тусгасан болно.</Text>
                    </Box>


                    <Box>
                        <Heading size='md'>Таны хувийн мэдээллийг гуравдагч этгээдэд ашиглуулах нөхцөл</Heading>

                        <Box marginTop={4}>
                            <Text size='lg'>
                            Жолооч103 нь дор дурдсанаас бусад нөхцөлд таны тухай мэдээллийг бусдад дамжуулахгүй.
                            </Text>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            - Луйврын гүйлгээг олж илрүүлэх зорилгоор банкнуудад дамжуулах
                            </Text>
                            <Text>
                            - Луйврын гүйлгээг олж илрүүлэх зорилгоор цагдаагийн байгууллагад дамжуулах
                            </Text>
                            <Text>
                            Луйврын гүйлгээ гэдэг нь бусдын картын дугаарыг хууль бусаар олшруулан авч худалдан авалт хийх, алдагдсан, хаягдсан картыг ашиглан худалдан авалт хийх зэргээр тухайн карт эзэмшигчийн зөвшөөрөлгүй хийгдсэн бүх төрлийн гүйлгээ.
                            </Text>
                        </Box>

                        <Box>
                            <Heading size='sm'>Хувийн мэдээлэл шинэчлэлт</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            Жолооч103-д бүртгүүлсэн таны мэдээлэл үнэн зөв байх шаардлагатай бөгөөд хэрвээ нэр, утас, цахим шуудан, гэрийн болон хүргэлтийн хаягт өөрчлөлт орсон тохиолдолд та доорх аргаар шинэчлэх боломжтой.
                            </Text>

                            <Text>
                            - Жолооч103 апп-ыг ашиглан өөрийн эрхээр нэвтрэн орж хэрэглэгчийн тохиргоо цэсэд хандан мэдээллээ шинэчилнэ.
                            </Text>
                        </Box>
                    </Box>

                    <Box>
                        <Heading size='md'>Таны хувийн мэдээллийн аюулгүй байдал</Heading>

                        <Box marginTop={4}>
                            <Text size='lg'>
                            Тантай холбоотой мэдээллийн аюулгүй байдлыг хангах үүднээс бид бүртгэлийн санг байнга хамгаалалттай байлгаж, зөвхөн өгөгдлийн санд нэвтрэх эрх бүхий удирдах албан тушаалт хүмүүст хандах эрхийг өгсөн байдаг.
                            </Text>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            Онлайнаар хийгдэх таны гүйлгээ нь таны дансны аюулгүй байдлыг хангах үүднээс тухайн банкны олон улсын онлайн картын систем ашиглан хийдэг тул ямар ч аюулгүй.
                            </Text>
                            <Text>
                            Гэвч та өөрийн мэдээллийн аюулгүй байдлыг хангах үүднээс нийтийн эсвэл бусдын гар утас болон төхөөрөмжөөр дамжуулан төлбөр тооцоо, хийхээс зайлсхийх, нууц үгээ бусдад задруулахгүй байх, нууц үгээ өөртэй холбоотой он сар өдөр, утасны дугаар зэргээр хийхгүй байхыг анхааруулж байна.
                            </Text>
                        </Box>
                    </Box>

                    <Box marginBottom={4}>
                            <Heading size='md'>Хэрэглэгчийн мэдээллийг устгах тухай</Heading>
                        </Box>

                        <Box>
                            <Heading size='sm'>Нэг. Нийтлэг үндэслэл</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            1.1 Энэхүү журам нь Жолооч103 аппликейшнд хэрэглэгчийн өөрийн зөвшөөрлөөр оруулсан мэдээллийг хэрэглэгч өөрийн хүсэлтээр устгуулахтай холбоотой үйл ажиллагааг зохицуулна.
                            </Text>
                        </Box>

                        <Box>
                            <Heading size='sm'>Хоёр. Хэрэглэгчийн мэдээлэл</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            2.1 Хэрэглэгчийн мэдээлэл гэж Жолооч103 аппликейшнд хэрэглэгчийн өөрийн зөвшөөрлөөр оруулсан бүртгэлийн мэдээллийг хамруулна.
                            </Text>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            2.2 Байршил /Location Service/. Таны байршилын мэдээллээр дуудлага-руу очсон, буух дөхсөн, түгээлтэд авах үед ашиглах болно
                            </Text>
                        </Box>


                        <Box>
                            <Heading size='sm'>Гурав. Хэрэглэгч өөрийн мэдээллийг өөрчлөн удирдах</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            3.1 Хэрэглэгч Жолооч103 аппликейшныг ашиглахдаа оруулсан өөрийн хувийн мэдээллийг"Миний мэдээлэл" цэсээр дамжуулан өөрийн бүртгэлийн мэдээллийг өөрчлөх, удирдах боломжтой.
                            </Text>
                        </Box>

                        <Box>
                            <Heading size='sm'>Дөрөв. Бүртгэлтэй хэрэглэгч хувийн мэдээллээ устгах</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            4.1 Хэрэглэгч өөрийн хүсэлтээр Жолооч103 аппликейшнд холбогдсон тус журмын 2.1-т заасан мэдээллийг устгуулахаар хүсэлтээ гаргаж болно.
                            </Text>
                            <Text>
                            4.2 Хэрэглэгч өөрийн хувийн мэдээллийг устгуулах хүсэлтийг бичгээр болон цахимаар гаргана.
                            </Text>
                            <Text>
                            4.3 Хэрэглэгч бүртгэлээ цуцлуулах бол үйлчилгээний салбарт өөрийн биеэр цахим иргэний үнэмлэхтэйгээ ирж цуцлах боломжтой.
                            </Text>

                            <Text>
                            4.4 Цахимаар цуцлах хүсэлт илгээх тохиолдолд хэрэглэгч өөрийн бүртгэлтэй и-мэйл хаягаас info@geru.mn и-мэйл хаяг руу хүсэлтээ илгээнэ.
                            </Text>

                            <Text>
                            4.5 Хэрэглэгчийн хүсэлтийг ажлын 5 хоногийн дотор хэрэглэгчийн ирүүлсэн хүсэлтийн дагуу хэрэглэгчийн бүртгэлийг Жолооч103-ны “Харилцагч бүртгэлийн алба”-ны “Мэдээллийн ажилтан” хянаж шийдвэрлэнэ.
                            </Text>

                            <Text>
                            4.6 Бүртгэлгүй хэрэглэгч харилцагчийн үйлчилгээний төвийн лавлах шугамаар Жолооч103 аппликейшнд бүртгэлтэй дугаараасаа холбогдон өөрийн мэдээллээ идэвхгүй болгох боломжтой.
                            </Text>

                            <Text>
                            4.7 Бүртгэлгүй хэрэглэгчийн бүртгэлийг Жолооч103-ны Харилцагчийн үйлчилгээний хэлтсийн “Харилцагчийн зөвлөх” хянаж шийдвэрлэнэ.
                            </Text>
                        </Box>

                        <Box>
                            <Heading size='sm'>Тав. Бүртгэлтэй хэрэглэгчийн мэдээллийг устгахаас татгалзах</Heading>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            5.1 Бүртгэлтэй хэрэглэгч нь өөрийн ойрын болон садан төрлийн /эхнэр, нөхөрийн/ мэдээллийг устгуулах талаар хүсэлт гаргах эрхгүй бөгөөд хүсэлт гаргасан тохиолдолд устгахаас татгалзах
                            </Text>
                            <Text>
                            5.2 Татгалзсан тохиолдолд тухай бүр хүсэлт гаргасан этгээдэд болон тухайн хэрэглэгчид ажлын 5 хоног дотор мэдэгдэнэ.
                            </Text>
                            <Text>
                            5.3 Компани нь харилцагчийн мэдээллийг Монгол улсын хуулийн дагуу дор хаяж 6 жилийн хугацаанд хадгалах үүрэгтэй.
                            </Text>
                        </Box>

                    <Box>
                        <Heading size='md'>Бусад</Heading>

                        <Box marginTop={4}>
                            <Heading size='sm'>Үйлдлийн мэдээлэл цуглуулалт</Heading>
                        </Box>

                        <Box marginTop={4}>
                            <Text size='lg'>
                            Энэ нь хэрэглэгч манай платформыг ашиглах үед хийгдсэн үйлдлүүдийг бид бусад апп-уудын адил бүртгэн авдаг. Энэ нь тухайн хэрэглэгчийн хувийн мэдээлэлтэй холбоогүй бөгөөд зөвхөн хэрэглэгчид апп-ыг ашиглахад илүү хялбар болгох, статистик мэдээлэл гаргах зориулалттай ашигладаг.
                            </Text>
                        </Box>

                        <Box marginTop={4}>
                            <Heading size='sm'>Журамд өөрчлөлт оруулах тухай</Heading>
                        </Box>

                        <Box marginTop={4}>
                            <Text size='lg'>
                            Бид үйлчилгээгээ илүү хүртээмжтэй, аюулгүй болгох, таны хувийн мэдээллийг нууцыг хадгалах зорилгоор энэ журмыг гаргасан бөгөөд өөрчлөлт оруулсан өдөр буюу системд заагдсан “Сүүлд шинэчилсэн” өдрөөс эхлэн шинэчилсэн журам мөрдөгдөнө. Энэ өдрөөс хойш та хуудсыг ашиглавал шинэчилсэн журмыг хүлээн зөвшөөрсөнд тооцох юм.
                            </Text>
                        </Box>

                        <Box marginTop={4}>
                            <Heading size='sm'>Нууцлал хамгааллын бодлого холбоотой асуудал</Heading>
                        </Box>

                        <Box marginTop={4}>
                            <Text size='lg'>
                            Хэрвээ танд манай байгууллагын мэдээллийн нууцлал хамгааллын бодлогын талаар санал гомдол байвал манай Хэрэглэгч хариуцсан менежертэй дараах байдлаар холбогдох боломжтой.
                            </Text>
                        </Box>

                        <Box paddingY={4}>
                            <Text>
                            - Үйлчилгээний лавлах 77103103 утсаар холбогдох
                            </Text>
                            <Text>
                            Хэрэглэгч хариуцсан менежер info@jolooch103.mn хаягаар цахим шуудан илгээх
                            </Text>
                        </Box>

                        <Box>
                            <Text size='lg'>Сүүлд шинэчлэгдсэн хугацаа: 2021 оны 11 сарын 10</Text>
                        </Box>
                    </Box>
                </Box>
        </Box>
    )
}

export default Privacy