const resources = {
    en: {
        translation: {
            "error": {
                "request_failed": "Request Failed",
                "request_success": "Request Success"
            },
            "table": {
                "have": "Yes",
                "havent": "No"
            },
            "naming": {
                "name_en": "English name",
                "name_mn": "Name",
                "company": "Company",
                "resource": "Material Name",
                "quanity": "Quantity",
                "resources": "Materials",
                "warehouse": "Warehouse",
                "state": "State",
                "balance": "Balance",
                "movement": "Movement",
                "materials": "materials",
                "action_by": "Action By",
            },
            "message": {
                "warning": {
                    "company": "Choose company!",
                    "related_order": "Choose Releated Order!",
                    "area": "Choose area!",
                    "location": "Choose location!",
                    "brief": "Please, Insert project brief",
                    "date_required": "Select Required Date",
                    "day_of_use": "Insert Days of usage",
                    "name_en": "Insert English Name",
                    "name_mn": "Insert Name",
                    "choose_company_first": "Choose Company First",
                    "select_warehouse_first": "You can select any warehouse to set balance",
                    "company_name_mn": "Please, Insert company name",
                    "company_name_en": "Please, Insert company english name",
                    "to_location": "Please, Select Transfer Location",
                },
                "success": {
                    "saved": "Successfully saved",
                    "edited": "Succesfully edited",
                    "updated": "Succesfully update",
                    "deleted": "Succesfully deleted"
                }
            },
            "Welcome to App": "Welcome to i-SCAFF",
            "auth": {
                "logout": "Logout",
                "signin": "Sign In",
                "signup": "Sign Up",
            },
            "sidebar": {
                "menus": {
                    "dashboard": {
                        "title": "Dashboard",
                        "main": "Dashboard",
                        "review": "Reviews",
                        "order": "Orders"
                    },
                    "registration": {
                        "title": "Registration",
                        "employee": "Employee",
                        "resource": "Material",
                        "location": "Location",
                        "equipment": "Equipment",
                        "warehouse": "Warehouse",
                        "customer": "Customer",
                        "company": "Company"
                    },
                    "inventory": {
                        "title": "Inventory",
                        "management": "Management"
                    },
                    "report": {
                        "title": "Reports",
                        "resource": "Material",
                        "order": "Order"
                    },
                    "admin": {
                        "title": "Admin",
                        "user": "Users",
                        "roles": "Users",
                    },
                    "user": {
                        "title": "Customer",
                        "order": "Orders"
                    }
                }
            },
            "monitoring": {
                "description": "Шат угсрах, буулгахтай холбоотой сайжруулах санал, цаашид анхаарах зүйлсийг жагсаан оруулах боломжтой",
            },
            "dashboard": {
                "main": {
                    "title": "Dashboard",
                    "chart_1": {
                        "title": 'All active orders',
                    },
                    "chart_2": {
                        "title": 'Materials',
                        "available": "Available to use",
                        "unavailable": "In use",
                    },
                    "chart_3": {
                        "title": "Material warehouses",
                    },
                },
                "order": {
                    "title": "Order List",
                    "new_order": "New Order",
                    "search": "Search by order id, location, area, company",
                    "list": {
                        "headers": {
                            "order_id": "Order Id",
                            "location": "Location",
                            "duration": "Duration",
                            "due_date": "Due Date",
                            "leader": "Leader",
                            "status": "Status",
                            "note": "Note"
                        },
                        "body": {
                            "time": {
                                "hour": "hour",
                                "day": "day",
                            },
                        }
                    },
                    "filters": {
                        "all": "All",
                        "created": "Created",
                        "planning": "Planning",
                        "executing": "Executing",
                        "using": "Using",
                    }
                }
            },
            "company": {
                "create": {
                    "title": "New Company",
                    "name": "Company name",
                    "name_en": "Company name english"
                }
            },
            "inventory": {
                "title": "Inventory",
                "log": "Inventory Logs",
                "log_detail": "Inventory Log Detail",
                "balance": "Warehouse Balance",
                "movement": "Inventory Movement",
                "select_warehouse": "Select Warehouse",
                "from_location": "From Location",
                "to_location": "To Location"
            },
            "registration": {
                "employee": {
                    "title": "Employee List",
                    "new_employee": "New Employee",
                    "new_certificate": "Add Certificate",
                    "create_certificate": {
                        "title": "Add Certificate",
                        "expired_at": "Expired At",
                        "certificate": "Certificate",
                        "employees": "Employees",
                    },
                    "search": "Search Employee...",
                    "update": "Update Employee",
                    "create_new": {
                        "title": "Create New Employee",
                        "success_button": "Save"
                    }
                },
                "company": {
                    "title": "Company List",
                    "search": "Search Company ...",
                    "list": {
                        "headers": {
                            "registry": "Registration Number",
                        }
                    }
                },
                "resource": {
                    "title": "Material List",
                    "new_resource": "New Material",
                    "new_group": "New Category",
                    "search": "Search Resource...",
                    "update": "Update Resource  ",
                    "unit": {
                        "active": "Active",
                        "inactive": "Inactive"
                    },
                    "category": {
                        "title": "New Category",
                        "id": "Category ID",
                        "name_en": "English Name",
                        "name_mn": "Mongolian Name"
                    },
                    "create_new": {
                        "title": "Create New Resource",
                        "material_id": "Material ID",
                        "name_en": "English Name",
                        "name_mn": "Mongolian Name",
                        "unit": "Unit",
                        "quantity": "Quantity",
                        "status": "Status",
                        "weight": "Weight",
                        "success_button": "Add Resource",
                        "category": "Categories"
                    },
                    "list": {
                        "headers": {
                            "material_id": "Material Id",
                            "material_name": "Material Name",
                            "quantity": "Quantity",
                            "unit": "Unit",
                            "weight": "Weight",
                            "category": "Category"
                        }
                    }
                },
                "location": {
                    "title": "Location List",
                    "new_location": "New Location",
                    "search": "Search Location...",
                    "new_area": "New Area",
                    "update": "Update Location",
                    "create_new": {
                        "area": "Areas",
                        "title": "Create New Location",
                        "area_mn": "Area MN",
                        "area_en": "Area EN",
                        "state": "State",
                        "companies": "Choose Company",
                        "location_en": "Location EN",
                        "location_mn": "Location MN",
                        "success_button": "Add Location"
                    },
                    "list": {
                        "headers": {
                            "area": "Area",
                            "state": "State",
                            "location": "Location",
                        }
                    }
                },
                "warehouse": {
                    "title": "Warehouse List",
                    "new_warehouse": "New Warehouse",
                    "configure_inventory": "Configure Inventory",
                },
                "equipment": {
                    "title": "Equipment List",
                    "new_equipment": "New Equipment",
                    "search": "Search Equipment...",
                    "update_title": "Update Equipment",
                    "create_new": {
                        "title": "Create New Equipment",
                        "id": "Equipment ID",
                        "name": "Equipment Name",
                        "location": "Equipment Location",
                        "success_button": "Add Equipment",
                        "update_button": "Update Equipment"
                    },
                    "list": {
                        "headers": {
                            "id": "Equipment ID",
                            "name": "Name",
                            "location": "Location"
                        }
                    }
                },
                "customer": {
                    "title": "Customer List",
                    "search": "Search Customer...",
                    "new_customer": "New Customer",
                    "new_company": "New Company",
                    "create_company": {
                        "title": "Create New Company",
                        "name_mn": "Name MN",
                        "name_en": "Name EN",
                        "registration_id": "Registration ID",
                        "business_name": "Business Name"
                    },
                    "create_new": {
                        "title": "Create New Customer",
                        "phone": "Phone",
                        "email": "Email",
                        "first_name": "First Name",
                        "last_name": "Last Name",
                        "password": "Password",
                        "company": "Company"
                    },
                    "create_company": {
                        "title": "Create New Company",
                        "business_name": "Business Name",
                        "name_mn": "Name MN",
                        "name_en": "Name EN",
                        "registration_id": "Registration ID"
                    },
                    "list": {
                        "headers": {
                            "id": "ID",
                            "phone": "Phone",
                            "email": "Email",
                            "first_name": "First Name",
                            "last_name": "Last Name",
                            "company": "Company"
                        }
                    }
                },
            },
            "order": {
                "title": "Order List",
                "new_order": "Request New Order",
                "list": {
                    "headers": {
                        "status": "Status",
                        "duration": "Duration",
                        "last_update": "Last updated date",
                    }
                },
                "create": {
                    "title": "Scaffolding request",
                    "fields": {
                        "work_type": {
                            "title": "Request type",
                            "build": "Scaffolding",
                            "dismantle": "Dismantling"
                        },
                        "related_order": {
                            "title": "Choose Related Order"
                        },
                        "company": {
                            "title": "Choose Company",
                        },
                        "states": {
                            "title": "Choose state",
                        },
                        "areas": {
                            "title": "Choose area",

                        },
                        "locations": {
                            "title": "Choose location",
                        },
                        "past_works": {
                            "title": "Choose the scaffolding you want to dismantle",
                        },
                        "purpse": "Purpose of your request",
                        "date": "Required date",
                        "use": "Day of use"
                    },
                    "send_request": "Send request",
                },
                "action": {
                    "choose": "Choose order",
                    "create": "Create order",
                    "edit": "Edit order",
                    "delete": "Delete order",
                },
                "detail": {
                    "title": {
                        'main': "Order detail",
                        "main_desc": "Main info",
                        "additional_desc": "Project Info",
                        "plan_desc": "Plan Info",
                        "process_desc": "Process info",
                    },
                    "process": {
                        'requested': "Scaffold request",
                        'collect_requirments': "Collect requirements",
                        "collect_requirments_empty": "There are no collected requirments",
                        'plan': "Plan",
                        'approve': "User approved",
                        'placed': "Placed materials",
                        'started_day': "Started scaffold",
                        'approval_paper': 'Approval papers',
                        'approval_paper_empty': 'Approval papers not added.',
                        'monitoring_note': "Monitoring",
                        'monitoring_note_empty': "Monitoring note not added.",
                        'user_monitoring_note': "User monitoring",
                        'user_monitoring_note_empty': "User monitoring note not added.",
                        'stopped_day': "Stopped scaffold",
                        'done': "Done scaffold",
                        "resources_dism": "Resources Dismantling",
                        "created": "New Order",
                        "requirement": "Requirement",
                        "requirement_done": "Requirement Done",
                        "planning": "Planning",
                        "planning_done": "Planning Done",
                        'resources_arrived': "Material Arrived",
                        "resources_loaded": "Material Loaded",
                        "project_started": "Project Started",
                        "project_done": "Project Done",
                        'tagging': "Tagging",
                        "tagged": "Tagging",//tagging aasaa yalgaagui, zarim huuchin data tagged gesn baigaa
                        'reviewed': "Reviewed",
                        "status": {
                            "active": "Идэвхтэй",
                            "inactive": "Идэвхгүй",
                        },
                    },
                    "actions": {
                        "feedback": "Feedback",
                        "monitoring": "Add monitoring note",
                        "approve": "Approve scaffolding",
                    },
                    "order_id": "Order number",
                    "order_sl": "Squadleader",
                    "purpose": "Brief",
                    "use_date": "Date required",
                    "est_dis_date": "Day of use",
                    "plan": {
                        "title": "Plan",
                        "time": {
                            "title": "Date plan",
                            "start": "Start date",
                            "deadline": "Deadline",
                            "material_down": "Material down",
                        },
                        "schema": {
                            "title": "Schema plan",
                            "type": "Schema type",
                            "pictures": "Schema pictures",
                            "empty": "Schema plan is not added.",
                        },
                        "hr": "Manpower plan",
                        "hr_empty": "Manpower plan is not added.",
                        "material": "Material plan",
                    },
                    "monitoring": {
                        "title": "Monitoring note",
                        "note": "Write a note",
                        "upload": "Upload pictures"
                    },
                    "feedback": {
                        "title": "Feedback",
                        "skill": "Skill of scaffolding",
                        "communication": "Skill of communication",
                        "performance": "Performance",
                        "text": "Additional text",
                    },
                }
            },
            "location": {
                "create": {
                    "id": "Location ID",
                    "name": "Location name",
                    "name_en": "Location english name",
                    "desc": "Location description",
                },
                "name": "Location",
                "action": {
                    "choose": "Choose location",
                    "create": "Create location",
                    "edit": "Edit location",
                    "delete": "Delete location",
                },
            },
            "area": {
                "create": {
                    "title": "New Area",
                    "id": "Area ID",
                    "name": "Area name",
                    "name_en": "Area english name",
                    "desc": "Area description",
                },
                "name": "Area",
                "action": {
                    "choose": "Choose area",
                    "create": "Create area",
                    "edit": "Edit area",
                    "delete": "Delete area",
                },
            },
            "project": {
                "create": {
                    "title": "Create project",
                    "id": "Project ID",
                    "name": "Project name",
                    "eng_name": "Project english name",
                },
                "name": "Project",
                "action": {
                    "choose": "Choose project",
                    "create": "Create project",
                    "edit": "Edit project",
                    "delete": "Delete project",
                },
            },
            "state": {
                "name": "State",
                "choose": "Choose state",
                "state_names": {
                    "ub": "Ulaanbaatar",
                    "arkhangai": "Arkhangai",
                    "bayankhongon": "Bayankhongor",
                    "bayan_uul": "Bayan-Ulgii",
                    "bulgan": "Bulgan",
                    "darkhan": "Darkhan-Uul",
                    "dornod": "Dornod",
                    "dornogovi": "Dornogovi",
                    "dundgovi": "Dundgovi",
                    "govi_altai": "Govi-Altai",
                    "govisumber": "Govisumber",
                    "khentii": "Khentii",
                    "khovd": "Khovd",
                    "khuvsgul": "Khuvsgul",
                    "orkhon": "Orkhon",
                    "selenge": "Selenge",
                    "sukhbaatar": "Sukhbaatar",
                    "tuv": "Tuv",
                    "umnugovi": "Umnugovi",
                    "uvs": "Uvs",
                    "uvurkhangai": "Uvurkhangai",
                    "zavkhan": "Zavkhan",
                }
            },
            "user": {
                "sap": "sap",
                "last_name": "Last name",
                "first_name": "First name",
                "role": "Roles",
                "roster": "Roster",
                "certificate": "Certificate",
                "avatar": "Avatar",
                "password": "Password",
                "phones": "Phones",
                "email": "Email",
                "expat": "Expat"
            },
            "certificate": {
                "Advanced": "Advanced",
                "Intermidate": "Intermidate",
                "Basic": "Basic",
                "Other": "Other",
                "cert1": "OT Working at Heights",
                "cert2": "Scaffolding GASI",
                "cert3": "SG-4 Procedure Training",
                "cert4": "OT Basic Scaffolding",
                "cert5": "OT Intermediate Scaffolding",
                "cert6": "OT Advanced Scaffolding",
                "yes": "Yes",
                "no": "No",
            },
            "warehouse": {
                "title": "Warehouse List",
                "new_warehouse": "New Warehouse",
                "configure_inventory": "Configure Inventory",
            },
            "action": {
                "title": "Action",
                "edit": "Edit",
                "save": "Save",
                "certificates": "Certificates",
                "delete": "Delete",
                "back": "Back",
                "password": "Insert New Password",
                "transfer": "Transfer",
                "send": "Send",
            },
            "delete": "Are you sure?",
        }
    },
    mn: {
        translation: {
            "error": {
                "request_failed": "Таны хүсэлт амжилтгүй боллоо",
                "request_success": "Таны хүсэлт амжилттай боллоо"
            },
            "table": {
                "have": "Тийм",
                "havent": "Үгүй"
            },
            "naming": {
                "name_en": "Англи нэр",
                "name_mn": "Нэр",
                "company": "Компани",
                "resource": "Материал нэр",
                "warehouse": "Агуулах",
                "quanity": "Тоо ширхэг",
                "resources": "Материал",
                "state": "Төлөв",
                "balance": "Үлдэгдэл тэнцүүлсэн",
                "movement": "Шилжилт хөдөлгөөн",
                "materials": "материал",
                "action_by": "Үйлдэл хийсэн",
            },
            "message": {
                "warning": {
                    "company": "Компани сонгоно уу!",
                    "related_order": "Хамааралтай төсөл сонгоно уу!",
                    "area": "Хэсэг сонгоно уу!",
                    "location": "Байршил сонгоно уу!",
                    "brief": "Төслийн шаардлага оруулна уу!",
                    "date_required": "Төсөл дуусах шаарлагатай огноо сонгоно уу!",
                    "day_of_use": "Шат хэрэглэх өдөр оруулна уу!",
                    "select_warehouse_first": "Тухайн агуулах сонгосноор үлдэгдэл тэнцүүлэх боломжтой болно",
                    "choose_company_first": "Эхлээд компани сонгоно уу",
                    "company_name_mn": "Компани нэр оруулна уу",
                    "company_name_en": "Компани англи нэр оруулна уу",
                    "to_location": "Зөөх байршил сонгоно уу",
                },
                "success": {
                    "saved": "Амжилттай хадгаллаа",
                    "edited": "Амжилттай засварлалаа",
                    "updated": "Амжилттай засварлалаа",
                    "deleted": "Амжилттай устгалаа",
                }
            },
            "Welcome to App": "Jolooch103.mn",
            "auth": {
                "logout": "Гарах",
                "signin": "Нэвтрэх",
                "signup": "Бүртгүүлэх",
            },
            "sidebar": {
                "menus": {
                    "dashboard": {
                        "title": "Хяналт",
                        "main": "Хяналтын самбар",
                        "review": "Үнэлгээ хяналт",
                        "order": "Нийт захиалга"
                    },
                    "registration": {
                        "title": "Бүртгэл",
                        "employee": "Ажилтан",
                        "resource": "Материал",
                        "location": "Байршил",
                        "equipment": "Тоног төхөөрөмж",
                        "warehouse": "Агуулах",
                        "customer": "Үйлчлүүлэгч",
                        "company": "Байгууллага"
                    },
                    "inventory": {
                        "title": "Агуулах үлдэгдэл",
                        "management": "Үлдэгдэл удирдлага"
                    },
                    "report": {
                        "title": "Хүснэгтэн тайлангууд",
                        "resource": "Бараа материал",
                        "order": "Захиалга"
                    },
                    "admin": {
                        "title": "Админ",
                        "user": "Хэрэглэгч",
                        "roles": "Хэрэглэгч",
                    },
                    "user": {
                        "title": "Хэрэглэгч",
                        "order": "Захиалга"
                    }
                }
            },
            "monitoring": {
                "description": "Шат угсрах, буулгахтай холбоотой сайжруулах санал, цаашид анхаарах зүйлсийг тэмдэглэл оруулах боломжтой",
            },
            "dashboard": {
                "main": {
                    "title": "Хяналтын самбар",
                    "chart_1": {
                        "title": 'Нийт идэвхтэй захиалгууд'
                    },
                    "chart_2": {
                        "title": 'Материалууд',
                        "available": "Ашиглах боломжтой",
                        "unavailable": "Ашиглаж буй",
                    },
                    "chart_3": {
                        "title": "Материалуудын агуулах",
                    },
                },
                "order": {
                    "title": "Захиалгын жагсаалт",
                    "new_order": "Захиалга үүсгэх",
                    "search": "Захиалгын дугаар, байршил, хэсэг, компани хайх",
                    "list": {
                        "headers": {
                            "order_id": "Захиалгын дугаар",
                            "location": "Байршил",
                            "duration": "Хийгдэх хугацаа",
                            "due_date": "Дуусах хугацаа",
                            "leader": "Багын удирдагч",
                            "status": "Төлөв",
                            "note": "Тэмдэглэл"
                        },
                        "body": {
                            "time": {
                                "hour": "hour",
                                "day": "day",
                            },
                        }
                    },
                    "filters": {
                        "all": "Бүгд",
                        "created": "Шинэ",
                        "planning": "Төлөвлөж буй",
                        "executing": "Хийж буй",
                        "using": "Ашиглаж буй",
                    }
                }
            },
            "company": {
                "create": {
                    "title": "Компани бүртгэх",
                    "name": "Компани нэр",
                    "name_en": "Компани нэр англи"
                }
            },
            "inventory": {
                "title": "Агуулах үлдэгдэл",
                "log": "Агуулах-н түүх",
                "balance": "Үлдэгдэл тэнцүүлэх",
                "movement": "Шилжилт хөдөлгөөн хийх",
                "select_warehouse": "Агуулах сонгох",
                "from_location": "Зөөх байршил",
                "to_location": "Аваачих байршил",
                "log_detail": "Үлдэгдэл хувиарлалт дэлгэрэнгүй",
            },
            "registration": {
                "customer": {
                    "title": "Үйлчлүүлэгчдийн жагсаалт",
                    "search": "Үйлчлүүлэгч хайх...",
                    "new_customer": "Шинэ үйлчлүүлэгч",
                    "new_company": "Шинэ компани",
                    "create_company": {
                        "title": "Шинэ компани бүртгэх",
                        "name_mn": "Монгол нэр",
                        "name_en": "Гадаад нэр",
                        "registration_id": "Бүртгэлийн ID",
                        "business_name": "Бизнес нэр"
                    },
                    "create_new": {
                        "title": "Шинэ үйлчлүүлэгч бүртгэх",
                        "phone": "Утас",
                        "email": "Имэйл",
                        "first_name": "Нэр",
                        "last_name": "Овог",
                        "password": "Нууц үг",
                        "company": "Компани"
                    },
                    "create_company": {
                        "title": "Шинэ компани бүртгэх",
                        "business_name": "Бизнес нэр",
                        "name_mn": "Монгол нэр",
                        "name_en": "Гадаад нэр",
                        "registration_id": "Бүртгэлийн ID"
                    },
                    "list": {
                        "headers": {
                            "id": "ID",
                            "phone": "Утасны дугаар",
                            "email": "Имэйл",
                            "first_name": "Нэр",
                            "last_name": "Овог",
                            "company": "Компани"
                        }
                    }
                },
                "company": {
                    "title": "Компани жагсаалт",
                    "search": "хайх ...",
                    "list": {
                        "headers": {
                            "registry": "Регистр дугаар",
                        }
                    }
                },
                "employee": {
                    "title": "Ажилтан бүртгэл",
                    "new_employee": "Шинэ ажилтан бүртгэх",
                    "new_certificate": "Сертификате олгох",
                    "search": "Ажилчин хайх...",
                    "create_certificate": {
                        "title": "Сертификатэ олгох",
                        "expired_at": "Дуусах огноо",
                        "certificate": "Сертификатэ",
                        "employees": "Ажилчид",
                    },
                    "update": "Ажилтаны мэдээлэл шинэчлэх",
                    "create_new": {
                        "title": "Шинэ ажилтан бүртгэх",
                        "success_button": "Ажилтан бүртгэх",
                    }
                },
                "resource": {
                    "title": "Материал бүртгэл",
                    "new_resource": "Шинэ материал бүртгэх",
                    "new_group": "Шинэ төрөл бүртгэх",
                    "search": "Материал хайх...",
                    "update": "Материал шинэчлэх",
                    "unit": {
                        "active": "Идэвхтэй",
                        "inactive": "Идэвхгүй"
                    },
                    "category": {
                        "title": "Шинэ төрөл бүртгэх",
                        "id": "Төрлийн ID",
                        "name_mn": "Монгол нэр",
                        "name_en": "Гадаад нэр"
                    },
                    "create_new": {
                        "title": "Шинэ материал нэмэх",
                        "material_id": "Материал ID",
                        "name_en": "Гадаад нэр",
                        "name_mn": "Монгол нэр",
                        "unit": "Нэгж",
                        "quantity": "Тоо",
                        "status": "Төлөв",
                        "weight": "Жин",
                        "success_button": "Материал нэмэх",
                        "category": "Төрлүүд"
                    },
                    "list": {
                        "headers": {
                            "material_id": "Материал ID",
                            "material_name": "Материалын нэр",
                            "quantity": "Тоо",
                            "unit": "Нэгж",
                            "weight": "Жин",
                            "category": "Төрөл"
                        }
                    }
                },
                "location": {
                    "title": "Байршилын бүртгэл",
                    "new_location": "Шинэ байршил бүртгэх",
                    "search": "Байршил хайх...",
                    "new_area": "Шинэ бүсчлэл нэмэх",
                    "update": "Байршил шинэчлэх",
                    "create_new": {
                        "area": "Бүсчлэлүүд",
                        "title": "Шинэ Байршил бүртгэх",
                        "area_mn": "Бүсчлэл Монголоор",
                        "area_en": "Бүсчлэл Англиар",
                        "state": "Аймаг, Хот",
                        "companies": "Компаниуд",
                        "location_en": "Байршил Англиар",
                        "location_mn": "Байршил Монголоор",
                        "success_button": "Байршил нэмэх"
                    },
                    "list": {
                        "headers": {
                            "area": "Бүс",
                            "state": "Аймаг",
                            "location": "Байршил",
                        }
                    }
                },
                "warehouse": {
                    "title": "Агуулахын жагсаалт",
                    "new_warehouse": "Шинэ агуулах",
                    "configure_inventory": "Үлдэгдэл тохируулах",
                },
                "equipment": {
                    "title": "Тоног төхөөрөмжийн бүртгэл",
                    "new_equipment": "Шинэ тоног төхөөрөмж бүртгэх",
                    "search": "Тоног төхөөрөмж хайх...",
                    "update_title": "Тоног төхөөрөмжийн мэдээлэл шинэчлэх",
                    "create_new": {
                        "title": "Шинэ тоног төхөөрөмж нэмэх",
                        "id": "Тоног төхөөрөмжийн ID",
                        "name": "Тоног төхөөрөмжийн нэр",
                        "location": "Байршил",
                        "success_button": "Тоног төхөөрөмж нэмэх",
                        "update_button": "Тоног төхөөрөмж засах"
                    },
                    "list": {
                        "headers": {
                            "id": "Тоног төхөөрөмжийн ID",
                            "name": "Нэр",
                            "location": "Байршил"
                        }
                    }
                }
            },
            "order": {
                "title": "Захиалга",
                "new_order": "Шатны захиалга өгөх",
                "list": {
                    "headers": {
                        "status": "Төлөв",
                        "duration": "Ашиглах хугацаа",
                        "last_update": "Төлөв өөрчилсөн огноо",
                    }
                },
                "create": {
                    "title": "Шатны захиалга",
                    "fields": {
                        "work_type": {
                            "title": "Ажлын төрөл",
                            "build": "Шат барих",
                            "dismantle": "Шат буулгах"
                        },
                        "company": {
                            "title": "Компаны нэр",
                        },
                        "related_order": {
                            "title": "Хамааралтай төсөл сонгох"
                        },
                        "states": {
                            "title": "Хот/аймаг сонгох",
                        },
                        "areas": {
                            "title": "Бүсчлэл сонгох",

                        },
                        "locations": {
                            "title": "Байршил сонгох",
                        },
                        "past_works": {
                            "title": "Choose the scaffolding you want to dismantle",
                        },
                        "purpse": "Purpose of your request",
                        "date": "Required date",
                        "use": "Day of use"
                    },
                    "send_request": "Хүсэлт илгээх",
                },
                "action": {
                    "choose": "Захиалга сонгох",
                    "create": "Захиалга үүсгэх",
                    "edit": "Захиалга засах",
                    "delete": "Захиалга устгах",
                },
                "detail": {
                    "title": {
                        "main": "Захиалгын дэлгэрэнгүй",
                        "main_desc": "Ерөнхий мэдээлэл",
                        "additional_desc": "Төслийн мэдээлэл",
                        "plan_desc": "Төлөвлөгөөний мэдээлэл",
                        'process_desc': "Процессийн мэдээлэл",
                    },
                    "process": {
                        'requested': "Шатны хүсэлт",
                        'collect_requirments': "Шаардлага цуглуулах",
                        "collect_requirments_empty": "Цуглуулсан шаардлага байхгүй байна.",
                        'plan': "Төлөвлөгөө",
                        'approve': "Ажил эхлэхийг хэрэглэгч зөвшөөрсөн",
                        'placed': "Материал буулгасан",
                        'started_day': "Ажил эхэлсэн",
                        'approval_paper': 'Зөвшөөрлийн бичиг',
                        'approval_paper_empty': 'Зөвшөөрлийн бичиг оруулаагүй байна.',
                        'monitoring_note': "Явцын дотоод хяналт",
                        'monitoring_note_empty': "Явцын дотоод хяналт оруулаагүй байна.",
                        'user_monitoring_note': "Захиалагчийн хяналт",
                        'user_monitoring_note_empty': "Захиалагч хяналт оруулаагүй байна.",
                        'stopped_day': "Ажил өдөр дууссан",
                        'done': "Шат барьж дууссан",
                        "resources_dism": "Шат буулгаж буй",
                        "created": "Шинэ захиалга",
                        "requirement": "Захиалгын шаардлага",
                        "requirement_done": "Захиалгын шаардлага дууссан",
                        "planning": "Төлөвлөлт",
                        "planning_done": "Төлөвлөлт дууссан",
                        'resources_arrived': "Материал буулгасан",
                        "resources_loaded": "Материал ачсан",
                        "project_started": "Ажил эхлэсэн",
                        "project_done": "Ажил дууссан",
                        'tagging': "Таглаж буй",
                        "tagged": "Таглаж буй",//tagging aasaa yalgaagui, zarim huuchin data tagged gesn baigaa
                        'reviewed': "Үнэлгээ санал өгсөн",
                        "status": {
                            "active": "Active",
                            "inactive": "Inactive",
                        }
                    },
                    "actions": {
                        "feedback": "Үнэлгээ өгөх",
                        "monitoring": "Хяналтын тэмдэглэл оруулах",
                        "approve": "Ажил эхлэхийг зөвшөөрөх",
                    },
                    "order_id": "Захиалгын дугаар",
                    "order_sl": "Ахлах ажилтан",
                    "purpose": "Зориулалт",
                    "use_date": "Баригдсан байх огноо",
                    "build_date": "Баригдсан байх огноо",
                    "dismantle_date": "Буулгасан байх огноо",
                    "est_dis_date": "Ашиглах хоног ",
                    "plan": {
                        "title": "Төлөвлөгөө",
                        "time": {
                            "title": "Хугацааны төлөвлөгөө",
                            "start": "Ажил эхлэх",
                            "deadline": "Ажил дуусгах",
                            "material_down": "Материал буулгах",
                            "est_use": "Ашиглах хугацаа /ойролцоогоор",
                        },
                        "schema": {
                            "title": "Шатны төлөвлөгөө",
                            "type": "Шатны төрөл",
                            "pictures": "Шатны зураг",
                            "empty": "Шатны төлөвлөгөө оруулаагүй байна.",
                        },
                        "hr": "Хүн хүч",
                        "hr_empty": "Хүн хүчний төлөвлөгөө оруулаагүй байна.",
                        "material": "Материал",
                    },
                    "monitoring": {
                        "title": "Явцын хяналтын тэмдэглэл оруулах",
                        "note": "Тэмдэглэл оруулах",
                        "upload": "Зураг оруулах"
                    },
                    "feedback": {
                        "title": "Үнэлгээ өгөх",
                        "skill": "Ажилчдын чадварыг үнэлнэ үү",
                        "communication": "Ажилчдын харилцааг үнэлнэ үү",
                        "performance": "Ажилчдын гүйцэтгэлийг үнэлнэ үү",
                        "text": "Санал сэтгэгдэл бичнэ үү",
                    },
                    // "note":{
                    //     "title":"",
                    // },
                },
            },
            "location": {
                "create": {
                    "id": "Байршил ID",
                    "name": "Байршил нэр",
                    "name_en": "Байршил англи нэр",
                    "desc": "Байршил тайлбар",
                },
                "name": "Байршил",
                "action": {
                    "choose": "Байршил сонгох",
                    "create": "Байршил нэмэх",
                    "edit": "Байршил засах",
                    "delete": "Байршил устгах",
                },
            },
            "area": {
                "create": {
                    "title": "Бүсчлэл нэмэх",
                    "id": "Бүсчлэл ID",
                    "name": "Бүсчлэл нэр",
                    "eng_name": "Бүсчлэл англи нэр",
                    "desc": "Бүсчлэл тайлбар",
                },
                "name": "Бүсчлэл",
                "action": {
                    "choose": "Бүсчлэл сонгох",
                    "create": "Бүсчлэл нэмэх",
                    "edit": "Бүсчлэл засах",
                    "delete": "Бүсчлэл устгах",
                },
            },
            "project": {
                "create": {
                    "title": "Төсөл нэмэх",
                    "id": "Төсөл ID",
                    "name": "Төслийн нэр",
                    "eng_name": "Төслийн англи нэр",
                    "desc": "Төслийн тайлбар",
                },
                "name": "Төсөл",
                "action": {
                    "choose": "Төсөл сонгох",
                    "create": "Төсөл нэмэх",
                    "edit": "Төсөл засах",
                    "delete": "Төсөл устгах",
                },
            },
            "state": {
                "name": "Хот/аймаг",
                "choose": "Хот/аймаг сонгох",
                "state_names": {
                    "ub": "Улаанбаатар",
                    "arkhangai": "Архангай",
                    "bayankhongon": "Баянхонгор",
                    "bayan_uul": "Баян-Өлгий",
                    "bulgan": "Булган",
                    "darkhan": "Дархан-Уул",
                    "dornod": "Дорнод",
                    "dornogovi": "Дорноговь",
                    "dundgovi": "Дундговь",
                    "govi_altai": "Говь-Алтай",
                    "govisumber": "Говьсүмбэр",
                    "khentii": "Хэнтий",
                    "khovd": "Ховд",
                    "khuvsgul": "Хөвсгөл",
                    "orkhon": "Орхон",
                    "selenge": "Сэлэнгэ",
                    "sukhbaatar": "Сүхбаатар",
                    "tuv": "Төв",
                    "umnugovi": "Өмнөговь",
                    "uvs": "Увс",
                    "uvurkhangai": "Өвөрхангай",
                    "zavkhan": "Завхан",
                }
            },
            "user": {
                "sap": "sap",
                "last_name": "Овог",
                "first_name": "Нэр",
                "role": "Албан тушаал",
                "roster": "Түвшин",
                "certificate": "Сертификат",
                "avatar": "Нүүр зураг",
                "password": "Нууц үг",
                "phones": "Утасны дугаар",
                "email": "Имэйл хаяг",
                "expat": "Гадаад иргэн"
            },
            "certificate": {
                "Advanced": "Ахисан шатны",
                "Intermidate": "Дунд шатны",
                "Basic": "Анхан шатны",
                "Other": "Бусад",
                "cert1": "OT Working at Heights",
                "cert2": "Scaffolding GASI",
                "cert3": "SG-4 Procedure Training",
                "cert4": "OT Basic Scaffolding",
                "cert5": "OT Intermediate Scaffolding",
                "cert6": "OT Advanced Scaffolding",
                "yes": "Авсан",
                "no": "Аваагүй",
            },
            "warehouse": {
                "title": "Агуулахын жагсаалт",
                "new_warehouse": "Шинэ агуулах",
                "configure_inventory": "Үлдэгдэл тохируулах",
            },
            "action": {
                "title": "Үйлдэл",
                "edit": "Засах",
                "delete": "Устгах",
                "certificates": "Сертификат",
                "back": "Буцах",
                "save": "Хадгалах",
                "password": "Шинэ нууц үг оруулах",
                "transfer": "Шилжүүлэх",
                "send": "Илгээх",
            },
            "delete": "Та устгахдаа итгэлтэй байна уу?",
        }
    }
}

export default resources