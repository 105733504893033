import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  Button,
  MenuItem,
  Fab,
  Link
} from "@material-ui/core";
import { Box, Text } from 'gestalt'
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";
import logo from "../../pages/login/jolooch.png";
// components
import { Badge, Typography } from "../Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  var classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"))
  // console.log(user)
  const { t, i18n } = useTranslation();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [isSearchOpen, setSearchOpen] = useState(false);

  const onLogout = () => {
    signOut(userDispatch, props.history)
  }

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale)
    localStorage.setItem("locale", locale)
  }

  return (
    <AppBar color="secondary" position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Box display="flex" alignItems="center">
          <Box paddingX={2} paddingY={1} color="light" rounding={2}>
            <img 
              src={logo}
              style={{
                width: Math.ceil(24 * 2.016260162601626),
                height: 24
              }}
            />
          </Box>
        </Box>
        <div className={classes.grow} />
        <Box display="flex" alignItems="center">
          {
            user && (
              <Box paddingX={4} display="flex">
                <AccountIcon />
                <Box width={4} />
                <Text color='light' size='300'>[{user.sap}] {user.first_name}</Text>
              </Box>
            )
          }
          
          <Button variant="contained" color="secondary" onClick={onLogout}>
            {t('auth.logout')}
          </Button>
        </Box>
        
      </Toolbar>
    </AppBar>
  );
}
