import React, { useState, useEffect } from 'react'
import { Box, TapArea, Text, Mask } from 'gestalt'
import { getDrivers } from '../dashboard/components/OrderDashboardApi'
import { _getProcessTitle, _getProcessColor } from '../../utils/helpers'
import { WifiOff, Wifi } from 'react-feather'
import moment from 'moment'

const DriversStatus = (props) => {
    // const [fetching, setFetching] = React.useState(false)
    // const [drivers, setDrivers] = React.useState([])
    const { drivers } = props

    // useEffect(() => {
    //     setInterval(() => {
    //         fetchDrivers()
    //     }, 5000)
    // }, [])

    // useEffect(() => {
    //     fetchDrivers()
    // }, [])

    // const fetchDrivers = () => {
    //     setFetching(true)
    //     getDrivers()
    //     .then(res => {
    //         if(res.data.code == 0) {
    //             setDrivers(res.data.drivers)
    //         }
    //     })
    //     .catch(err => alert(err))
    //     .then(() => setFetching(false))
    // }

    const onSelectDriver = (driver) => {
        // if(driver.last_geolocation && driver.last_geolocation.coords)
        //     props.onPinTo(driver.last_geolocation.coords)

        props.onSelectDriver(driver)
    }

    // console.log(drivers)

    return (
        <div style={{
            position: 'absolute',
            top: 60,
            right: 0,
            // display: 'flex'
        }}>
            <Box overflow="scrollY" display='flex' wrap width={240}>
                {
                    drivers.filter((current) => current.driver.roles[0].title != 'Түгээгч').map((current, index) => (
                        <Box display='flex' key={index} paddingX={2} width={100}>
                            <Box display='flex' justifyContent='center' alignItems='center' marginEnd={1}>
                                {
                                    (current.driver.last_geolocation && current.driver.last_geolocation.coords) && Math.abs(moment(current.driver.last_geolocation.timestamp).diff(moment(), 'minutes')) < 20 ? (
                                        <Wifi 
                                            size={18}
                                            color="green"
                                        />
                                    ) : (
                                        <WifiOff 
                                            size={18}
                                            color="orange"
                                        />
                                    )
                                }
                            </Box>
                            <TapArea tapStyle="compress" key={index} fullWidth onTap={() => onSelectDriver(current)}>
                                <div style={{
                                    background: current.order ? _getProcessColor(current.order.current_process.name) : (
                                        current.cab ? "#fff" : (current.pickup ? "#008753" : "#FFCC00")
                                    ),
                                    borderRadius: 12,
                                }}>
                                    <Box display='flex' alignItemds='center' paddingX={2} marginBottom={2} justifyContent='center' borderStyle="lg" rounding={3}>
                                        <Text inline align='center' color={current.order || (!current.cab) ? "light" : "dark"}><Text inline weight='bold' align='center' color={current.order || (!current.cab) ? "light" : "dark"}>{current.driver.sap}</Text></Text>
                                    </Box>
                                </div>
                            </TapArea>
                        </Box>
                    ))
                }

                {
                    drivers.filter((current) => current.driver.roles[0].title == 'Түгээгч').map((current, index) => (
                        <Box display='flex' key={index} paddingX={2} width={100}>
                            <Box display='flex' justifyContent='center' alignItems='center' marginEnd={1}>
                                {
                                    (current.driver.last_geolocation && current.driver.last_geolocation.coords) ? (
                                        <Wifi 
                                            size={18}
                                            color="green"
                                        />
                                    ) : (
                                        <WifiOff 
                                            size={18}
                                            color="orange"
                                        />
                                    )
                                }
                            </Box>
                            <TapArea tapStyle="compress" key={index} fullWidth onTap={() => onSelectDriver(current)}>
                                <div style={{
                                    background: "#fff",
                                    borderRadius: 12,
                                }}>
                                    <Box display='flex' alignItemds='center' paddingX={2} marginBottom={2} justifyContent='center' borderStyle="lg" rounding={3}>
                                        <Text inline align='center'><Text inline weight='bold' align='center'>{current.driver.sap}</Text></Text>
                                    </Box>
                                </div>
                            </TapArea>
                        </Box>
                    ))
                }
            </Box>
        </div>
    )
}

export default DriversStatus