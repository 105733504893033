import ReactS3Client from 'react-aws-s3';

const config = {
    bucketName: 'geru-mn',
    region: 'ap-southeast-1',
    accessKeyId: 'AKIA6CN3AAEROG5Y4MXU',
    secretAccessKey: 'OGnBjHscSY7LYzfqk/bTWrl8RnrfMXMAviSSHzT7',
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

/* This is optional */
const S3_URL = 'geru-mn.s3-ap-southeast-1.amazonaws.com/'
const S3_PREV_URL = 'geru-mn.s3-ap-southeast-1.amazonaws.com/'
export const S3_CLOUD_FRONT = 'https://d110hwq6svvzha.cloudfront.net/'
export const S3_RESIZE_ENDPOINT = 'https://d2eij6jgzhr4c0.cloudfront.net/'

export function cloudFront(url) {
	if(!url || (typeof url) != 'string') return

	var S3_MATCHING_URL = S3_URL
	if(!url.split(S3_URL)[1]) {
		if(!url.split(S3_PREV_URL)[1]) {
			return url
		}
		S3_MATCHING_URL = S3_PREV_URL
	}

	const imageUrl = url.split(S3_MATCHING_URL)[1]

	return String(S3_CLOUD_FRONT + imageUrl)
}

export function resizeSrc({ url, width, height }) {
    let prefix = url.split(S3_CLOUD_FRONT)
    let key = prefix.length > 1 ? prefix[1] : ""

    const json = {
        "bucket": "geru-mn",
        "key": key,
        "edits": {
          "resize": {
            "height": height,
            "fit": "cover"
          },
          "smartCrop": {}
        }
    }

    const str = JSON.stringify(json);
    const enc = btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }));

    const src = S3_RESIZE_ENDPOINT + enc
    return src
}

export function request(params) {
    const instance = new ReactS3Client(Object.assign(config, params));
    return instance
    // ReactS3Client
    // .uploadFile(file, newFileName)
    // .then(data => console.log(data))
    // .catch(err => console.error(err))
} 