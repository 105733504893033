import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    IconButton,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    TextField,
    Button
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'
import { getCompanies } from "../CompanyApi"
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle"
import Widget from "../../../components/Widget/Widget"
import { useTranslation } from "react-i18next"
import { deleteCustomer } from '../../../utils/RestApi'
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import { toast } from 'react-toastify'

const CustomerList = (props) => {
    const { t } = useTranslation();
    const [customers, setCustomers] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [search_value, setSearchValue] = useState('');
    const [page, setPage] = useState(1)
    const history = useHistory();
    const { btnColor = "secondary" } = props
    const locale = localStorage.getItem('locale')
    
    useEffect(() => {
        fetchCustomers()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchCustomers()
        setPage(1)   
    }, [search_value])

    const fetchCustomers = () => {
        setFetching(true)
        getCompanies({
            page,
            search_value
        }).then((res) => {
            if(res.data.code == 0) {
                setCustomers(res.data.company.docs)
                setPages(res.data.company.pages)
            }
        }).catch((err) => alert(err)).then(() => setFetching(false))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateCustomer = () => {
        history.push('/app/customer/create')
    }

    const onEdit = (id) => {
        history.push(`/app/company/${id}`)
    }

    const onDelete = (id) => {
        const is_confirm = window.confirm(t('delete'))

        if(is_confirm){
            deleteCustomer(id)
            .then(res => {
                if(res.data.code == 0) {
                    toast.success(t("message.success.deleted"))
                    fetchCustomers()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        }
    }

    const rView = () => {
        return (
            <div>
                <Button variant="contained" color={btnColor} size='large' onClick={() => history.push('/app/customer/createCompany')}>
                    {t("registration.customer.new_company")}
                </Button>
            </div>
        )
    }

    return (
        <div>
            <PageTitle 
                title={t("registration.company.title")}
                // button={t("registration.customer.new_customer")}
                // onButtonClick={onCreateCustomer}  
                rightView={() => rView()}
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                    id="outlined-basic"
                    label={t("registration.company.search")}
                    variant="outlined"
                    value={search_value}
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                />
            </div>
            <Widget
                title=""
                //upperTitle
                noBodyPadding
                //bodyClass={classes.tableWidget}
            >
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('registration.customer.list.headers.company')}</TableCell>
                            <TableCell>{t('registration.company.list.headers.registry')}</TableCell>
                            <TableCell align='center' colSpan={2}>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            customers.map((customer, index) => (
                                <TableRow key={index} hover>
                                    <TableCell className="pl-3 fw-normal">{index + 1 + ((page - 1) * 10)}</TableCell>
                                    {/* <TableCell className="pl-3 fw-normal">{customer.company[`name_${locale}`]}</TableCell> */}
                                    <TableCell className="pl-3 fw-normal">{customer[`name_${locale}`]}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{customer.registration_id}</TableCell>
                                    <TableCell align='right'>
                                        <IconButton onClick={() => onEdit(customer._id)}>
                                            <EditIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                    {/* <TableCell align='left'>
                                        <IconButton onClick={() => onDelete(customer._id)}>
                                            <TrashIcon color='red' />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    fetching && (
                        <Grid container justify='center'>
                            <CircularProgress />
                        </Grid>
                    )
                }
                <div style={{ paddingTop: 20, paddingBottom: 20}}>
                    <Grid container alignItems='center' justify='center'>
                        <Pagination 
                            count={pages}
                            color='primary'
                            onChange={onPagingChange}
                            />
                    </Grid>
                </div>
            </Widget>
        </div>
    )
}

export default CustomerList