import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import AddPhone from "../../components/AddPhone/AddPhone"
import { useTranslation } from "react-i18next"
import { Grid, Paper, Button, TextField, CircularProgress, Chip, FormControl, InputLabel, Select, MenuItem, Input } from  '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { saveEmployee } from '../../utils/RestApi'
import { getConfigs } from './ConfigApi'
import Autocomplete from '@material-ui/lab/Autocomplete';
//import Toas
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateEmployee = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory()
    const [addRoster, setAddRoster] = React.useState("");
    const [addExpat, setAddExpat] = React.useState(false);
    const [addSap, setAddSap] = React.useState("");
    const [addFirstName, setAddFirstName] = React.useState("");
    const [addLastName, setAddLastName] = React.useState("");
    const [registry_number, setRegistryNumber] = React.useState("");
    // const [dri]

    const [addEmail, setAddEmail] = React.useState("");
    const [dob, setDob] = useState("")
    const [driver_licence_date, setDriverLicenceDate] = useState(null)

    const [address, setAddress] = useState("")
    
    const [relationship_status, setRelationshipStatus] = useState("")
    const [manual_wheel_option, setManualWheelOption] = useState(false) 
    
    const [driver_type, setDriverType] = useState("B")

    //const [avatar, setAvatar] = React.useState("")
    const [addPassword, setAddPassword] = React.useState("");
    const [addRoles, setAddRoles] = React.useState([]);
    const [fetching, setFetching] = React.useState(false)
    const [phones, setPhones] = React.useState([""]);
    const [roles, setRoles] = React.useState([]);
    const [rosters, setRosters] = React.useState([]);

    useEffect(() => {
        fetchDatas()
    }, [])

    const fetchDatas = () => {
        setRoles([{
            title: "Цагийн ажилтан"
        }, {
            title: "Түгээгч"
        }, {
            title: "Жолооч"
        }, {
            title: "Ээлжийн ахлагч"
        }, {
            title: "Оператор"
        }])

        // getConfigs({})
        // .then((res) => {
        //     if(res.data.code == 0) {
        //         res.data.config.map((config, index) => {
        //             if(config.field === 'Role')
        //                 setRoles(config.value)

        //             if(config.field === 'Roster')
        //                 setRosters(config.value)
        //         })
        //     }
        // })
        // .catch((err) => console.log(err))
    } 

    const phoneRemove = index => {
        const list = [...phones];
        list.splice(index, 1);
        setPhones(list);
      };
     
    const phoneAdd = () => {
        setPhones([...phones, ""]);
    };

    const getPhones = (e, i) => {
        let newPhones = [...phones]

        newPhones[i] = e.target.value
        setPhones(newPhones)
    }

    const onBack  = () =>  history.push('/app/employee');
    
    const onSaveEmployee = () => {
        setFetching(true)

        if(!phones) {
            toast.warning(`${t('user.phones')} is empty`)
            return setFetching(false)
        }
        
        if(!addSap) {
            toast.warning(`${t('user.sap')} is empty`)
            return setFetching(false)            
        } 
        
        if(!addFirstName) {
            toast.warning(`${t('user.first_name')} is empty`)
            return setFetching(false)            
        } 
        
        if(!addLastName) {
            toast.warning(`${t('user.last_name')} is empty`)
            return setFetching(false)            
        } 
        
        if(!driver_licence_date) {
            toast.warning("Жолооч болсон огноо оруулна уу")
            return setFetching(false)            
        } 
        
        if(!addPassword) {
            toast.warning(`${t('user.password')} is empty`)
            return setFetching(false)            
        } 
        
        if(!addRoles) {
            toast.warning(`${t('user.role')} is empty`)
            return setFetching(false)            
        }

        saveEmployee({
            phones: phones.filter((phone) => phone),
            sap: addSap,
            registry_number,
            first_name: addFirstName,
            last_name: addLastName,
            manual_wheel_option,
            auto_wheel_option: true,
            driver_licence_date,
            driver_point: 10,
            driver_type,
            email: addEmail,
            password: addPassword,
            roles: addRoles.map((title) => {
                return {
                    title
                }
            }),
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
                return
            }
            
            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.push('/app/employee')
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.employee.create_new.title")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addSap} onChange={(e) => setAddSap(e.target.value)} label="Дугаар" variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addLastName} onChange={(e) => setAddLastName(e.target.value)} label={t('user.last_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addFirstName} onChange={(e) => setAddFirstName(e.target.value)} label={t('user.first_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={registry_number} onChange={(e) => setRegistryNumber(e.target.value)} label="Регистрийн дугаар" variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DatePicker 
                            fullWidth 
                            label={'Жолооч болсон жил сонгох'} 
                            openTo="year" 
                            value={driver_licence_date}
                            onChange={(value) => {
                                setDriverLicenceDate(value)
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            {/* <InputLabel htmlFor="#">Жолоочын ангилал</InputLabel> */}
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={["B", "C", "D"]}
                                onChange={(e, roles) => {
                                    setDriverType(String(roles))
                                    // setAddRoles(roles.map((role, i) => role.title)) 
                                }}
                                getOptionLabel={(roles) => roles}
                                value={driver_type.split(",")}
                                filterSelectedOptions
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Жолоочын ангилал"
                                />
                                )}
                            />
                            {/* <InputLabel htmlFor="#">Жолоочын ангилал</InputLabel>
                                <Select
                                    native
                                    value={driver_type}
                                    onChange={(e) => setDriverType(e.target.value)}
                                    label={t("user.roster")}
                                    inputProps={{
                                        name: 'roster',
                                    }}
                                >
                                {
                                    ["B", "C", "D"].map((roster, index) => (
                                        <option value={roster} key={index}>{roster}</option>
                                    ))
                                }
                            </Select> */}
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">Механик машин барих эсэх</InputLabel>
                                <Select
                                    native
                                    value={manual_wheel_option}
                                    onChange={(e) => setManualWheelOption(e.target.value)}
                                    label="Механик машин барих эсэх"
                                    inputProps={{
                                        name: 'roster',
                                    }}
                                >
                                {
                                    [{
                                        label: "Үгүй",
                                        value: "Үгүй"
                                    },{
                                        label: "Тийм",
                                        value: true
                                    }].map((roster, index) => (
                                        <option value={roster.value} key={index}>{roster.label}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 20 }} />
                
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                    <FormControl fullWidth className={classes.formControl}>
                            {/* <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={addRoles}
                                onChange={(e) => setAddRoles(e.target.value)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {roles.map((name, i) => (
                                    <MenuItem key={name.title} value={name.title} style={getStyles(name.title, addRoles, theme)}>
                                        {name.title}
                                    </MenuItem>
                                ))}
                            </Select> */}
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={roles}
                                onChange={(e, roles) => {
                                    setAddRoles(roles.map((role, i) => role.title)) 
                                }}
                                getOptionLabel={(roles) => roles.title}
                                defaultValue={addRoles}
                                filterSelectedOptions
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={t("user.role")}
                                />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                {
                    phones.map((phone, i) => (
                        <AddPhone 
                            setPhone={getPhones}
                            datas={phones}
                            data={phone}
                            index={i}
                            onAdd={phoneAdd}
                            onRemove={phoneRemove}
                        />
                    ))
                }
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' onChange={(e) => setAddEmail(e.target.value)} value={addEmail} label={t('user.email')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-password-input' value={addPassword} onChange={(e) => setAddPassword(e.target.value)} autoComplete="current-password" type='password' label={t('user.password')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress /> //setEmployee //hooks onSaveEmployee
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveEmployee}>
                                        {t("registration.employee.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
        </LocalizationProvider>
    )
}

export default CreateEmployee