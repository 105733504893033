import React, { useEffect, useState } from 'react'
import { Box, Spinner } from 'gestalt'
import Chart, { Bar, Pie, Colors } from 'react-chartjs-2';
import { orderCancelChart } from '../../../utils/RestApi'

// Chart.register(Colors);

const OrderCancelChart = () => {
    const [result, setResult] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        getOrderCancelChart()
    }, [])

    const getOrderCancelChart = () => {
        setFetching(true)
        orderCancelChart()
        .then(res => {
            if(res.data.code == 0) {
                setResult(res.data.result)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    const pie_material = {
        data: {
            labels: result.map((current) => current._id),
            datasets: [
                {
                    data: result.map((current) => current.total),
                    backgroundColor: [
                        '#F96167',
                        '#FCE77D',
                        '#4831D4',
                        '#FF69B4',
                        '#00FFFF',
                        '#CBD18F,'
                    ],
                    borderWidth: 1,
                },
            ],
        },
        option: {
            plugins: {
                colors: {
                    enabled: true
                },
                legend: {
                    display: true,
                },
                title: {
                    display: true,
                    // text: `${t("dashboard.main.chart_2.title")} - ${orderMaterials + allMaterials}`
                },
            },
        },
    }

    return (
        <Box>
            <Spinner show={fetching} />
            <Pie data={pie_material.data} options={pie_material.option} />
        </Box>
    )
}

export default OrderCancelChart