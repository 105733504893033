import React, { useEffect, useState } from 'react'
import { Box, Text } from 'gestalt'
import moment from 'moment'

const InComingCallsLogs = () => {
    const [logs, setLogs] = useState([])

    useEffect(() => {
        setLogs([{
            phone: '99222503',
            created_at: moment(),
            status: 'abondaned'
        }, {
            phone: '88998894',
            created_at: moment().subtract(2, 'minutes'),
            status: 'answered'
        }, {
            phone: '99119511',
            created_at: moment(),
            created_at: moment().subtract(10, 'minutes'),
            status: 'answered'
        }])
    }, [])

    return (
        <Box>
            <Box>
                {
                    logs.map((log, index) => (
                        <Box display='flex' justifyContent='between' key={index}>
                            <Text size='400' color={log.status == 'answered' ? "success" : "error"}>{log.phone}</Text>

                            <Text>{moment(log.created_at).format("HH:mm")}</Text>
                        </Box>
                    ))
                }
                
            </Box>
        </Box>
    )
}

export default InComingCallsLogs