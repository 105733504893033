import React from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"

import { Grid, Paper, Button, TextField} from  '@material-ui/core';
import { Star as StarIcon } from "react-feather"

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    input: {
        display: 'none',
      },
}));


const CreateOrder = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();

    const onCreateOrder  = () =>  props.history.push('/app/order/create');

      return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("order.detail.feedback.title")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <StarIcon />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("order.detail.feedback.text")}
                            type="text"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onCreateOrder}>
                                {t("action.back")}  
                            </Button>
                            <Button variant="contained" color="primary" onClick={onCreateOrder}>
                            {t("project.create.title")}   
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateOrder