import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { getCategories } from "./CategoryApi"
import { saveResource } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, FormControl, CircularProgress, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const units = ['kg', 'gram', 'tonne'];

const CreateResource = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const locale = localStorage.getItem('locale')
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [nameMn, setNameMn] = useState('')
    const [unit, setUnit] = useState('')
    const [materialId, setMaterialId] = useState('')
    const [quantity, setQuantity] = useState('')
    const [status, setStatus] = useState('')
    const [weight, setWeight] = useState('')
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        fetchCategories()
    }, [])

    const fetchCategories = () => {
        getCategories({})
        .then((res) => {
            if (res.data.code == 0) {
                setCategories(res.data.category.docs)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onSaveResource = () => {
        setFetching(true)

        if(!materialId) {
            toast.warning(`${t('registration.resource.create_new.material_id')} is empty`)
            return setFetching(false)
        } 
        
        if(!nameEn) {
            toast.warning(`${t('registration.resource.create_new.name_en')} is empty`)
            return setFetching(false)
        } 
        
        if(!nameMn) {
            toast.warning(`${t('registration.resource.create_new.name_mn')} is empty`)
            return setFetching(false)
        } 
        
        if(!unit) {
            toast.warning(`${t('registration.resource.create_new.unit')} is empty`)
            return setFetching(false)
        } 
        
        if(!quantity) {
            toast.warning(`${t('registration.resource.create_new.quantity')} is empty`)
            return setFetching(false)
        } 
        
        if(!status) {
            toast.warning(`${t('registration.resource.create_new.status')} is empty`)
            return setFetching(false)
        } 
        
        if(!weight) {
            toast.warning(`${t('registration.resource.create_new.weight')} is empty`)
            return setFetching(false)
        } 
        
        if(!category) {
            toast.warning(`${t('registration.resource.create_new.category')} is empty`)
            return setFetching(false)
        }

        saveResource({
            material_id: materialId,
            name_en: nameEn,
            name_mn: nameMn,
            unit,
            quantity,
            status,
            weight,
            category
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
            }

            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                props.history.goBack()
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }
      
    const onBack  = () =>  props.history.goBack()

    return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.resource.create_new.title")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.resource.create_new.category")}</InputLabel>
                                <Select
                                    native
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    label={t("registration.resource.create_new.category")}
                                    inputProps={{
                                        name: 'category',
                                    }}
                                >
                            <option aria-label="None" value="" />
                            {
                                categories.map((category, index) => (
                                    <option key={index} value={category._id}>{category[`name_${locale}`]}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                <div style={{ height: 20 }} />

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={materialId} onChange={(e) => setMaterialId(e.target.value)} label={t('registration.resource.create_new.material_id')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={nameEn} onChange={(e) => setNameEn(e.target.value)} label={t('registration.resource.create_new.name_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={nameMn} onChange={(e) => setNameMn(e.target.value)} label={t('registration.resource.create_new.name_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} label={t('registration.resource.create_new.quantity')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.resource.create_new.status")}</InputLabel>
                            <Select
                            native
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            label={t("registration.resource.create_new.status")}
                            inputProps={{
                                name: 'status',
                            }}
                            >
                            <option aria-label="None" value="" />
                            <option value="Active">{t("registration.resource.unit.active")}</option>
                            <option value="Inactive">{t("registration.resource.unit.inactive")}</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={10} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' type="number" value={weight} onChange={(e) => setWeight(e.target.value)} label={t('registration.resource.create_new.weight')} variant="outlined" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.resource.create_new.unit")}</InputLabel>
                            <Select
                            native
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            label={t("registration.resource.create_new.unit")}
                            inputProps={{
                                name: 'unit',
                            }}
                            >
                            <option aria-label="None" value="" />
                            {
                                units.map((unit, index) => (
                                    <option value={unit}>{unit}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ height: 20 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveResource}>
                                        {t("registration.resource.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateResource