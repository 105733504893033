import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    CircularProgress,
    Button,
    IconButton,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'
import { getResources } from "../ResourceApi"

import PageTitle from "../../../components/PageTitle/PageTitle"
import Widget from "../../../components/Widget/Widget"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { deleteResource } from '../../../utils/RestApi';
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import { toast } from 'react-toastify'

const ResourceList = (props) => {
    const { t } = useTranslation();
    const [resources, setResources] = useState([])
    const locale = localStorage.getItem('locale')
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const [search_value, setSearchValue] = useState('');
    const history = useHistory();
    const { btnColor = "secondary" } = props

    useEffect(() => {
        fetchResources()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchResources()
        setPage(1)
    }, [search_value])

    const fetchResources = () => {
        setFetching(true)
        getResources({
            page,
            search_value
        }).then((res) => {
            if(res.data.code == 0) {
                setResources(res.data.resource.docs)
                setPages(res.data.resource.pages)
            }
        }).catch((err) => alert(err)).then(() => setFetching(false))
    }

    const onCategory = () => history.push('/app/resource/createCategory');
    const onMaterial = () => history.push('/app/resource/create');

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const rView = () => {
        return (
            <div>
                <Button variant="contained" color={btnColor} style={{marginRight: 10}} size='large' onClick={onMaterial}>
                    {t("registration.resource.new_resource")}
                </Button>
                <Button variant="contained" color={btnColor} size='large' onClick={onCategory}>
                    {t("registration.resource.new_group")}
                </Button>
            </div>
        )
    }

    const onEdit = (id) => {
        history.push(`/app/resource/${id}`)
    }

    const onDelete = (id) => {
        const is_confirm = window.confirm(t('delete'))

        if(is_confirm){
            deleteResource(id)
            .then(res => {
                if(res.data.code == 0) {
                    toast.success(t("message.success.deleted"))
                    fetchResources()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        }
    }

    return (
        <div>
            <PageTitle 
                title={t("registration.resource.title")}
                rightView={() => rView()}
                // button={t("registration.resource.new_resource")}
                //onButtonClick={onCreateNewOrder}  
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                id="outlined-basic"
                label={t("registration.resource.search")}
                variant="outlined"
                value={search_value}
                onChange={(e) => setSearchValue(e.target.value)}
                fullWidth
                />
            </div>
            <Widget
                title=""
                //upperTitle
                noBodyPadding
                //bodyClass={classes.tableWidget}
            >
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.material_id')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.material_name')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.quantity')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.unit')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.weight')}</TableCell>
                            <TableCell>{t('registration.resource.list.headers.category')}</TableCell>
                            <TableCell align='center' colSpan={2}>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            resources.map((resource, index) => (
                                <TableRow key={index} hover>
                                    <TableCell className="pl-3 fw-normal">{index+1}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{resource.material_id}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{resource[`name_${locale}`]}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{resource.quantity}</TableCell>
                                    <TableCell>{resource.unit}</TableCell>
                                    <TableCell>{resource.weight}</TableCell>
                                    <TableCell>{resource.category ? resource.category[`name_${locale}`] : "-"}</TableCell>
                                    <TableCell align='right'>
                                        <IconButton onClick={() => onEdit(resource._id)}>
                                            <EditIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='left'>
                                        <IconButton onClick={() => onDelete(resource._id)}>
                                            <TrashIcon color='red' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    fetching && (
                        <Grid container justify='center'>
                            <CircularProgress />
                        </Grid>
                    )
                }
                <div style={{ paddingTop: 20, paddingBottom: 20}}>
                    <Grid container alignItems='center' justify='center'>
                        <Pagination 
                            count={pages}
                            color='primary'
                            onChange={onPagingChange}
                            />
                    </Grid>
                </div>
            </Widget>
        </div>
    )
}

export default ResourceList