import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Box, Text, TapArea } from 'gestalt'
import { _getProcessTitle, _getProcessColor } from '../../utils/helpers'

import InComingCalls from './InComingCalls'

const OrderStatusCount = (props) => {
    const { orders, drivers } = props
    const [filters, setFilters] = useState([{
        name: 'created',
        total: 0,
      }, {
        name: 'assigned',
        total: 0,
      }, {
        name: 'ready_to_drive',
        total: 0,
      }, {
        name: 'in_driving',
        total: 0,
      }, {
        name: 'ready_to_drop',
        total: 0,
    }])

    const [in_cub_drivers, setInCubDrivers] = useState(0)
    const [ready_to_pickup_drivers, setReadyToPickupDrivers] = useState(0)

    useEffect(() => {
        let tempFilters = _.groupBy(orders, (order) => order.current_process.name)
        let newFilters = [...filters]

        newFilters.forEach((filter) => { filter.total = 0 })

        for (var prop in tempFilters) {
            if (Object.prototype.hasOwnProperty.call(tempFilters, prop)) {
                const index = filters.findIndex((current) => current.name == prop)
                if(index != -1) newFilters[index].total = tempFilters[prop].length
            }
        }

        setFilters(newFilters)
    }, [orders])

    useEffect(() => {
        let in_cub_drivers = drivers.filter((current) => current.driver.roles[0].title != 'Түгээгч').filter((current) => current.cab).length
        setInCubDrivers(in_cub_drivers)

        let ready_to_pickup_drivers = drivers.filter((current) => current.driver.roles[0].title != 'Түгээгч').filter((current) => {
            return current.cab == null && current.order == null && current.pickup == null
        }).length

        setReadyToPickupDrivers(ready_to_pickup_drivers)
}, [drivers])

    return (
        <div style={{
            position: 'absolute',
            top: 15,
            // left: 15 + 170,
            left: 15,
            display: 'flex',
            // flexDirection: 'column'
        }}>
            {/* <Box display='flex' color="light" paddingX={4} paddingY={2} borderStyle='lg' marginEnd={4}>
                <TapArea onTap={props.onSeeLogs}> 
                    <Box paddingX={2} display='flex' direction='column' alignItems='center'>
                        <Text size='100'>Callpro-дуудлага</Text>

                        <Box display='flex' alignItems='center'>
                            <span style={{
                                // color: _getProcessColor(filter.name),
                                fontSize: 24,
                                fontWeight: 'bold',
                            }}>
                                20/
                            </span>
                            <span style={{
                                color: 'red',
                                fontSize: 24,
                                fontWeight: 'bold',
                            }}>
                                2
                            </span>
                        </Box>
                    </Box>
                </TapArea>
            </Box> */}

            <Box display='flex' color="light" paddingX={4} paddingY={2} borderStyle='lg'>
                {
                    filters.map((filter, index) => (
                        <Box key={index} paddingX={2} display='flex' direction='column' alignItems='center'>
                            <Text size='100'>{_getProcessTitle(filter.name)}</Text>

                            <span style={{
                                color: _getProcessColor(filter.name),
                                fontSize: 24,
                                fontWeight: 'bold',
                            }}>
                                {filter.total}
                            </span>
                        </Box>
                    ))
                }
            </Box>

            <Box width={12} />

            <TapArea tapStyle="compress" fullWidth={false}>
                <Box color='light' paddingX={4} paddingY={2} borderStyle='lg'>
                    <Box paddingX={2} display='flex' direction='column' alignItems='center'>
                        {/* <Text size='100'>{_getProcessTitle(filter.name)}</Text> */}
                        <Text size='100'>Сул жолооч</Text>

                        <span style={{
                            // color: _getProcessColor(filter.name),
                            fontSize: 24,
                            fontWeight: 'bold',
                        }}>
                            {in_cub_drivers}
                        </span>
                    </Box>
                </Box>
            </TapArea>

            <Box width={12} />

            <TapArea tapStyle="compress" fullWidth={false}>
                <Box color='light' paddingX={4} paddingY={2} borderStyle='lg'>
                    <Box paddingX={2} display='flex' direction='column' alignItems='center'>
                        {/* <Text size='100'>{_getProcessTitle(filter.name)}</Text> */}
                        <Text size='100'>Түгээлт хувиарлах</Text>

                        <span style={{
                            color: "#FFCC00", // _getProcessColor(filter.name),
                            fontSize: 24,
                            fontWeight: 'bold',
                            WebkitTextStrokeWidth: 1,
                            WebkitTextStrokeColor: "rgba(0, 0, 0, 0.2)"
                        }}>
                            {ready_to_pickup_drivers}
                        </span>
                    </Box>
                </Box>
            </TapArea>
        </div>
    )
}

export default OrderStatusCount