import { request } from '../../../utils/api'

export function getActiveUsers(params) {
    let token = localStorage.getItem("token")
    return request(token).get('user/active', {
        params
    })
}

export function getMaterialData() {
    return request().get('order/material-dashboard')
}

export function getWarehouseInventories() {
    return request().get('warehouse/warehouse_inventory-dashboard')
}

export function getUserDistributions() {
    return request().get('user-distribution/summary')
}

export function getConfig() {
    return request().get(`config`)
}

export function updateConfig(config_id, params) {
    return request().put(`config/${config_id}`, params)
}