import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/PageTitle'

import {
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Button,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Paper,
    TextField,
    CircularProgress
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import { getLocationsWithWarehouse, getWarehouseInventory, getLocationInventory, warehouseResourceMovement, locationResourceMovement } from '../../utils/RestApi'

import {
    ChevronsRight as ChevronRightIcon,
    Home as HomeIcon,
    MapPin as MapPinIcon
} from 'react-feather'

import _isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'

const InventoryMovement = (props) => {
    const { t } = useTranslation()
    const locale = localStorage.getItem('locale')

    const [from_location, setFromLocation] = useState({})
    const [to_location, setToLocation] = useState({})

    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [resources, setResources] = useState([])
    const changedResources = resources.filter((cur) => cur.exchange_quantity > 0)

    const [locations, setLocations] = useState([])

    useEffect(() => {
        fetchLocations()
    }, [])

    useEffect(() => {
        fetchInventory()
    }, [from_location])

    const onBack = () => {
        props.history.goBack()
    }

    const onInventoryMovement = () => {
        if(_isEmpty(to_location)) {
            return alert(t("message.warning.to_location"))
        }

        let inventories = changedResources.map((resource) => {
            return {
                resource_id: resource._id,
                in_stock_quantity: resource.in_stock_quantity,
                exchange_quantity: resource.exchange_quantity
            }
        })

        if(from_location.type == 'location') {
            setSaving(true)
            locationResourceMovement(from_location._id, {
                inventories,
                to_location
            })
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                if(res.data.code == 0) {
                    toast.success(t("message.success.saved"))
                    props.history.push('/app/inventory');
                }
            })
            .catch((err) => alert(err))
            .then(() => setSaving(false))
        } else {
            //warehouse
            setSaving(true)
            warehouseResourceMovement(from_location._id, {
                inventories,
                to_location
            })
            .then((res) => {
                if(res.data.code != 0) {
                    return alert(res.data.errors[0].msg)
                }

                if(res.data.code == 0) {
                    toast.success(t("message.success.saved"))
                    props.history.push('/app/inventory');
                }
            })
            .catch((err) => alert(err))
            .then(() => setSaving(false))
        }
    }

    const onChangeQuantity = (e, index) => {
        let newResources = [...resources]
        newResources[index].exchange_quantity = e.target.value
        setResources(newResources)
    } 

    const fetchInventory = () => {
        if(_isEmpty(from_location)) return

        if(from_location.type == 'location') {
            setResources([])
            setFetching(true)
            getLocationInventory(from_location._id)
            .then((res) => {
                if (res.data.code == 0) {
                    setResources(res.data.resources.map((cur) => {
                        return Object.assign(cur, {
                            exchange_quantity: 0
                        })
                    })) //users
                }
            })
            .catch((err) => alert(err))
            .then(() => setFetching(false))
        } else {
            //warehouse
            setFetching(true)
            getWarehouseInventory(from_location._id)
            .then((res) => {
                if (res.data.code == 0) {
                    setResources(res.data.resources.map((cur) => {
                        return Object.assign(cur, {
                            exchange_quantity: 0
                        })
                    })) //users
                }
            })
            .catch((err) => alert(err))
            .then(() => setFetching(false))
        }
    }

    const fetchLocations = () => {
        getLocationsWithWarehouse()
        .then(res => {
            if(res.data.code == 0) {
                //console.log(res.data.locations)
                //locations.push(})
                let locations = []

                locations = locations.concat(res.data.warehouses.map((warehouse) => {
                    return Object.assign(warehouse, {
                        type: 'warehouse'
                    })
                }))

                locations = locations.concat(res.data.locations.map((location) => {
                    return Object.assign(location, {
                        type: 'location',
                        name_en: location.location_en,
                        name_mn: location.location_mn,
                    })
                }))

                setLocations(locations)
            }
        })
        .catch(err => alert(err))
    }

    //const locations = []

    return (
        <div>
            <PageTitle
                title={t("inventory.movement")}
                // button={t("registration.warehouse.new_warehouse")}
                // onButtonClick={onNewWarehouse}  
            />
            
            <Grid container>
                <Grid item fullWidth xs={5}>
                    <Autocomplete
                        id="country-select-demo"
                        //style={{ width: 300 }}
                        options={locations}
                        //value={from_location}
                        onChange={(e, data) => {
                            setFromLocation(data)
                        }}
                        // classes={{
                        //     option: classes.option,
                        // }}
                        autoHighlight
                        autoComplete="off"
                        getOptionLabel={(option) => option[`name_${locale}`]}
                        renderOption={(option) => (
                            <React.Fragment>
                                {
                                    option.type == 'location' ? (
                                        <MapPinIcon size={14} color='#4f4f4f'/> 
                                    ) : <HomeIcon size={14} color='#4f4f4f' /> 
                                }
                                <span style={{ marginLeft: 5 }}>{option[`name_${locale}`]}</span>
                                <span style={{
                                    color: '#b5b5b5',
                                    marginLeft: 5,
                                }}>{option.company ? option.company[`name_${locale}`]  : "-"}</span>
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("inventory.from_location")}
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2} container justify='center' alignItems='center'>
                    <ChevronRightIcon 

                    />
                </Grid>
                <Grid item fullWidth xs={5}>
                    <Autocomplete
                        id="country-select-demo"
                        //style={{ width: 300 }}
                        options={_isEmpty(from_location) ? locations : locations.filter((cur) => cur._id != from_location._id)}
                        onChange={(e, data) => {
                            setToLocation(data)
                        }}
                        // classes={{
                        //     option: classes.option,
                        // }}
                        autoHighlight
                        autoComplete="off"
                        getOptionLabel={(option) => option[`name_${locale}`]}
                        renderOption={(option) => (
                            <React.Fragment>
                                {
                                    option.type == 'location' ? (
                                        <MapPinIcon size={14} color='#4f4f4f'/> 
                                    ) : <HomeIcon size={14} color='#4f4f4f' /> 
                                }
                                <span style={{ marginLeft: 5 }}>{option[`name_${locale}`]}</span>
                                <span style={{
                                    color: '#b5b5b5',
                                    marginLeft: 5,
                                }}>{option.company ? option.company[`name_${locale}`]  : "-"}</span>
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("inventory.to_location")}
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <div style={{ height: 30 }} />

            {
                fetching && (
                    <div style={{
                        marginBottom: 20
                    }}>
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    </div>
                )
            }

            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('naming.resource')}</TableCell>
                            <TableCell>{t('naming.quanity')}</TableCell>
                            <TableCell>{t('naming.quanity')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            resources.map((resource, index) => (
                                <TableRow hover>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{resource[`name_${locale}`]}</TableCell>
                                    <TableCell>
                                        {resource.in_stock_quantity}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            value={resource.exchange_quantity}
                                            onChange={(e) => onChangeQuantity(e, index)}
                                            helperText={resource.exchange_quantity > resource.in_stock_quantity ? "More than in stock quantity" : ""}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{ marginTop: 30 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching && (
                                    <Button variant="contained" color="primary" disabled>
                                        {t("action.transfer")}  
                                    </Button>
                                ) || (
                                    <Button variant="contained" disabled={changedResources.length == 0} color={changedResources.length > 0 ? "primary" : "secondary"} onClick={onInventoryMovement}>
                                        {t("action.transfer")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default InventoryMovement