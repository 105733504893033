import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Button,
    Chip,
    Paper,
    TableContainer
} from "@material-ui/core";
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from "react-router-dom";
import { DateRangePicker } from 'react-dates'
import { getInventoryLogs } from '../../utils/RestApi'
import _isEmpty from 'lodash/isEmpty'

import { 
    Trash as TrashIcon, 
    Edit as EditIcon, 
    List as ListIcon,
    Lock as LockIcon,
    MapPin as MapPinIcon,
    Eye as EyeIcon,
} from 'react-feather'
import { toast } from 'react-toastify'

const InventoryManagement = (props) => {
    const { t } = useTranslation();
    const [search_value, setSearchValue] = useState('');
    const [warehouses, setWarehouses] = useState([])

    const [inventory_logs, setInventoryLogs] = useState([])

    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)
    const locale = localStorage.getItem('locale')
    const history = useHistory();

    const [begin_at, setBeginAt] = useState(moment().subtract(7, 'days'))
    const [end_at, setEndAt] = useState(moment())
    const [is_focused, setIsFocused] = useState(false)

    useEffect(() => {
        //fetchTotalInventory()
        fetchInventoryLogs()
    }, [page])

    useEffect(() => {
        if(begin_at == null || end_at == null) return

        if(page == 1) {
            fetchInventoryLogs()
        } else {
            setPage(1)
        }
    }, [begin_at, end_at])

    const navigateToInventoryLog = (inventory) => history.push(`/app/inventory/${inventory._id}/logs`)

    const fetchInventoryLogs = () => {
        setFetching(true)
        getInventoryLogs({
            begin_at: moment(begin_at).format("YYYY-MM-DD"),
            end_at: moment(end_at).add(1, 'day').format("YYYY-MM-DD")
        })
        .then((res) => {
            if (res.data.code == 0) {
                //console.log(res.data)
                const {
                    docs,
                    pages
                } = res.data.result
                
                setInventoryLogs(docs)
                setPages(pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const fetchTotalInventory = () => {
        // let inventory = [{
        //     warehouse: {
        //         name_en: "Oyutolgoi",
        //         name_mn: "Оюу-Толгой"
        //     },
        //     total_locations: 800,
        //     total_resource_count: 1200,
        // }, {
        //     warehouse: {
        //         name_en: "JCI",
        //         name_mn: "ЖСАЙ"
        //     },
        //     total_locations: 100,
        //     total_resource_count: 700,
        // }]

        // setFetching(true)
        // getTotalInventory()
        // .then((res) => {
        //     if (res.data.code == 0) {
        //         setWarehouses(res.data.warehouses) //users
        //         // setPages(res.data.result.pages)
        //     }
        // })
        // .catch((err) => alert(err))
        // .then(() => setFetching(false))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onResourceMovement = () => {
        history.push('/app/inventory/movement')
    }

    const onWarehouseBalance = () => {
        history.push(`/app/warehouse/balance`)
    }

    const onSetInventory = (warehouse_id) => {
        history.push(`/app/warehouse/${warehouse_id}/inventory`)
    }

    const onNewWarehouse  = () =>  {
        history.push('/app/warehouse/create')
    }

    return (    
        <div>
            <PageTitle
                title={t("inventory.log")}
                // button={t("registration.warehouse.new_warehouse")}
                // onButtonClick={onNewWarehouse}  
            />

            <div style={{ marginBottom: 20 }}>
                <Grid container justify='space-between'>
                    <Grid item>
                        <DateRangePicker
                            startDate={begin_at} // momentPropTypes.momentObj or null,
                            endDate={end_at} // momentPropTypes.momentObj or null,
                            onDatesChange={({ startDate, endDate }) => {
                                setBeginAt(startDate)
                                setEndAt(endDate)
                            }} // PropTypes.func.isRequired,
                            //enableOutsideDays
                            numberOfMonths={2}
                            isOutsideRange={(day) => day.isAfter(moment()) || day.isBefore(moment().subtract(365, "days"))}
                            focusedInput={is_focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => setIsFocused(focusedInput)} // PropTypes.func.isRequired,
                            //initialVisibleMonth={() => moment().add(2, "M")} // PropTypes.func or null,
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={onResourceMovement}>
                                    {t("inventory.movement")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={onWarehouseBalance}>
                                    {t("inventory.balance")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t("inventory.from_location")}</TableCell>
                            <TableCell>{t("inventory.to_location")}</TableCell>
                            <TableCell>{t("naming.materials")}</TableCell>
                            <TableCell>{t('naming.quanity')}</TableCell>
                            <TableCell>{t('naming.state')}</TableCell>
                            <TableCell colSpan={2} align='center'>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            inventory_logs.map((inventory, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        {inventory.from_location[`name_${locale}`]} - {inventory.company[`name_${locale}`]}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            _isEmpty(inventory.to_location) ? "-" : (
                                                `${inventory.to_location[`name_${locale}`]} - ${inventory.company[`name_${locale}`]}`
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {inventory.total_resources_count} {t("naming.materials")}
                                    </TableCell>
                                    <TableCell>
                                        {inventory.total_quantity}
                                    </TableCell>
                                    <TableCell>
                                        <Chip 
                                            color={inventory.log_type == 'balance' ? 'primary' : 'secondary'} 
                                            label={t(`naming.${inventory.log_type}`)} 
                                            variant="outlined" 
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: 30 }}>
                                        <IconButton onClick={() => navigateToInventoryLog(inventory)}>
                                            <EyeIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))   
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
        </div>
    )
}

export default InventoryManagement