// const API_URL = 'http://localhost:3006/api/v1'
const API_URL = 'https://joloochbackend.herokuapp.com/api/v1'
const LOCAL_URL = 'https://picked-admin.herokuapp.com'
const AWS_KEY = 'AKIAX4CJY24S4S6JFFO4'
const AWS_SECRET = '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'
const AWS_BUCKET = 'picked-app'

export {
    API_URL,
    LOCAL_URL,
    AWS_SECRET,
    AWS_KEY,
    AWS_BUCKET
}