import React, { useState, useEffect } from 'react'

import {
    Grid,
    Table,
    TableRow,
    IconButton,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    TextField,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'
import { getEquipments } from "../EquipmentApi"
import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle"
import Widget from "../../../components/Widget/Widget"
import { useTranslation } from "react-i18next"
import { deleteEquipment } from '../../../utils/RestApi'
import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import { toast } from 'react-toastify'

const EquipmentList = () => {
    const { t } = useTranslation();
    const [equipments, setEquipments] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pages, setPages] = useState(1)
    const [search_value, setSearchValue] = useState('');
    const [page, setPage] = useState(1)
    const history = useHistory();
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    
    useEffect(() => {
        fetchEquipments()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchEquipments()
        setPage(1)   
    }, [search_value])

    const fetchEquipments = () => {
        setFetching(true)
        getEquipments({
            page,
            search_value
        }).then((res) => {
            if(res.data.code == 0) {
                setEquipments(res.data.equipment.docs)
                setPages(res.data.equipment.pages)
            }
        }).catch((err) => alert(err)).then(() => setFetching(false))
    }

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEquipment = () => {
        history.push('/app/equipment/create')
    }

    const onEdit = (id) => {
        history.push(`/app/equipment/${id}`)
    }

    const onDelete = (id) => {
        const is_confirm = window.confirm(t('delete'))

        if(is_confirm){
            deleteEquipment(id)
            .then(res => {
                if(res.data.code == 0) {
                    toast.success(t("message.success.deleted"))
                    fetchEquipments()
                }
            })
            .catch(err => alert(err))
            .then(() => setFetching(false))
        }
    }

    return (
        <div>
            <PageTitle 
                title={t("registration.equipment.title")}
                button={t("registration.equipment.new_equipment")}
                onButtonClick={onCreateNewEquipment}  
            />
            <div style={{ marginBottom: 20 }}>
                <TextField
                id="outlined-basic"
                label={t("registration.equipment.search")}
                variant="outlined"
                value={search_value}
                onChange={(e) => setSearchValue(e.target.value)}
                fullWidth
                />
            </div>
            <Widget
                title=""
                //upperTitle
                noBodyPadding
                //bodyClass={classes.tableWidget}
            >
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('№')}</TableCell>
                            <TableCell>{t('registration.equipment.list.headers.id')}</TableCell>
                            <TableCell>{t('registration.equipment.list.headers.name')}</TableCell>
                            <TableCell>{t('registration.equipment.list.headers.location')}</TableCell>
                            <TableCell align='center' colSpan={2}>{t('action.title')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            equipments.map((equipment, index) => (
                                <TableRow key={index} hover>
                                    <TableCell className="pl-3 fw-normal">{index + 1 + ((page - 1) * 10)}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{equipment.equipment_id}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{equipment.name}</TableCell>
                                    <TableCell className="pl-3 fw-normal">{equipment.location}</TableCell>
                                    <TableCell align='right'>
                                        <IconButton onClick={() => onEdit(equipment._id)}>
                                            <EditIcon color='gray' />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='left'>
                                        <IconButton onClick={() => onDelete(equipment._id)}>
                                            <TrashIcon color='red' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    fetching && (
                        <Grid container justify='center'>
                            <CircularProgress />
                        </Grid>
                    )
                }
                <div style={{ paddingTop: 20, paddingBottom: 20}}>
                    <Grid container alignItems='center' justify='center'>
                        <Pagination 
                            count={pages}
                            color='primary'
                            onChange={onPagingChange}
                            />
                    </Grid>
                </div>
            </Widget>
        </div>
    )
}

export default EquipmentList