import React, { useState, useEffect } from 'react'
import { TextField } from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { searchLocation } from '../../utils/RestApi'

const AsyncAutoComplete = (props) => {
    const { 
        label = "Хайх",
        optionLabel = "name"
    } = props
    
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        getData()
    }, [query])

    const getData = () => {
        setLoading(true)
        searchLocation({
            q: query
        })
        .then(res => {
            setData(res.data.pois)
        })
        .catch(err => alert(err))
        .then(() => setLoading(false))
    }

    return (
        <Autocomplete
            {...props}
            options={data}
            getOptionLabel={(option) => option[optionLabel]}
            freeSolo
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    setQuery(value);
                    props.onValueChanged && props.onValueChanged(value)
                }
            }}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
                
    )
} 

export default AsyncAutoComplete