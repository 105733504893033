import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import atlasConfig from "../atlasConfig.json";
import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getTodoIndex,
  _calculateShift,
} from "../utils";

const { dataSourceName } = atlasConfig;

export function useCalls() {
  // Set up a list of todos in state
  const [missed_calls, setMissedCalls] = React.useState([]);
  const start_at = _calculateShift()

  const callsCollection = useCollection({
    cluster: dataSourceName,
    db: "test",
    collection: "calls",
  });

  React.useEffect(() => {
    let shouldUpdate = true
    const fetchCalls = callsCollection.find({
        date: {
            $gte: start_at.toDate()
        },
        is_called_back: false,
        queue: "Main",
        status: "abondaned"
    })

    if (shouldUpdate) {
        fetchCalls.then((calls) => {
          setMissedCalls(calls)
        })
        .catch(err => alert(err))
    }
    return () => {
      shouldUpdate = false
    }
  }, [callsCollection])

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(callsCollection, {
    onInsert: (change) => {
      const {
        is_called_back,
        queue,
        status
      } = change.fullDocument

      if(is_called_back == false && queue == "Main" && status == "abondaned") {
        setMissedCalls((oldTodos) => {
          const idx = getTodoIndex(oldTodos, change.fullDocument) ?? oldTodos.length
          if (idx === oldTodos.length) {
            return addValueAtIndex(oldTodos, idx, change.fullDocument)
          } else {
            return oldTodos
          }
        })
      }
    },
    onUpdate: (change) => {
      const {
        is_called_back,
        queue,
        status
      } = change.fullDocument

      if(is_called_back == true && queue == "Main" && status == "abondaned") {
        setMissedCalls((oldTodos) => {
          const idx = getTodoIndex(oldTodos, { _id: change.documentKey._id });
          if (idx >= 0) {
            return removeValueAtIndex(oldTodos, idx);
          } else {
            return oldTodos;
          }
        });
      }
    }
  });

  return {
    // loading,
    missed_calls,
    // fetchDriver
  };
}
