import React, { useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import AddPhone from "../../components/AddPhone/AddPhone"
import { useTranslation } from "react-i18next"
import { Grid, Paper, Button, TextField, Chip, FormControl, InputLabel, CircularProgress, Select, MenuItem, Input } from  '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { updateCustomer, getCustomerCompanies, takeCustomer } from '../../utils/RestApi'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const UpdateCustomer = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory()
    const [addCompany, setAddCompany] = React.useState();
    const [addFirstName, setAddFirstName] = React.useState("");
    const [addLastName, setAddLastName] = React.useState("");
    const [addEmail, setAddEmail] = React.useState("");
    //const [avatar, setAvatar] = React.useState("")
    const [fetching, setFetching] = React.useState(false)
    const [phones, setPhones] = React.useState([""]);
    const locale = localStorage.getItem('locale')
    const [companies, setCompanies] = React.useState([]);

    const findId = props.match.params.customer_id;

    useEffect(() => {
        fetchCompanies()
    }, [])

    const fetchCompanies = () => {
        getCustomerCompanies({})
        .then((res) => {
            if(res.data.code == 0) {
                setCompanies(res.data.companies)
            }
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchCustomer()
    }, [])

    const fetchCustomer = () => {
        takeCustomer(findId)
        .then((res) => {
            if (res.data.code == 0) {
                setAddCompany(res.data.customer.company)
                setAddFirstName(res.data.customer.first_name)
                setAddLastName(res.data.customer.last_name)
                if(res.data.customer.phones != ""){
                    setPhones(res.data.customer.phones)
                }//d ["342340", "@34324"]
                setAddEmail(res.data.customer.email)
            }
        })
        .catch((err) => console.log(err))
    }

    const phoneRemove = index => {
        const list = [...phones];
        list.splice(index, 1);
        setPhones(list);
      };
     
    const phoneAdd = () => {
        setPhones([...phones, ""]);
    };

    const getPhones = (e, i) => {
        let newPhones = [...phones]

        newPhones[i] = e.target.value
        setPhones(newPhones)
    }

    const onBack  = () =>  history.push('/app/customer');
    
    const onSaveCustomer = () => {
        setFetching(true)

        if(!phones) {
            toast.warning(`${t('registration.customer.create_new.phone')} is empty`)
            return setFetching(false)
        }
        
        if(!addCompany) {
            toast.warning(`${t('registration.customer.create_new.compnany')} is empty`)
            return setFetching(false)
        } 
        
        if(!addFirstName) {
            toast.warning(`${t('registration.customer.create_new.first_name')} is empty`)
            return setFetching(false)            
        } 
        
        if(!addLastName) {
            toast.warning(`${t('registration.customer.create_new.last_name')} is empty`)
            return setFetching(false)            
        }

        updateCustomer(findId, {
            phones: phones.filter((phone) => phone),
            company: addCompany,
            first_name: addFirstName,
            last_name: addLastName,
            email: addEmail,
        })
        .then(res => {
            if(res.data.code != 0) {
                toast.warning(res.data.errors[0].msg)
                return
            }
            
            if(res.data.code == 0) {
                toast.success(t("message.success.saved"))
                history.push('/app/customer')
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.customer.create_new.title")}
                    //btnColor="primary"
                    //button={t("order.new_order")}
                    //onButtonClick={onCreateNewOrder}  
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="#">{t("registration.customer.create_new.company")}</InputLabel>
                                <Select
                                    native
                                    value={addCompany}
                                    onChange={(e) => setAddCompany(e.target.value)}
                                    label={t("registration.customer.create_new.company")}
                                    inputProps={{
                                        name: 'company',
                                    }}
                                >
                            <option aria-label="None" value="" />
                            {
                                companies.map((company, index) => (
                                    <option keys={index} value={company._id}>{company[`name_${locale}`]}</option>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addLastName} onChange={(e) => setAddLastName(e.target.value)} label={t('registration.customer.create_new.last_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={addFirstName} onChange={(e) => setAddFirstName(e.target.value)} label={t('registration.customer.create_new.first_name')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    phones.map((phone, i) => (
                        <AddPhone 
                            setPhone={getPhones}
                            datas={phones}
                            data={phone}
                            index={i}
                            onAdd={phoneAdd}
                            onRemove={phoneRemove}
                        />
                    ))
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' onChange={(e) => setAddEmail(e.target.value)} value={addEmail} label={t('registration.customer.create_new.email')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress /> //setEmployee //hooks onSaveEmployee
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveCustomer}>
                                        {t("action.save")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default UpdateCustomer