import React, { useState, useEffect } from "react"
import { Spinner, Text, Box, Button, TapArea, Layer, Modal, FixedZIndex, OverlayPanel } from "gestalt"
import PageTitle from "../../components/PageTitle/PageTitle"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    CircularProgress,
    IconButton,
    Paper,
    TableContainer
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'

import { Trash as TrashIcon, Edit as EditIcon, Lock as LockIcon } from 'react-feather'
import moment from 'moment';

import { getInsurances, getCurrentInsurance, deleteBanned, setInsuranceStatus } from '../../utils/RestApi'
import { toast } from "react-toastify";

import CreateInsuranceList from './CreateInsuranceList'
import Xlsx from "../../components/Xlsx";

const InsuranceRow = (props) => {
    const { index, employee, key } = props
    const { loading, downloadXlsx } = Xlsx()
    const [saving, setSaving] = useState(false)

    const [status, setStatus] = useState(employee.status)
    const [updated_by, setUpdatedBy] = useState(employee.updated_by)


    const [fetching, setFetching] = useState(false)
    // const []


    const onEdit = () => {

    }

    const onDelete = () => {

    }

    const onSent = () => {
        setSaving(true)
        setInsuranceStatus(moment(employee.date).format("YYYY-MM-DD"), {
            status: "sent"
        })
        .then(res => {
            if(res.data.code == 0) {
                toast("Амжилттай хадгаллаа")
                setStatus("sent")
                setUpdatedBy(res.data.updated_by)
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const onDownloadExcel = () => {
        setFetching(true)

        getCurrentInsurance({
            insurance_id: employee._id
        })
        .then(res => {
            if(res.data.code == 0) {
                let data = [
                    {
                      sheet: "Adults",
                      columns: [
                        { label: "№", value: "index" }, // Top level data
                        { label: "Регистр дугаар", value: "registry_number" }, // Top level data
                        { label: "Овог", value: "last_name" }, // Custom format
                        { label: "Нэр", value: "first_name" }, // Run functions
                        { label: "Утас", value: "phone" }
                      ],
                      content: res.data.insurance.drivers.map((driver, index) => Object.assign(driver, {
                        index: index + 1,
                        phone: String(driver.phones)
                      })),
                    }
                  ]
        
                // console.log(data)
                
                downloadXlsx(data, {
                    fileName: `Jolooch103-${moment(employee.date).format("YYYY/MM/DD")}`
                })
            }
        })
        .then(() => setFetching(false))
    }

    return (
        <TableRow key={index} hover style={{
            background: index % 2 == 0 ? "#f2f2f2" : "#fff"
        }}>
            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{index + 1}</TableCell>
            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{moment(employee.date).format("YYYY/MM/DD")}</TableCell>
            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>
                <TapArea onTap={() => props.onEdit(employee)}>
                    <Box display="flex" alignItems="center">
                        <Text underline>
                            {employee.drivers.length} жолооч
                        </Text>
                        <IconButton onClick={() => props.onEdit(employee)}>
                            <EditIcon color="black" size={16} />
                        </IconButton>
                    </Box>
                </TapArea>
            </TableCell>

            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 120, }}>
                {
                    fetching ? <Spinner show={true} /> : <Button text="Excel татах" size='sm' color="red" onClick={onDownloadExcel} />
                }
            </TableCell>
            
            {
                status == "created" ? (
                    saving ? <Spinner show={true} /> : (
                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 180, }}>
                            <Button text="Имэйл илгээсэн" size='sm' color="blue" onClick={onSent} />
                        </TableCell>
                    )
                ) : (
                    <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 180, }}>
                        <Text underline align="center">Илгээсэн</Text>
                    </TableCell>
                )
            }
            

            <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff", width: 180, }}>
                {
                    updated_by && (
                        <Text color="subtle" size='200' align="center">{updated_by.first_name}</Text>
                    )
                }
            </TableCell>
        </TableRow>
    )
}

const InsuranceList = (props) => {
    const [search_value, setSearchValue] = useState('');
    
    const [insurance_list, setInsuranceList] = useState([])
    const [certificates, setCertificates] = useState([])
    const [fetching, setFetching] = useState(false)
    const [saving, setSaving] = useState(false)
    const [pages, setPages] = useState(1)
    const [page, setPage] = useState(1)

    const [focus, setFocus] = useState(false)
    const [phone, setPhone] = useState("")
    const [note, setNote] = useState("")
    const [end_at, setEndAt] = useState(moment())

    const [isOpen, setIsOpen] = useState(false)

    const [insurance, setInsurance] = useState(null)

    useEffect(() => {
        fetchVIP()
    }, [page, search_value])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const onCreateNewEmployee = () => { 
        setInsurance(null)
        setIsOpen(true)
    }

    const fetchVIP = () => {
        setFetching(true)
        getInsurances({
            search_value
        })
        .then(res => {
            if(res.data.code == 0) {
                setInsuranceList(res.data.result.docs)
                setPages(res.data.result.pages)
            }
        })
        .then(() => setFetching(false))
    }

    const onEdit = (insurance) => {
        setInsurance(insurance)
        setIsOpen(true)
    }

    const onDelete = (vip_id) => {
        if (window.confirm("Устгахдаа итгэлтэй байна уу") == true) {
            deleteBanned(vip_id)
            .then(res => {
                if(res.data.code != 0) {
                    return toast("Амжилттай устгалаа")
                }

                if(res.data.code == 0) {
                    fetchVIP()
                }
            })
        }
    }

    const onSave = () => {
        fetchVIP()
        setIsOpen(false)
    }

    return (
        <Box paddingX={10}>
            <PageTitle
                button="Даатгал мэдээлэл нэмэх"
                onButtonClick={onCreateNewEmployee}  
            />
            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Өдөр</TableCell>
                            <TableCell>Жолооч тоо</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>Илгээсэн ажилтан</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            insurance_list.map((employee, index) => { 
                                return (
                                    <InsuranceRow 
                                        employee={employee} 
                                        key={index} 
                                        index={index}
                                        onEdit={onEdit}
                                    >
                                    
                                    </InsuranceRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                {   
                    fetching && (
                        <Grid
                            container
                            justify='center'
                        >
                            <CircularProgress />
                        </Grid>
                    )
                }
                </TableContainer>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container alignItems='center' justify="center">
                        <Pagination
                            page={page}
                            count={pages}
                            color="primary"
                            onChange={onPagingChange}
                        />
                    </Grid>
                </div>
            {
                isOpen && (
                    <CreateInsuranceList 
                        onDismiss={() => setIsOpen(false)} 
                        onSave={onSave}
                        insurance={insurance}
                    />
                )
            }
        </Box>
    )
};

export default InsuranceList;
