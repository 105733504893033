import React, { useEffect, useState } from "react"
import { Spinner, Box, Modal, Button, Checkbox, Text } from "gestalt"

import {
    Grid,
    Table,
    TextField,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Paper,
    TableContainer
} from "@material-ui/core";

import Pagination from '@material-ui/lab/Pagination'
import { getEmployees } from '../../utils/RestApi'
import moment from "moment";

const EmployeeSelector = (props) => {
    const [fetching, setFetching] = useState(false)
    const [employees, setEmployees] = useState([])
    const [search_value, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)

    const [selected_users, setSelectedUsers] = useState(props.selected_users)

    useEffect(() => {
        fetchEmployees()
    }, [page])

    useEffect(() => {
        if(page == 1) return fetchEmployees()
        setPage(1)
    }, [search_value])

    const onPagingChange = (event, value) => {
        setPage(value)
    }

    const fetchEmployees = () => {
        setFetching(true)
        getEmployees({
            page,
            roles: ["Цагийн ажилтан", "Жолооч"],
            search_value
        })
        .then((res) => {
            if (res.data.code == 0) {
                setEmployees(res.data.result.docs) //users
                setPages(res.data.result.pages)
            }
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
    }

    const onSave = () => {
        props.onSave(selected_users)
    }

    const onChange = (employee) => {
        let new_users = [...selected_users]
        let index = new_users.findIndex((cur) => String(cur._id) == String(employee._id))

        if(index == -1) {
            new_users.push(employee)
        } else {
            new_users.splice(index, 1)
        }

        setSelectedUsers(new_users)
    }

    return (
        <Modal 
            size='lg' 
            onDismiss={props.onDismiss}
            footer={
                <Box display="flex">
                    <Button text="Хаах" fullWidth onClick={props.onDismiss}/>
                    <Box width={12} />
                    <Button onClick={onSave} text="Сонгох" fullWidth color="red" />
                </Box>
            }
        >
            <Box>
                <Box marginBottom={2}>
                    <Text weight="bold">Сонгосон: {selected_users.length}</Text>
                </Box>
            <Spinner show={fetching} />

            <div style={{ marginBottom: 20 }}>
                <TextField
                    id="outlined-basic"
                    autoComplete="new-password"
                    inputProps={{
                        autoComplete: 'off'
                     }}
                    label="Хайх"
                    variant="outlined"
                    value={search_value}
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                />
            </div>

            <TableContainer component={Paper}>
                <Table className="mb-0">
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Регистр дугаар</TableCell>
                            <TableCell>Овог</TableCell>
                            <TableCell>Нэр</TableCell>
                            <TableCell>Утас</TableCell>
                            <TableCell>Албан тушаал</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((employee, index) => { 
                                return (
                                    <TableRow key={index} hover style={{
                                        background: index % 2 == 0 ? "#f2f2f2" : "#fff"
                                    }}>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>
                                            <Checkbox 
                                                checked={selected_users.find((cur) => String(cur._id) == String(employee._id))}
                                                onChange={() => onChange(employee)}
                                            />
                                        </TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.registry_number}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.last_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.first_name}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.phones}</TableCell>
                                        <TableCell style={{ background: index % 2 == 0 ? "#f2f2f2" : "#fff" }}>{employee.roles.length > 0 && employee.roles[0].title}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table> 
            </TableContainer>
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Grid container alignItems='center' justify="center">
                    <Pagination
                        page={page}
                        count={pages}
                        color="primary"
                        onChange={onPagingChange}
                    />
                </Grid>
            </div>
            </Box>
        </Modal>
    )
};

export default EmployeeSelector;
