import React, { useState } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"

import { Grid, Paper, Button, TextField, MenuItem, IconButton, FormControl, InputLabel, Select, InputAdornment} from  '@material-ui/core';
import { Alert } from '@material-ui/lab'

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    input: {
        display: 'none',
      },
}));


const CreateOrder = (props) =>  {
    const { t } = useTranslation();
    const classes = useStyles();

    const [fetching, setFetching] = useState(false)
    const [note, setNote] = useState("")
    const [pictures, setPictures] = useState([])

    const onBack  = () => props.history.goBack()

    const onSendNote = () => {
        
    }

    return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("order.detail.monitoring.title")}
                />
                <div style={{ marginBottom: 20 }}>
                    <Grid>  
                        <Alert severity="info">
                            {t("monitoring.description")}
                        </Alert>
                    </Grid>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <TextField fullWidth
                            id="#"
                            label={t("order.detail.monitoring.note")}
                            type="text"
                            variant="outlined"
                            multiline
                            rows={6}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                            {t("order.detail.monitoring.upload")}
                            </Button>
                        </label>
                    </Grid>
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack}>
                                {t("action.back")}  
                            </Button>
                            <div style={{ width: 20 }} />
                            <Button variant="contained" color="primary" onClick={onSendNote}>
                                {t("action.send")}   
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateOrder