import { useState, useEffect } from 'react'

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export function _getProcessTitle(name) {
    switch(name) {
        case "created":
        return "Хүлээлтэнд байгаа"
        case "assigned":
        return "Явж байгаа"
        case "ready_to_drive":
        return "Очсон"
        case "in_driving":
        return "Авсан"
        case "ready_to_drop":
        return "Буух дөхсөн"
        case "ready_to_payment":
        return "Төлбөр төлөхийг хүлээж байгаа"
        case "waiting_to_pickup":
        return "Түгээлт авах"
        default:
            return "Хүлээлтэнд байгаа"
    }
}

export function _getProcessImg(name) {
    switch(name) {
        case "created":
            return "/images/blue.png"
        case "assigned":
            return "/images/dark.png"
        case "ready_to_drive":
            return "/images/orange.png"
        case "in_driving":
            return "/images/red.png"
        case "ready_to_drop":
            return "/images/green.png"
        case "ready_to_payment":
            return "/images/green.png"
        case "waiting_to_pickup":
            return "/images/green.png"
        default:
            return "/images/blue.png"
    }
}

export function _getProcessColor(name) {
    switch(name) {
        case "created":
        return "#00A3FF"
        case "assigned":
        return "#2A42C8"
        case "ready_to_drive":
        return "#FF6915"
        case "in_driving":
        return "#F41D24"
        case "ready_to_drop":
        return "#51D698"
        case "ready_to_payment":
        return "#51D698"
        case "waiting_to_pickup":
        return "#51D698"
        default:
            return "#2A42C8"
    }
}

export function currencyFormat(price) {
  if (!price) return "0₮";

  // return `${nFormatter(price)}₮`;
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "₮";
}

export function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = Math.ceil(R * c); // in metres
    
    const km = d / 1000

    return km
}