import React, { useState, useEffect } from 'react'

import { Box, TapArea, Icon, TextField, Text, Image, Spinner } from 'gestalt'
import { _getProcessTitle, getDistance, _getProcessColor } from '../../../utils/helpers'
import _, { filter } from 'lodash'
import moment from 'moment'

const DriverSelector = (props) => {
    const { order, driver, drivers } = props
    const [filtered_orders, setFilteredOrders] = useState([])
    const [fetching, setFetching] = React.useState(false)
    const [search_text, setSearchText] = useState("")
    // const [drivers, setDrivers] = React.useState([])

    useEffect(() => {
        _calculateDriversOrder()
    }, [drivers, search_text])

    const _calculateDriversOrder = () => {
        let filtered_orders = []

        drivers.filter((current) => current.driver.roles[0].title != 'Түгээгч').forEach((current) => {
            let index = 11

            if(current.order) {
                index = current.order.processes.findIndex((current) => current.status == 'active')
            }

            filtered_orders.push(Object.assign(current, {
                distance: (current.driver.last_geolocation && current.driver.last_geolocation.coords) ? getDistance(order.from_location.coordinate[1], order.from_location.coordinate[0], current.driver.last_geolocation.coords.latitude, current.driver.last_geolocation.coords.longitude) : 999999,
                current_process_index: 10 - index
            }))
        })

        if(search_text) {
            setFilteredOrders(filtered_orders.filter((current) => {
                let str = current.driver.sap.toLowerCase() + " " + current.driver.first_name.toLowerCase()
                return str.includes(search_text)
            }))
        } else {
            setFilteredOrders(_.sortBy(filtered_orders, ['current_process_index', 'distance']))
        }
    }  

    const onSelectDriver = (driver_id) => {
        // let driver_id = event.target.value
        let driver = drivers.find((current) => String(current.driver._id) == String(driver_id))
        props.onDriverChange(driver.driver)
    }

    return (
        <Box>
            <Box marginBottom={2}>
                <TextField placeholder='Дугаар, нэрээр хайх' value={search_text} onChange={({ value }) => setSearchText(value)} />
            </Box>
            <Box height={600} overflow="scrollY">
                {
                    fetching && (
                        <Box display='flex' justifyContent='center' paddingY={6}>
                            <Spinner show={true} />
                        </Box>
                    )
                }

                {
                    filtered_orders.map((current, index) => (
                        <TapArea tapStyle="compress" key={index} fullWidth onTap={() => onSelectDriver(current.driver._id)}>
                            <Box display='flex' alignItemds='center' color='light' borderStyle='sm' paddingY={2} marginBottom={3}>
                                <Box display='flex' alignItems='center' marginEnd={2} marginStart={4}>
                                    <Icon
                                        icon={current.driver._id == driver._id ? "check-circle" : "circle-outline"}
                                        size={20}
                                        color={current.driver._id == driver._id ? "success" : "black"}
                                    />
                                </Box>
                                <Box>
                                    <Box display='flex' alignItems='center'>
                                        <Box marginEnd={2}>
                                            <Text inline><Text inline weight='bold'>[{current.driver.sap}] {current.driver.first_name}</Text>.{current.driver.last_name.slice(0, 1)}</Text>
                                            {
                                                current.driver.driver_licence_date && <Text size='200'>Жолооч болоод: {Math.abs(moment(current.driver.driver_licence_date).diff(moment(), 'year'))} жил</Text> 
                                            }
                                        </Box>
                                        {
                                            current.driver.manual_wheel_option && (
                                                <Box width={14} height={14}>
                                                    <Image 
                                                        src="/images/wheel.jpg"
                                                        naturalHeight={1}
                                                        naturalWidth={1}
                                                    />
                                                </Box>
                                            )
                                        }
                                        <Box marginStart={1}>
                                            <Text>
                                                [{current.driver.driver_type}]
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Text size='200'>{current.driver.phones} / {current.driver.roles[0].title}</Text>
                                </Box>
                                <Box flex='grow' display='flex' direction='column' alignItems='end'>
                                    {
                                        current.order && (
                                            <Box>
                                                <span style={{
                                                    color: _getProcessColor(current.order.current_process.name)
                                                }}>{_getProcessTitle(current.order.current_process.name)}</span>
                                            </Box>
                                        )
                                    }
                                    {
                                        current.distance != 999999 && (
                                            <Text size='200'>{current.distance} км зайд</Text>
                                        )
                                    }
                                </Box>
                                <Box paddingX={2} display='flex' alignItems='center'>
                                    <Box color='successBase' height={24} width={24} rounding={2} display='flex' justifyContent='center' alignItems='center'>
                                        <Text color='light' weight='bold' size='200'>{current.total_order}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </TapArea>
                    ))
                }
            </Box>
        </Box>
    )
}

export default DriverSelector