import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from "../../components/PageTitle/PageTitle";
import DashboardChart from './components/DashboardChart'
import { Box, Button, Image, Avatar, Text, TextField, Spinner } from 'gestalt'
import {
    Grid,
    Paper,
    CircularProgress,
} from "@material-ui/core";

import ReactPlaceholder from 'react-placeholder';

import { getOrderFilterCount } from './components/OrderDashboardApi'
import { getActiveUsers, getWarehouseInventories, getUserDistributions, getConfig, updateConfig } from './components/DashboardApi'
import { toast } from 'react-toastify'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import UploadImage from '../../components/Upload/Upload';
import { isEmpty } from 'lodash';

const PriceRangeImage = () => {
    const [price_map, setPriceMap] = useState("")
    const [status, setStatus] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [config, setConfig] = useState({})

    const onStatusChanged = (status) => setStatus(status)

    useEffect(() => {
        fetchConfig()
    }, [])

    useEffect(() => {
        if(!isEmpty(config) && config.value) setPriceMap(config.value[0].title)
    }, [config])

    const fetchConfig = () => {
        getConfig()
        .then(res => {
            if(res.data.code == 0) {
                let config = res.data.config.find((current) => current.field == "app_price_map")
                setConfig(config)
            }
        })
    }

    const onDone = (secure_url) => {
        toast("Амжилттай хадгаллаа 🙌")
        setPriceMap(secure_url)

        console.log(secure_url)

        setFetching(true)
        updateConfig(config._id, {
            field: "app_price_map",
            value: [{
                title: secure_url
            }]
        })
        .then(res => {
            if(res.data.code == 0) {
                toast("Амжилттай хадгаллаа")
                setStatus(false)
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Grid xs={12} md={6} item>
            <Paper elevation={3}>
                <Box padding={3}>
                    <Text>Тариф</Text>
                </Box>

                {
                  !status && price_map && (
                    <Box>
                      <Image
                        src={price_map}
                        naturalHeight={1}
                        naturalWidth={2}
                      />
                    </Box>
                  ) 
                }

                <UploadImage aspect={2} onDone={onDone} onStatusChanged={onStatusChanged} />
            </Paper>
        </Grid>
    )
}

const ActiveUsers = () => {
    const [fetching, setFetching] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        fetchActiveUsers()
    }, [])

    const fetchActiveUsers = () => {
        setFetching(true)
        getActiveUsers({
            limit: 200,
        })
        .then(res => {
            if(res.data.code != 0) {
                alert(res.data.errors[0].msg)
            }
            if(res.data.code == 0) {
                setUsers(_sortBy(res.data.result.docs, 'roles.title'))
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    return (
        <Grid xs={12} md={3} item>
            <Paper elevation={3}>
                <Box padding={3}>
                    <Text>Идэвхтэй хэрэглэгчид /Цаг дотор/</Text>
                </Box>
                {
                    fetching && <Spinner show={true} />
                }
                {
                    users.map((user, index) => (
                        <Box key={index} display='flex' paddingX={3} paddingY={1} alignItems='center'>
                            <Box width={32} height={32} position='relative'>
                                <Avatar 
                                    name={user.first_name}
                                    src={user.avatar_url}
                                />
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                }}>
                                    <Box height={8} width={8} rounding="circle" color='successBase' />
                                </div>
                            </Box>
                            <Box marginStart={2}>
                                <Text>{user.first_name} {user.last_name}</Text>
                                <Box display='flex'>
                                {
                                    user.roles.length > 0 && (
                                        <Text size='200' color='subtle'>{user.roles[0].title}</Text>
                                    )
                                }
                                <Box width={12} />
                                <Text size='200' color='subtle'>{moment(user.last_active_at).fromNow()}</Text>
                                </Box>
                            </Box>
                        </Box>
                    ))
                }
            </Paper>
        </Grid>
    )
}

const AppGuideLink = () => {
    const [value, setValue] = useState("")
    const [mode, setMode] = useState("view")
    const [fetching, setFetching] = useState(false)
    const [config, setConfig] = useState({})
    
    useEffect(() => {
        fetchConfig()
    }, [])

    useEffect(() => {
        if(config.value) setValue(config.value[0].title)
    }, [config])

    const fetchConfig = () => {
        getConfig()
        .then(res => {
            if(res.data.code == 0) {
                let config = res.data.config.find((current) => current.field == "app_guide_link")
                setConfig(config)
            }
        })
    }

    const onSave = () => {
        setFetching(true)
        updateConfig(config._id, {
            field: "app_guide_link",
            value: [{
                title: value
            }]
        })
        .then(res => {
            if(res.data.code == 0) {
                toast("Амжилттай хадгаллаа")
                setMode("view")
            }
        })
        .catch(err => alert(err))
        .then(() => setFetching(false))
    }

    // const _RR5xtp4jg4
    return (
        <Grid xs={12} sm={6} md={6} item>
            <Paper elevation={3}>
                <Box padding={3}>
                    <Text inline>Youtube дугаар холбоос байна [https://www.youtube.com/watch?v=<Text inline weight='bold' color="error">_RR5xtp4jg4]</Text></Text>
                    <Box height={8} />
                    {
                        mode == 'view' ? (
                            <Box color="secondary" paddingX={2} paddingY={1} rounding={2}>
                                <Text weight='bold'>{value}</Text>
                            </Box>
                        ) : (
                            <TextField 
                                placeholder='Youtube link холбох'
                                value={value}
                                onChange={({ value }) => setValue(value)}
                            />  
                        )
                    }
                    
                    <Box height={8} />
                    {
                        mode == 'view' ? (
                            <Button text="Солих" fullWidth onClick={() => setMode("edit")} />        
                        )  : (
                            <Box display='flex'>
                                <Button text="Болих" fullWidth onClick={() => setMode("view")} />        
                                <Box width={12} />
                                <Button text="Хадгалах" color="red" fullWidth onClick={onSave} />        
                            </Box>
                        )
                    }
                    
                </Box>
            </Paper>
        </Grid>
    )
}

const Dashboard = () => {
    const { t } = useTranslation()
    const [all, setAll] = useState(0)
    const [fetching, setFetching] = useState(true)
    const [processes, setProcesses] = useState([{
        _id: 0,
        title: "dashboard.order.filters.planning",
        name: 'planning',
        total: 0,
    }, {
        _id: 1,
        title: "dashboard.order.filters.executing",
        name: 'executing',
        total: 0,
    }, {
        _id: 2,
        title: "dashboard.order.filters.using",
        name: 'using',
        total: 0,
    }])

    const [orderMaterials, setOrderMaterials] = useState({})
    const [allMaterials, setAllMaterials] = useState({})
    const [warehouseInventories, setWarehouseInventories] = useState([{}])
    const [companyUsers, setCompanyUsers] = useState([{}])
    const [orderManpowers, setOrderManpowers] = useState([{}])

    useEffect(() => {
        fetchOrderFilterCount()
    }, [])

    let newProcesses = []
    let newTotal = 0

    const fetchOrderFilterCount = () => {
        getOrderFilterCount()
            .then(res => {
                if (res.data.code == 0) {
                    res.data.result.map((result) => (
                        newProcesses = [...processes],
                        (result._id == 'created' || result._id == 'requirement' || result._id == 'requirement_done' || result._id == 'planning' || result._id == 'planning_done') && (newProcesses[0].total += result.total),
                        (result._id == 'resources_arrived' || result._id == 'project_started' || result._id == 'project_done' || result._id == 'resources_loaded') && (newProcesses[1].total += result.total),
                        (result._id == 'tagging') && (newProcesses[2].total += result.total),
                        newTotal += result.total,
                        setProcesses(newProcesses)
                    ))
                    setAll(newTotal)
                }
            })
            .catch(err => console.log(err))

        getWarehouseInventories()
            .then(res => {
                if (res.data.code == 0) {
                    setWarehouseInventories(res.data.warehouse_inventories)
                }
            })
            .catch(err => console.log(err))
        getUserDistributions()
            .then(res => {
                if (res.data.code == 0) {
                    setCompanyUsers(res.data.user_distribution)
                    setOrderManpowers(res.data.order_manpower)
                }
            })
            .catch(err => console.log(err))
            .then(() => setFetching(false))
        // datetable()
    }

    // const datetable = () => {
    //     orderManpowers.map
    // }

    return (
        <Box paddingX={10}>
            <PageTitle
                title={t("dashboard.main.title")}
            />
            <Grid xs={12} container spacing={3}>
                <ActiveUsers />
                <Grid item md={9}>
                    <AppGuideLink />

                    <Box marginTop={5}>
                        <PriceRangeImage />
                    </Box>

                    <Box paddingY={5}>
                    {
                        fetching ? (
                            <div>
                                <ReactPlaceholder showLoadingAnimation type="TextBlock" rows={20} />
                            </div>
                        ) : (
                            <DashboardChart
                                orderMaterials={orderMaterials}
                                allMaterials={allMaterials}
                                warehouseInventories={warehouseInventories}
                                all={all}
                                processes={processes}
                                companyUsers={companyUsers}
                                orderManpowers={orderManpowers}
                            />
                        )
                    }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Dashboard