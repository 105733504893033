import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./jolooch.png";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { toast } from 'react-toastify'
import { loginAction } from '../../utils/RestApi'
import { useTranslation } from "react-i18next"

function Login(props) {
  var classes = useStyles();
  const { t } = useTranslation();
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  // useEffect(() => {

  // }, [])

  const onRegister = () => {
    props.history.push('/register')
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      //this.onSubmit();
      onLogin()
    }
  }

  const onLogin = () => {
    //khulangoo.a@gmail.com
    //admin@nbik.mn
    // if(loginValue == 'khulangoo.a@gmail.com') {
    //   localStorage.setItem("role", 'customer')
    //   return props.history.push('/app/my-order')  
    // }

    localStorage.clear()

    // localStorage.setItem("role", 'admin')
    // return props.history.push('/app/dashboard')
    // setIsLoading(true)

    loginAction({
      username: loginValue,
      password: passwordValue
    })
      .then(res => {
        if (res.data.code != 0) {
          return toast.warning(res.data.errors[0].msg)
        }

        if (res.data.code == 0) {
          const { user, token, user_type } = res.data

          

          // if (user_type == 'customer') {
          //   localStorage.setItem("role", 'customer')
          //   return props.history.push(`/app/my-order`)
          // }

          let is_admin = user.roles.find((cur) => cur.title == 'admin') ? true : false

          const userRole = user.roles[0].title

          // alert(is_admin)

          if (is_admin) {
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            localStorage.setItem("role", 'admin')
            return props.history.push('/app/dashboard')
          }

          if(userRole == 'Оператор') {
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            localStorage.setItem("role", 'operator')
            return props.history.push('/app/map')
          }

          if(userRole == 'Ээлжийн ахлагч') {
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            localStorage.setItem("role", 'manager')
            return props.history.push('/app/map')
          }

          toast.warning("Системд нэвтрэх эрх алга байна!");

          // loginUser(
          //   res.data.token,
          //   userDispatch,
          //   loginValue,
          //   passwordValue,
          //   props.history,
          //   setIsLoading,
          //   setError,
          // )
          return
        }
        toast.warning("Мэйл нууц үгээ шалгана уу!");
      })
      .catch(err => alert(err))
      .then(() => setIsLoading(false))
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>

          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              Өдрийн мэнд
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>

            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={e => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              onKeyDown={onKeyDown}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <div className={classes.creatingButtonContainer}>
              {isLoading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  onClick={onLogin}
                  disabled={loginValue.length === 0 || passwordValue.length === 0}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  {t("auth.signin")}
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
