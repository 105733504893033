import React from "react";
import * as Realm from "realm-web";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useApp } from "../components/RealmApp";
import atlasConfig from "../atlasConfig.json";
import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getTodoIndex,
} from "../utils";

const { dataSourceName } = atlasConfig;

export function useDrivers() {
  // Set up a list of todos in state
  const app = useApp();
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const userCollection = useCollection({
    cluster: dataSourceName,
    db: "test",
    collection: "users",
  });

    const fetchDriver = async (driver_id) => {
        let driver = await userCollection
        .findOne({
          _id: new Realm.BSON.ObjectId(driver_id)
        })

        return driver
    }
    

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
//   React.useEffect(() => {
//     let shouldUpdate = true;
//     const fetchTodos = orderItemCollection.find({}, {
//       sort: {
//         mood: 1,
//         created_at: -1
//       }
//     })
//     if (shouldUpdate) {
//       fetchTodos.then((fetchedTodos) => {

//         let driverIds = fetchedTodos.filter((todo) => todo.driver).map((todo) => todo.driver)

//         userCollection.find({
//           _id: { $in: driverIds }
//         })
//         .then(users => {
//           fetchedTodos.forEach((todo) => {
//             todo.driver = users.find((user) => String(user._id) == String(todo.driver))
//           })

//           setOrders(fetchedTodos);
//           setLoading(false);
//         })
//         .catch(err => alert(err))
//       });
//     }
//     return () => {
//       shouldUpdate = false;
//     }
//   }, [orderItemCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
//   useWatch(orderItemCollection, {
//     onInsert: (change) => {
//       setOrders((oldTodos) => {
//         if (loading) {
//           return oldTodos;
//         }
//         const idx =
//           getTodoIndex(oldTodos, change.fullDocument) ?? oldTodos.length;
//         if (idx === oldTodos.length) {
//           return addValueAtIndex(oldTodos, idx, change.fullDocument);
//         } else {
//           return oldTodos;
//         }
//       });
//     },
//     onUpdate: (change) => {
//       setOrders((oldTodos) => {
//         if (loading) {
//           return oldTodos;
//         }
//         const idx = getTodoIndex(oldTodos, change.fullDocument);
//         alert(change.fullDocument.driver)
//         return updateValueAtIndex(oldTodos, idx, () => {
//           return change.fullDocument;
//         });
//       });
//     },
//     onReplace: (change) => {
//       setOrders((oldTodos) => {
//         if (loading) {
//           return oldTodos;
//         }
//         const idx = getTodoIndex(oldTodos, change.fullDocument);
//         return replaceValueAtIndex(oldTodos, idx, change.fullDocument);
//       });
//     },
//     onDelete: (change) => {
//       setOrders((oldTodos) => {
//         if (loading) {
//           return oldTodos;
//         }
//         const idx = getTodoIndex(oldTodos, { _id: change.documentKey._id });
//         if (idx >= 0) {
//           return removeValueAtIndex(oldTodos, idx);
//         } else {
//           return oldTodos;
//         }
//       });
//     },
//   });

  // Given a draft todo, format it and then insert it
//   const saveTodo = async (draftTodo) => {
//     if (draftTodo.summary) {
//       draftTodo.owner_id = app.currentUser.id;
//       try {
//         await orderItemCollection.insertOne(draftTodo);
//       } catch (err) {
//         if (err.error.match(/^Duplicate key error/)) {
//           console.warn(
//             `The following error means that this app tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
//           );
//         }
//         console.error(err);
//       }
//     }
//   };

//   // Toggle whether or not a given todo is complete
//   const toggleTodo = async (todo) => {
//     await orderItemCollection.updateOne(
//       { _id: todo._id },
//       { $set: { isComplete: !todo.isComplete } }
//     );
//   };

//   // Delete a given todo
//   const deleteTodo = async (todo) => {
//     await orderItemCollection.deleteOne({ _id: todo._id });
//   };

  return {
    // loading,
    // orders,
    fetchDriver
  };
}
