import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { saveCategory } from '../../utils/RestApi'
import { Grid, Paper, Button, TextField, CircularProgress, FormControl } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateResource = (props) =>  {
    const { t } = useTranslation();
    const [categoryEn, setCategoryEn] = useState('');
    const [categoryMn, setCategoryMn] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);

    const onSaveCategory = () => {
        setFetching(true)

        if(!categoryId) {
            toast.warning(`${t('registration.resource.category.id')} is empty`)
            return setFetching(false)
        } 
        
        if(!categoryEn) {
            toast.warning(`${t('registration.resource.category.name_en')} is empty`)
            return setFetching(false)
        } 
        
        if(!categoryMn) {
            toast.warning(`${t('registration.resource.category.name_mn')} is empty`)
            return setFetching(false)
        }

        saveCategory({
            category_id: categoryId,
            name_en: categoryEn,
            name_mn: categoryMn,
        })
            .then(res => {
            toast.success(t("message.success.saved"))
            props.history.goBack()
        })
        .catch((err) => alert(err))
        .then(() => setFetching(false))
      }

    const onBack  = () =>  props.history.goBack()

    return(
        <div>
            <Paper style={{padding: 30}}>
                <PageTitle 
                    title={t("registration.resource.category.title")}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={categoryId} onChange={(e) => setCategoryId(e.target.value)} label={t('registration.resource.category.id')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={categoryEn} onChange={(e) => setCategoryEn(e.target.value)} label={t('registration.resource.category.name_en')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} alignItems='center'>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <TextField id='outlined-basic' value={categoryMn} onChange={(e) => setCategoryMn(e.target.value)} label={t('registration.resource.category.name_mn')} variant="outlined" />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}  alignItems='center'>
                        <Grid justify='center' container>
                            <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                                {t("action.back")}  
                            </Button>
                            {
                                fetching ? (
                                    <CircularProgress />
                                ) : (
                                    <Button variant="contained" color="primary" onClick={onSaveCategory}>
                                        {t("registration.resource.create_new.success_button")}  
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default CreateResource