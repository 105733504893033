import React, { useEffect, useState } from 'react'

import {
    Paper,
    Grid,
    Typography,
    Avatar,
    Box,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ImageModal from '../../components/ImageModal'

const OrderDetailMain = (props) => {
    const { t } = useTranslation()
    const { order } = props
    return (
        <div style={{ marginTop: '40px' }}>
            <Paper>
                <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6' style={{ fontWeight: 'bold' }}>{t("order.detail.title.main_desc")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("order.detail.order_id")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography style={{ fontWeight: 'bold' }}>{order.order_id}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("naming.company")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{order.company[`name_${window.localStorage.locale}`]}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("area.name")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{order.location[`area_${window.localStorage.locale}`]}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("location.name")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{order.location[`location_${window.localStorage.locale}`]}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography>{t("order.detail.purpose")}</Typography>
                            <Box style={{ marginTop: 10, marginBottom: 10 }}>
                                <Typography style={{ fontWeight: 'bold' }}> {order.requirement.detail}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("order.detail.use_date")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{moment(order.requirement.deadline_at).format('YYYY/MM/DD HH:MM')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{t("order.detail.est_dis_date")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography>{order.requirement.duration}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            
            <Paper style={{ marginTop: 20 }}>
                <Alert>
                    {t(`order.detail.process.${order.current_process.name}`)}
                </Alert>
            </Paper>

            <Paper style={{ marginTop: 40 }}>
                <div style={{ padding: 20 }}>
                    <Typography variant="h6" style={{ marginBottom: 8, fontWeight: "bold" }}>{t("order.detail.process.collect_requirments")}</Typography>

                    {/* {
                        order.detail.user ? (
                            <Typography>
                                {order.detail.user ? `${order.detail.user.first_name} - ` : ''}{moment(order.detail.user.date).format('YYYY/MM/DD HH:MM')}
                            </Typography>
                        ) : (
                            <Typography>
                                {t("order.detail.process.collect_requirments_empty")}
                            </Typography>
                        )
                    } */}

                    <Typography>
                        {order.detail.note}
                    </Typography>
                    <div style={{ marginTop: 20 }}>
                        <Grid
                            container
                            direction="row"
                            //paddingTop={20}
                            spacing={2}
                        >
                            <ImageModal
                                pictures={order.plan.pictures}
                            />
                            {/* {
                                order.detail.pictures.map((picture) => (
                                    <Grid item>
                                        <Avatar
                                            variant="square"
                                            src={picture}
                                            alt="X"
                                            style={{ height: 100, width: 100 }}
                                            //onClick={() => zoomPhotos(order.detail.pictures)}
                                        />
                                    </Grid>
                                ))
                            } */}
                        </Grid>
                    </div>
                </div>
            </Paper>
            
            <div style={{ marginTop: 20 }}>
                <Grid xs={12} container spacing={3}>
                    <Grid xs={12} sm={6} md={4} item>
                        <Paper>
                            <div style={{ padding: 20 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant='h6' style={{ fontWeight: 'bold' }}>{t("order.detail.plan.title")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography style={{ paddingBottom: 20 }}>{t("order.detail.plan.time.title")}</Typography>
                                        <Grid container spacing={3} >
                                            <Grid item xs={6}>
                                                <Typography>{t("order.detail.plan.time.material_down")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{order.resource_required_at ? (moment(order.resource_required_at).format('YYYY/MM/DD')) : ("-")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{t("order.detail.plan.time.start")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ fontWeight: 'bold' }}>{(moment(order.start_at).format('YYYY/MM/DD HH:MM'))}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{t("order.detail.plan.time.deadline")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{(moment(order.end_at).format('YYYY/MM/DD HH:MM'))}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{t("order.detail.est_dis_date")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{order.duration}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography style={{ paddingBottom: 20 }}>{t("order.detail.plan.schema.title")}</Typography>
                                        {
                                            order.plan.scaffolding_type ? (
                                                <div>
                                                    <Typography>{t("order.detail.plan.schema.type")}</Typography>
                                                    <Typography style={{ paddingBottom: 20 }}>{order.plan.scaffolding_type}</Typography>
                                                    <Typography>{t("order.detail.plan.schema.pictures")}</Typography>
                                                    <div style={{ marginTop: 20 }}>
                                                        <Grid container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            spacing={2}
                                                            xs={12}>
                                                            <ImageModal
                                                                pictures={order.plan.pictures}
                                                            />
                                                        </Grid>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Typography>{t("order.detail.plan.schema.empty")}</Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid xs={12} sm={6} md={4} item>
                        <Paper>
                            <div style={{ padding: 20 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 20 }}>{t("order.detail.plan.material")}</Typography>
                                    <Grid container>
                                        {
                                            order.resources.map((resource) => (
                                                <Grid container>
                                                    <Typography>{resource[`name_${window.localStorage.locale}`]} - {resource.quantity}</Typography>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid xs={12} sm={6} md={4} item>
                        <Paper>
                            <div style={{ padding: 20 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', paddingBottom: 20 }}>{t("order.detail.plan.hr")}</Typography>
                                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                                        {
                                            order.member_requirements.map((member) => (
                                                <Grid>
                                                    <Typography>{t(`certificate.${member.title}`)} - {member.quantity}</Typography>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingBottom: 20 }}>
                                        {
                                            order.team_members.length != 0 ? (
                                                <div>
                                                    {
                                                        order.team_members.map((member) => (
                                                            <Grid style={{ paddingBottom: 10 }}>
                                                                <Grid container spacing={3} alignItems="center">
                                                                    <Grid item>
                                                                        {
                                                                            member.current_level.title ? (
                                                                                <div>
                                                                                    {
                                                                                        member.current_level.title == "OT Intermediate Scaffolding" &&
                                                                                        <Avatar
                                                                                            src={member.avatar_url}
                                                                                            style={{
                                                                                                border: '2px solid yellow',
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        member.current_level.title == "OT Basic Scaffolding" &&
                                                                                        <Avatar
                                                                                            src={member.avatar_url}
                                                                                            style={{
                                                                                                border: '2px solid blue',
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        member.current_level.title == "OT Advanced Scaffolding" &&
                                                                                        <Avatar
                                                                                            src={member.avatar_url}
                                                                                            style={{
                                                                                                border: '2px solid green',
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            ) : (
                                                                                <Avatar />
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Typography>{member.first_name}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    {
                                                                        member.current_level.expired_at ? (
                                                                            <Typography>{member.current_level.title}  {moment(member.current_level.expired_at).format('YYYY/MM/DD')}</Typography>
                                                                        ) : null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <Typography>{t("order.detail.plan.hr_empty")}</Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: 20 }}></div>
        </div>
    )
}


export default OrderDetailMain