import React, { useState, useEffect } from "react";
import {
  Grid,
  Input,
  Paper,
  Tab,
  Tabs,
  TextField
} from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle";
import OrderList from './components/OrderList'
import OrderFilter from './components/OrderFilter'

import _sum from 'lodash/sumBy'
import { getOrderFilterCount } from './components/OrderDashboardApi'
import {
  Clipboard,
  Bell,
  Map,
  Zap,
  Activity,
} from 'react-feather'
import { useTranslation } from "react-i18next"

import { withRouter } from 'react-router-dom'

function OrderDashboard(props) {
  const { t } = useTranslation();
  const [search_value, setSearchValue] = useState('')
  const [process_name, setProcessName] = useState('active')
  const [filterFetching, setFilterFetching] = useState(false)
  const [processes, setProcesses] = useState([{
    _id: 0,
    title: "dashboard.order.filters.all",
    name: 'active',
    start_at: null,
    end_at: null,
    status: 'active',
    icon: <Clipboard />,
    total: 0,
  }, {
    _id: 1,
    title: "dashboard.order.filters.created",
    name: 'new',
    start_at: null,
    end_at: null,
    status: 'active',
    icon: <Bell />,
    total: 0,
  }, {
    _id: 2,
    title: "dashboard.order.filters.planning",
    name: 'planning',
    start_at: null,
    end_at: null,
    status: 'active',
    icon: <Map />,
    total: 0,
  }, {
    _id: 3,
    title: "dashboard.order.filters.executing",
    name: 'executing',
    start_at: null,
    end_at: null,
    status: 'active',
    icon: <Zap />,
    total: 0,
  }, {
    _id: 4,
    title: "dashboard.order.filters.using",
    name: 'using',
    start_at: null,
    end_at: null,
    status: 'idle',
    icon: <Activity />,
    total: 0,
  }])

  const [is_write, setIsWrite] = useState(false)

  useEffect(() => {
    fetchOrderFilterCount()
  }, [])

  const onNeedToRefresh = () => fetchOrderFilterCount()
  let newProcesses = []
  const fetchOrderFilterCount = () => {
    setFilterFetching(true)
    getOrderFilterCount()
      .then(res => {
        if (res.data.code == 0) {
          res.data.result.map((result) => (
            newProcesses = [...processes],
            (newProcesses[0].total += result.total),
            (result._id == 'created') && (newProcesses[1].total += result.total),
            (result._id == 'requirement' || result._id == 'requirement_done' || result._id == 'planning' || result._id == 'planning_done') && (newProcesses[2].total += result.total),
            (result._id == 'resources_arrived' || result._id == 'project_started' || result._id == 'project_done' || result._id == 'resources_loaded') && (newProcesses[3].total += result.total),
            (result._id == 'tagging') && (newProcesses[4].total += result.total),
            setProcesses(newProcesses)
          ))
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <PageTitle
        title={t("dashboard.order.title")}
        button={t("dashboard.order.new_order")}
        onButtonClick={() => props.history.push('/app/order/create')}
      />
      <Grid container spacing={4}>
        <Grid container>
          <OrderFilter
            processes={processes}
            process_name={process_name}
            setProcessName={setProcessName}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: 20 }}>
            <TextField
              id="outlined-basic"
              label={t("dashboard.order.search")}
              variant="outlined"
              value={search_value}
              onChange={(e) => setSearchValue(e.target.value)}
              fullWidth
            />
          </div>
          <OrderList
            is_write={is_write}
            search_value={search_value}
            process_name={process_name}
            onNeedToRefresh={onNeedToRefresh}
            filterFetching={filterFetching}
            setFilterFetching={setFilterFetching}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(OrderDashboard)