import React, { useEffect, useState } from 'react'
import { Layer, Modal, Box, TapArea, Icon, Button, Spinner, Text, Avatar } from 'gestalt'

import { getDistance } from '../../utils/helpers'
import { OrderItem } from '../dashboard/components/OrderList'
import { toast } from 'react-toastify'
import _isEmpty from 'lodash/isEmpty'

import { savePickup } from '../../utils/RestApi'
import moment from 'moment'

const DriverModal = (props) => {
    const { drivers, } = props
    const current = props.driver
    const { driver } = current

    // console.log(current)
    // const { order } = current
    const [saving, setSaving] = useState(false)
    const [fetching, setFetching] = useState(false)

    const pickups = drivers.filter((current) => current.driver.roles[0].title == 'Түгээгч')

    const [selected_driver, setSelectedDriver] = useState({})

    useEffect(() => {
        // console.log(drivers)
        if(current && current.pickup) {
            setSelectedDriver({
                _id: current.pickup.pickup
            })
        }
    }, [current])

    const onConfirm = () => {}

    const onSavePickup = () => {
        let lat = driver.last_geolocation && driver.last_geolocation.coords ? driver.last_geolocation.coords.latitude : 0
        let lon = driver.last_geolocation && driver.last_geolocation.coords ? driver.last_geolocation.coords.longitude : 0

        setSaving(true)
        savePickup({
            pickup_type: "pickup",
            pickup: selected_driver._id,
            driver: driver._id,
            location: {
                lat,
                lon,
                latitude: lat,
                longitude: lon,
            }
        })
        .then(res => {
            if(res.data.code == 0) {
                toast.success("Амжилттай хадгаллаа")
            }
        })
        .catch(err => alert(err))
        .then(() => setSaving(false))
    }

    const onPinTo = () => {
        if(driver.last_geolocation && driver.last_geolocation.coords)
            props.onPinTo(driver.last_geolocation.coords)
        else toast.warning("Байршил тодорхойгүй байна")
    }

    const onSelectDriver = (driver) => {
        setSelectedDriver(driver)
    }

    const onOrderFitMap = () => {}

    const _renderOrder = () => {
        return <OrderItem 
            key={0}
            order={current.order}
            onTap={onOrderFitMap}
        />
    }

    const _renderInCab = () => {
        return null
    }

    const _renderPickup = () => {
        if(driver.roles[0].title == 'Түгээгч') return null
        
        return (
            <Box marginTop={4}>
                <Box paddingY={4}>
                    <Text>Түгээлт хувиарлах</Text>
                </Box>
                {
                    pickups.map((current, index) => (
                        <TapArea tapStyle="compress" key={index} fullWidth onTap={() => onSelectDriver(current.driver)}>
                            <Box display='flex' alignItemds='center' color='light' borderStyle='sm' paddingY={2} paddingX={4} marginBottom={3}>
                                <Box display='flex' alignItems='center' marginEnd={2}>
                                    <Icon
                                        icon={current.driver._id == selected_driver._id ? "check-circle" : "circle-outline"}
                                        size={20}
                                        color={current.driver._id == selected_driver._id ? "success" : "black"}
                                    />
                                </Box>
                                <Box>
                                    <Text inlin><Text inline weight='bold'>[{current.driver.sap}] {current.driver.first_name}</Text>.{current.driver.last_name.slice(0, 1)}</Text>
                                    <Text size='200'>{current.driver.phones} / {current.driver.roles[0].title}</Text>
                                </Box>
                                <Box flex='grow' display='flex' direction='column' alignItems='end'>
                                    {
                                        driver.last_geolocation && driver.last_geolocation.coords && current.driver.last_geolocation && current.driver.last_geolocation.coords && (
                                            <Text size='200'>{getDistance(driver.last_geolocation.coords.lat, driver.last_geolocation.coords.lon, current.driver.last_geolocation.coords.latitude, current.driver.last_geolocation.coords.longitude)}км зайд</Text>
                                        )
                                    }
                                </Box>
                            </Box>
                        </TapArea>
                    ))
                }

                {
                    saving ? <Spinner show={true} /> : (
                        <Button
                            text="Тохируулах"
                            disabled={_isEmpty(selected_driver)}
                            color="red"
                            fullWidth
                            onClick={onSavePickup}
                        />
                    )
                }
            </Box>
        )
    }

    // console.log(current)

    // alert(current.cab)

    return (
        <Layer>
            <Modal 
                onDismiss={props.onDismiss}
                footer={
                    <Box display='flex' marginTop={4}>
                        <Button 
                            text='Хаах'
                            fullWidth
                            onClick={props.onDismiss}
                        />

                        <Box width={12} />

                        {
                            fetching ? <Spinner show={true} /> : (
                                <Button
                                    text='Байршил'
                                    fullWidth
                                    onClick={onPinTo}
                                />
                            )
                        }
                        
                    </Box>
                }
            >
                <Box>
                    <Box flex="grow" padding={2}>
                        <Box display='flex' color='infoWeak' paddingY={4}>
                            <Box paddingY={1} paddingX={4}>
                                <Box width={60} height={60}>
                                    <Avatar     
                                        src={driver.avatar_url}
                                        name={driver.first_name}
                                        color="#4f4f4f"
                                    />
                                </Box>
                            </Box>
                            <Box flex='grow'>
                                <Box>
                                    <Text size="400" weight='bold'>[{driver.sap}] {driver.first_name}.{driver.last_name.slice(0, 1)}</Text>
                                    <Box display='flex'>
                                        <Text color='gray'>{String(driver.roles[0].title)}</Text>

                                        <Box width={8} />

                                        <Text color='gray'>{driver.phones}</Text>
                                    </Box>
                                </Box>
                                <Box display='flex'>
                                    <Text color='gray'>Механик машин {driver.manual_wheel_option ? "барина" : "барихгүй"}</Text>
                                </Box>
                                <Box>
                                    <Text>Жолооч болоод: {Math.abs(moment(driver.driver_licence_date).diff(moment(), 'year'))} жил</Text> 
                                </Box>
                            </Box>
                        </Box>

                        <Box height={12} />

                        {
                            current.order && (
                                _renderOrder()
                            )
                        }

                        {
                            current.order == null && current.cab && (
                                _renderInCab()
                            )
                        }

                        {
                            current.order == null && !current.cab && (
                                _renderPickup()
                            )
                        }
                        
                    </Box>
                </Box>
            </Modal>
        </Layer>
    )
}

export default DriverModal