import React, { useState, useEffect } from 'react'
import PageTitle from "../../components/PageTitle/PageTitle"
import { useTranslation } from "react-i18next"
import { saveLocation } from '../../utils/RestApi'
import { searchAreas } from './AreaApi'
import { Grid, Paper, Button, CircularProgress, TextField, IconButton, FormControl, InputLabel, Select } from  '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const CreateLocation = (props) =>  {
    const history = useHistory()
    const { t } = useTranslation();
    const classes = useStyles();
    const locale = localStorage.getItem('locale')
    const [areas, setAreas] = useState([])
    const [area, setArea] = useState('')
    const [locationEn, setLocationEn] = useState('')
    const [locationMn, setLocationMn] = useState('')
    const [state, setState] = useState('')
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        fetchAreas()
    }, [])

    const fetchAreas = () => {
        searchAreas()
        .then((res) => {
            if (res.data.code == 0) {
                setAreas(res.data.areas)
            }
        })
        .catch((err) => alert(err))
    }

    const onSaveLocation = () => {
        setFetching(true)
        
        if(!locationEn) {
            toast.warning(`${t('registration.location.create_new.location_en')} is empty`)
            return setFetching(false)
        }
        
        if(!locationMn) {
            toast.warning(`${t('registration.location.create_new.location_mn')} is empty`)
            return setFetching(false)
        }
        
        if(!area) {
            toast.warning(`${t('registration.location.create_new.area')} is empty`)
            return setFetching(false)
        }

        saveLocation({
            location_en: locationEn,
            location_mn: locationMn,
            area
        })
            .then(res => {
                if(res.data.code != 0) {
                    toast.warning(res.data.errors[0].msg)
                }

                if(res.data.code == 0) {
                    toast.success(t("message.success.saved"))
                    history.goBack()
                }
            })
            .catch((err) => alert(err))
            .then(() => setFetching(false))
      }

    const onBack  = () => history.goBack()

    console.log(areas)

    return(
        <Paper style={{padding: 30}}>
            <PageTitle 
                title={t("registration.location.create_new.title")}
                //btnColor="primary"
                    //button={t("order.new_order")}
                //onButtonClick={onCreateNewOrder}  
            />
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="#">{t("registration.location.create_new.area")}</InputLabel>
                            <Select
                                native
                                value={area}
                                onChange={(e) => setArea(e.target.value)}
                                label={t("registration.location.create_new.area")}
                                inputProps={{
                                    name: 'area',
                                }}
                            >
                        <option aria-label="None" value="" />
                        {
                            areas.map((area, index) => (
                                <option key={index} value={area._id}>{area[`area_${locale}`]} - {area.company ? area.company[`name_${locale}`]  : ""}</option>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <TextField id='outlined-basic' value={locationMn} onChange={(e) => setLocationMn(e.target.value)} label={t('registration.location.create_new.location_mn')} variant="outlined" />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} alignItems='center'>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <TextField id='outlined-basic' value={locationEn} onChange={(e) => setLocationEn(e.target.value)} label={t('registration.location.create_new.location_en')} variant="outlined" />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}  alignItems='center'>
                    <Grid justify='center' container>
                        <Button variant="contained" color="default" onClick={onBack} style={{marginRight: 10}}>
                            {t("action.back")}  
                        </Button>
                        {
                            fetching ? (
                                <CircularProgress />
                            ) : (
                                <Button variant="contained" color="primary" onClick={onSaveLocation}>
                                    {t("action.save")}  
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CreateLocation