import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Step,
  Stepper,
  StepLabel
} from "@material-ui/core";
import { getCustomerCompany } from "../utils/RestApi";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { toast } from 'react-toastify'

// styles
import useStyles from "./login/styles";
// logo
import logo from "./login/iscaff.png";

// context
import { useTranslation } from "react-i18next"

function Register(props) {
  var classes = useStyles();
  const { t } = useTranslation();

  // local
  var [registrationID, setRegistrationID] = useState("");
  var [nameEn, setNameEn] = useState("");
  var [nameMn, setNameMn] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [fetching, setFetching] = React.useState(false)

  function getSteps() {
    return ["Register Company", "Register Customer"];
  }

  const steps = getSteps();

  const onRegister = () => {
    setFetching(true)
    
    getCustomerCompany({
      registration_id: registrationID
    })
    .then((res) => {
      if(res.data.code == 1) {
        return toast.warning("Ийм ID-тай КОМПАНИ бүртгэлтэй байна.")
      } 

      if(res.data.code == 0) {
        props.history.push({
          pathname: `/register/${registrationID}`,
          state: {
            nameEn,
            nameMn
          }
        })
      }

    })
    .catch((err) => alert(err))
    .then(() => setFetching(false))
  }

  const onBack = () => {
    props.history.goBack()
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>i-SCAFF</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
              
        <Typography variant="h1" className={classes.greeting}>
                REGISTER!
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                COMPANY
              </Typography>

              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={registrationID}
                onChange={e => setRegistrationID(e.target.value)}
                margin="normal"
                placeholder="Company ID"
                type="number"
                fullWidth
              />
              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameEn}
                onChange={e => setNameEn(e.target.value)}
                margin="normal"
                placeholder="Company Name EN"
                fullWidth
              />

              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameMn}
                onChange={e => setNameMn(e.target.value)}
                margin="normal"
                placeholder="Company Name MN"
                fullWidth
              />
            {
              fetching ? (
                <CircularProgress />
              ) : (
                <div>
                  <div className={classes.creatingButtonContainer}>
                    <Button
                      onClick={onRegister}
                      disabled={registrationID.length === 0 || nameEn.length === 0 || nameMn.length === 0}
                      size="large"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      {t("auth.signup")}
                    </Button>
                </div>

                <div className={classes.creatingButtonContainer}>
                    <Button
                      onClick={onBack}
                      size="large"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.createAccountButton}
                    >
                      {t("action.back")}
                    </Button>
                </div>
              </div>
              )
            }
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
        </div>
        <Typography color="primary" className={classes.copyright}>
          ©2021 Simplood LLC.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Register);
