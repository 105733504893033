import React from "react";
// styles
import { makeStyles } from '@material-ui/core/styles';  
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from "react-i18next"
import { Grid, TextField, IconButton, FormControl } from  '@material-ui/core';

export default function AddPhone(props) {
    
    const { t } = useTranslation();

    const classes = makeStyles();

    const onAddButton = () => {
        if(props.onAdd)
            props.onAdd()
    }

    const onRemoveButton = () => {
        if(props.onRemove)
            props.onRemove()
    }

    const onEvent = (e, i) => {
        if(props.setPhone)
            props.setPhone(e, i)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={10} alignItems='center'>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <TextField id='outlined-basic' type="number" autoComplete={false} autoCorrect={false} value={props.data} onChange={(e) => onEvent(e, props.index)} label={t('user.phones')} variant="outlined" />
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                {
                    props.datas.length - 1 === props.index 
                        &&
                    <IconButton onClick={onAddButton}>
                        <AddIcon/>
                    </IconButton>   
                }
                {
                    props.datas.length !== 1 
                        && 
                    <IconButton onClick={onRemoveButton}>
                        <RemoveIcon/>
                    </IconButton>
                }
            </Grid>
        </Grid>
    )
}