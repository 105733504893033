import * as Realm from "realm-web";
// import moment from 'moment'
var moment = require('moment-timezone');
const SHIPT_HOUR = 7

export const toggleBoolean = (prev) => !prev;

export function _calculateShift() {
  const asDate = moment().tz("Asia/Ulaanbaatar")
  let start_at = asDate

  if(asDate.get("hour") <= SHIPT_HOUR) {
      start_at = asDate.subtract(1, 'day')
  }

  start_at.set("hour", SHIPT_HOUR).set("minute", 0).set("second", 0)

  return start_at
}

const isValidArrayIndex = (arr, idx) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr, idx, value) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr, idx, newValue) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr, idx, updater) {
  if (!isValidArrayIndex(arr, idx)) {
    return arr
    // throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr, idx) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const createObjectId = () => {
  return new Realm.BSON.ObjectId()
};

export const getTodoId = (todo) => {
  if (todo._id instanceof Realm.BSON.ObjectId) {
    return todo._id.toHexString();
  }
  return todo._id
};

export const isSameTodo = (todo1, todo2) =>
  getTodoId(todo1) === getTodoId(todo2);

export const getTodoIndex = (todos, todo) => {
  const idx = todos.findIndex((t) => isSameTodo(t, todo));
  return idx >= 0 ? idx : null;
}
